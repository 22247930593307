export const isEmptyObject = (obj: any) => {
  if (obj)
    return Object.entries(obj)?.length === 0 && obj?.constructor === Object;
  return false;
};

export const isEmptyArrayObject = (obj: any) => {
  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      if (key > "0") {
        return false;
      }
      return true;
    }
  }
  return true;
};

export const hasKeys = (obj: any = {}, keys: string[]) => {
  const result: any = {};

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (obj.hasOwnProperty(key)) {
      result[key] = {
        exists: true,
        defined: typeof obj[key] !== 'undefined'
      };
    } else {
      result[key] = {
        exists: false,
        defined: false
      };
    }
  }

  return Object.values(result).every((item: any) => item.exists);
}
