export const ACTION_METAINFO = [
  {
    icon: "ri-pencil-fill",
    action: "edit",
    label: "Edit And Drag",
  },
  {
    icon: "ri-drag-move-line",
    action: "add",
    label: "Drag",
  },
];

export const MESSAGES = {
  firstLabelNodeResource:
    "The first label node necessarily corresponds to resource of the API",
  parentArray: "Parent node should be array",
  attributeAlreadyExist: "An attribute with this name already exists",
  resourceAlreadyExist: "A resource with this name already exists",
  listOfObject: "Only list of Objects can be transformed into a resource",
  notValidForURL: "Not a valid resource name.",
};
