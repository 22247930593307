import { Image } from "@storybook/image";
import { FC, useMemo } from "react";

import "./loader.scss";

interface ILoaderDimension {
  dimension?: number;
  className?: "loader-blue" | "loader-white";
  type?: string;
}

export const Loader: FC<ILoaderDimension> = ({
  dimension = 45,
  className = "loader-blue",
  type = "gif",
}) => {
  const renderMainComponent = useMemo(() => {
    if (type === "gif") {
      return <Image fileName="loader.gif" className="gif-loader" />;
    } else
      return (
        <div className="circle-loader">
          <div
            style={{ width: dimension, height: dimension }}
            className={`loader ${className}`}
          />
        </div>
      );
  }, [className, dimension, type]);
  return renderMainComponent;
};
