import { useCallback, useEffect, useMemo, useState } from "react";

import "./summaryCard.scss";
import { useRecoilState, useRecoilValue } from "recoil";
import { toggleAccordionState, treeDataState } from "states/states";
import { ReactDropdown, Switch } from "@storybook";
import { operatorList } from "utils";

export const SummaryCard = ({
  handleSave,
  expressionsList,
  indexOfExpressionList,
  accordionVisibility,
  handleToggleAccordion,
  excludedAttributes,
}: any) => {
  const [selectedSummaryAttributeDesc, setSelectedSummaryAttributeDesc] =
    useState("");

  const [operatorVisibility, setOperatorVisibility] = useState(false);
  const treeData = useRecoilValue(treeDataState);
  const [selectedSummaryAttributes, setSelectedSummaryAttributes] = useState(
    {}
  );

  const [toggleAccordion, setToggleAccordion] = useRecoilState(toggleAccordionState);

  const nonAccordianVisibility = useMemo(() => {
    return expressionsList?.title?.value ? false : true;
  }, [expressionsList]);

  const getSummaryAttributes = useMemo(() => {
    const data = treeData?.children.find((item: any) => {
      return item?.name === "summary";
    });

    const options = data?.children.map((attribute: any) => {
      return { label: attribute?.name, value: attribute?.name };
    });

    const filteredOptions = options?.filter((option) => {
      return !excludedAttributes.includes(option.value);
    });

    return filteredOptions;
  }, [treeData?.children, excludedAttributes]);

  const handleSelectSummaryAttribute = useCallback(
    (e: any) => {
      setSelectedSummaryAttributes({
        ...selectedSummaryAttributes,
        [indexOfExpressionList]: true,
      });
      handleSave(e.value, [], indexOfExpressionList);
    },
    [handleSave, indexOfExpressionList, selectedSummaryAttributes]
  );

  const getValue = useCallback(
    (index: number) => {
      return {
        label: expressionsList?.expression[index]?.value,
        value: expressionsList?.expression[index]?.value,
      };
    },
    [expressionsList?.expression]
  );

  const getSummaryAttributeValue = useCallback(() => {
    return {
      label: expressionsList?.linkedTo,
      value: expressionsList?.linkedTo,
    };
  }, [expressionsList]);

  const handleChangeSelect = useCallback(
    (e: any, index: number) => {
      if (
        expressionsList?.title?.value &&
        !toggleAccordion[indexOfExpressionList]
      )
        return;
      const newExpressionTags = JSON.parse(
        JSON.stringify(expressionsList)
      )?.expression;

      if (index === newExpressionTags.length - 1) {
        newExpressionTags[newExpressionTags.length - 1].value = e.value;
        if (
          expressionsList?.expression[expressionsList?.expression.length - 1]
            ?.type === "attribute"
        ) {
          newExpressionTags.push({ type: "operator", value: "" });
          setOperatorVisibility(false);
        } else {
          newExpressionTags.push({ type: "attribute", value: "" });
        }
      } else {
        newExpressionTags[index].value = e.value;
      }
      handleSave("", newExpressionTags, indexOfExpressionList);
    },
    [expressionsList, handleSave, indexOfExpressionList, toggleAccordion]
  );

  const getRestAttributes = useCallback(() => {
    const data = treeData?.children.filter((item: any) => {
      return item?.name !== "summary";
    });
    const allAttributes: any = [];
    // eslint-disable-next-line array-callback-return
    data.map((resource: any) => {
      // eslint-disable-next-line array-callback-return
      resource?.children.map((attribute: any) => {
        allAttributes.push({
          // label: `${resource?.name}.${attribute?.name}`,
          label: (
            <div>
              <span style={{ color: "#f5af45" }}>{resource?.name}</span>.
              {attribute?.name}
            </div>
          ),
          value: `${resource?.name}.${attribute?.name}`,
        });
      });
    });
    return allAttributes;
  }, [treeData?.children]);

  const getOperators = useCallback(() => {
    const operators = operatorList();
    return Object.values(operators).map((item: any) => {
      return {
        label: item,
        value: item,
      };
    });
  }, []);

  useEffect(() => {
    const summaryResource = treeData?.children.find(
      (item: any) => item?.name === "summary"
    );
    const desc: any = summaryResource?.children?.find(
      (item: any) => item?.name === expressionsList?.linkedTo
    );
    setSelectedSummaryAttributeDesc(desc?.description);
  }, [
    expressionsList?.linkedTo,
    indexOfExpressionList,
    setSelectedSummaryAttributeDesc,
    treeData?.children,
  ]);

  useEffect(() => {
    const newToggleAccordian = JSON.parse(JSON.stringify(toggleAccordion));
    newToggleAccordian[indexOfExpressionList] = expressionsList?.title?.toggle;
    setToggleAccordion(newToggleAccordian);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpratorVisibility = useCallback(() => {
    setOperatorVisibility(true);
  }, [setOperatorVisibility]);

  const handleDeleteTag = useCallback(
    (e: any, index: number) => {
      e?.stopPropagation();
      let newExpressionTags = JSON.parse(
        JSON.stringify(expressionsList)
      )?.expression;
      if (index === 0 && newExpressionTags.length < 3) {
        newExpressionTags.splice(index, 2);
        newExpressionTags.push({ type: "attribute", value: "" });
      } else if (index === newExpressionTags.length - 2) {
        const type = newExpressionTags[index].type;
        newExpressionTags.splice(index, 2);
        newExpressionTags.push({
          type: type === "attribute" ? "attribute" : "operator",
          value: "",
        });
      } else {
        newExpressionTags.splice(index, 2);
      }
      handleSave("", newExpressionTags, indexOfExpressionList);
    },
    [expressionsList, handleSave, indexOfExpressionList]
  );

  const renderExpressionTags = useMemo(() => {
    return expressionsList?.expression?.map((selector: any, index: number) => {
      return (
        <div className="expression-tags-wrapper" key={index}>
          {selector?.type === "attribute" ||
            operatorVisibility ||
            index !== expressionsList?.expression.length - 1 ? (
            <div
              className={`select-wrapper ${expressionsList?.expression[index]?.value &&
                "delete-icon-visibility"
                }`}
              key={index}
            >
              <div
                className="delete-select"
                onClick={(e) => handleDeleteTag(e, index)}
              >
                <i className="ri-delete-bin-5-line"></i>
              </div>

              <div
                className={
                  selector?.type === "attribute"
                    ? "attribute-select"
                    : "operator-select"
                }
              >
                <ReactDropdown
                  options={
                    selector?.type === "attribute"
                      ? getRestAttributes()
                      : getOperators() ?? []
                  }
                  value={getValue(index)}
                  defaultValue={{ label: "", value: "" }}
                  handleChangeSelect={(event) =>
                    handleChangeSelect(event, index)
                  }
                  isCreatable={false}
                  placeholder={selector?.type}
                  isDisabled={false}
                  isSearchable={selector?.type === "attribute" ? true : false}
                  type="tag"
                />
              </div>
            </div>
          ) : (
            <div
              className="plus-icon-wrapper"
              onClick={handleOpratorVisibility}
            >
              <i className="ri-add-circle-fill"></i>
            </div>
          )}
        </div>
      );
    });
  }, [
    expressionsList?.expression,
    getOperators,
    getRestAttributes,
    getValue,
    handleChangeSelect,
    handleDeleteTag,
    handleOpratorVisibility,
    operatorVisibility,
  ]);

  const handleAccordianToggle = useCallback(() => {
    const newToggleAccordian = JSON.parse(JSON.stringify(toggleAccordion));
    newToggleAccordian[indexOfExpressionList] =
      !newToggleAccordian[indexOfExpressionList];

    if (!newToggleAccordian[indexOfExpressionList] && accordionVisibility) {
      handleToggleAccordion(indexOfExpressionList);
    }

    if (newToggleAccordian[indexOfExpressionList] && !accordionVisibility) {
      handleToggleAccordion(indexOfExpressionList);
    }

    if (!newToggleAccordian[indexOfExpressionList]) {
      handleSave("", [{ type: "attribute", value: "" }], indexOfExpressionList);
    }
    setToggleAccordion(newToggleAccordian);
  }, [
    accordionVisibility,
    handleSave,
    handleToggleAccordion,
    indexOfExpressionList,
    toggleAccordion,
  ]);

  const renderQuery = useMemo(() => {
    return (
      <div className="query-container">
        <div
          className="accordian-title"
          onClick={() =>
            toggleAccordion[indexOfExpressionList] &&
            handleToggleAccordion(indexOfExpressionList)
          }
        >
          <div>{expressionsList?.title?.value}</div>
          {/* // <i className="ri-arrow-up-s-line"></i> */}
        </div>
        <div className="query-select-toggle">
          <Switch
            checked={toggleAccordion[indexOfExpressionList]}
            handleChange={handleAccordianToggle}
            onColor="#33b87a"
          />
        </div>
      </div>
    );
  }, [
    expressionsList?.title?.value,
    handleAccordianToggle,
    handleToggleAccordion,
    indexOfExpressionList,
    toggleAccordion,
  ]);

  return (
    <div className="card-wrapper">
      {expressionsList?.title?.value && renderQuery}
      {(nonAccordianVisibility || accordionVisibility) && (
        <div
          className={`${expressionsList?.title?.value
            ? "accordian-body"
            : "non-accordian-body"
            }`}
        >
          <div className="card-header-select">
            {expressionsList?.title?.value ? (
              <div className="accoirdian-summary-attribute">
                {expressionsList?.linkedTo}
              </div>
            ) : (
              <ReactDropdown
                options={getSummaryAttributes ?? []}
                value={getSummaryAttributeValue()}
                defaultValue={{ label: "", value: "" }}
                handleChangeSelect={(event) =>
                  handleSelectSummaryAttribute(event)
                }
                isCreatable={false}
                placeholder={"Select Summary Attribute"}
                isDisabled={false}
                isSearchable={true}
                type="header"
              />
            )}
          </div>
          {selectedSummaryAttributeDesc && (
            <div className="summaryCard-description">
              {selectedSummaryAttributeDesc}
            </div>
          )}
          <div className="expression">{renderExpressionTags}</div>
        </div>
      )}
    </div>
  );
};
