import { Button, Image, Loader, Tooltip } from "@storybook";
import { useNetwork, useNodeTree, useNotification } from "hooks";

import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  SavedTreeData,
  SavedTreeMetadata,
  treeDataState,
  currentNode,
  UrlPrefix,
  IsTopBar,
} from "states/states";

import "./empty-screen.scss";
import { getDateWithTime } from "utils";
import { Topbar } from "components/topbar";
import { DeleteModal } from "components";

export const EmptyScreen = () => {
  const navigate = useNavigate();
  const { remove: deleteSavedTree } = useNetwork();
  const { getNodeById } = useNodeTree();
  const urlPrefix = useRecoilValue(UrlPrefix);
  const isTopbar = useRecoilValue(IsTopBar);
  const [savedTreeData, setsavedTreeData] = useRecoilState(SavedTreeData);
  const [savedTreeMetadata, setSavedTreeMetadata] =
    useRecoilState(SavedTreeMetadata);
  const pathName = useMemo(() => window.location.pathname.replace("/", ""), []);
  const setSelectedNode = useSetRecoilState(currentNode);
  const [treeData, setTreeData] = useRecoilState(treeDataState);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [deletingTreeMetaData, setDeletingTreeMetaData] = useState<any>({});

  const { errorNotification, successNotification } = useNotification();

  const handleOnCancel = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const handleDeleteModal = useCallback(() => {
    deleteSavedTree(`/api/delete-api?id=${deletingTreeMetaData.id}`).then(
      (res) => {
        const { statusCode } = res;
        if (statusCode === 200) {
          successNotification("Item deleted successfully");
          const data = savedTreeData.filter(
            (el: any) => el.id !== deletingTreeMetaData.id
          );
          const metadata = savedTreeMetadata.filter(
            (el: any) => el.id !== deletingTreeMetaData.id
          );
          setsavedTreeData(data);
          setSavedTreeMetadata(metadata);
          setDeletingTreeMetaData({});
        } else {
          errorNotification("Something went wrong");
        }
      }
    );
    setIsModalOpen(false);
  }, [setIsModalOpen, deletingTreeMetaData]);

  const handleDelete = useCallback(
    (e: any, el: any) => {
      e.stopPropagation();
      setIsModalOpen(true);
      setDeletingTreeMetaData(el);
    },
    [deletingTreeMetaData, setDeletingTreeMetaData, setIsModalOpen]
  );

  const handleClick = useCallback(
    (item: any) => {
      sessionStorage.removeItem("selectedTemplate");
      setTreeData(item);
      const data = getNodeById(treeData, item?.parent);

      const payload = {
        parentNode: data,
        currentNode: item,
      };
      setSelectedNode(payload);
      navigate(
        urlPrefix
          ? `../${urlPrefix}/api-editor?id=${item?.id}&navigate=${pathName}`
          : `../api-editor?id=${item?.id}&navigate=${pathName}`
      );
    },
    [
      getNodeById,
      navigate,
      pathName,
      setSelectedNode,
      setTreeData,
      treeData,
      urlPrefix,
    ]
  );

  const handleOnClickEmpty = useCallback(() => {
    sessionStorage.removeItem("selectedTemplate");
    const id = Math.floor(Date.now() * Math.random());
    const payload = {
      id: id,
      name: "",
      api_url: "",
      description: "",
      type: "list",
      version: "1.0",
      label: [],
      children: [
        {
          id: 2,
          name: "resource_1",
          parent: id,
          branchColor: "#FCB900",
          legendId: "default",
          description: "",
          type: "object",
          pageable: false,
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          isRelation: false,
          relation: {},
          children: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: true,
          isSubPath: true,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 3,
          name: "resource_2",
          parent: id,
          branchColor: "#FCB900",
          legendId: "default",
          description: "",
          type: "object",
          pageable: false,
          children: [],
          queryParams: [],
          isRelation: false,
          relation: {},
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: true,
          isSubPath: true,
          inDocumention: false,
        },
      ],
    };
    setTreeData(payload);
    navigate(urlPrefix ? `../${urlPrefix}/describe` : "../describe");
  }, [navigate, setTreeData, urlPrefix]);

  const renderDeleteModal = useMemo(() => {
    return (
      <DeleteModal
        isOpen={isModalOpen}
        onConfirm={handleDeleteModal}
        onCancel={handleOnCancel}
      />
    );
  }, [isModalOpen, deletingTreeMetaData]);

  const renderLists = useMemo(() => {
    if (Array.isArray(savedTreeData) && savedTreeData?.length)
      return savedTreeData?.map((el: any, index: number) => {
        return (
          <div
            key={index + el.name}
            className="item-wrapper"
            onClick={() => handleClick(el)}
          >
            <Tooltip key={index} text={el.name} direction="bottom">
              <div className="name-wrapper">{el.name}</div>
            </Tooltip>
            <div className="info-wrapper">
              {savedTreeMetadata?.filter((item: any) => item?.id === el?.id)[0]
                ?.createdAt && (
                <div className="date-wrapper">
                  <div>Created On:</div>
                  {getDateWithTime(
                    savedTreeMetadata?.filter(
                      (item: any) => item?.id === el?.id
                    )[0]?.createdAt
                  )}
                </div>
              )}
              <div
                className="deleteIcon-wrapper"
                onClick={(e) => handleDelete(e, el)}
              >
                <i className="ri-delete-bin-5-line"></i>
              </div>
            </div>
          </div>
        );
      });
  }, [
    savedTreeData,
    savedTreeMetadata,
    handleClick,
    handleDelete,
    handleDeleteModal,
    handleOnCancel,
    isModalOpen,
    setIsModalOpen,
  ]);

  const renderMainComponent = useMemo(() => {
    if (!savedTreeData?.length) {
      return (
        <div className="empty-screen">
          <div className="empty-screen__inner">
            <div className="empty-screen__inner__img-wrapper">
              <Image fileName="empty-screen.svg" />
            </div>

            <div className="empty-screen__inner__content">
              <div className="empty-screen__inner__content__label">
                You don’t have any APIs set up yet
              </div>
              <div className="empty-screen__inner__content__sub-title">
                This is where we shine!
              </div>
            </div>
            <Button
              label="Create your first API"
              handleClick={() =>
                navigate(urlPrefix ? `../${urlPrefix}/describe` : "describe")
              }
              type="adf-button__filled adf-button__filled--primary adf-button__large "
            />
          </div>
        </div>
      );
    }
    return (
      <>
        <Topbar lable="API Designs" />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, 260px)",
            gap: 24,
            padding: 24,
            overflow: "auto",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              height: "150px",
              padding: "16px",
              background: "var(--adf-color-white-border)",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: 8,
              cursor: "pointer",
            }}
            onClick={handleOnClickEmpty}
          >
            <div
              style={{
                fontSize: "20px",
                display: "grid",
                placeItems: "center",
                width: "40px",
                height: "40px",
                background: "#000",
                borderRadius: "50%",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              <i className="ri-add-line"></i>
            </div>
            <div
              style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
            >
              Create API Design
            </div>
          </div>
          {renderLists}
        </div>
        {renderDeleteModal}
      </>
    );
  }, [
    savedTreeData?.length,
    handleOnClickEmpty,
    renderLists,
    navigate,
    urlPrefix,
    isModalOpen,
  ]);
  return renderMainComponent;
};
