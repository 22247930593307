import React from "react";

export const ThemeContext = React.createContext("");

export function ThemeProvider(props: any) {
  // const [isDark, setIsDark] = useState(true);
  // const toggleTheme = useCallback(() => {
  //   setIsDark((prev) => !prev);
  //   localStorage.setItem("dark", !isDark ? "true" : "false");
  //   const body = document.querySelector("body");
  //   body?.classList.toggle("dark-mode");
  // }, [isDark]);

  // useEffect(() => {
  //   //make dark mode default
  //   localStorage.setItem("dark", "true");
  //   const body = document.querySelector("body");
  //   body?.classList.add("dark-mode");
  // }, []);

  return (
    <ThemeContext.Provider value={"light"}>
      {/* <div className="theme-toggle-icon" onClick={toggleTheme}>
        <i className={` ${isDark ? "ri-moon-fill" : "ri-sun-line"}`}></i>
      </div> */}
      {props.children}
    </ThemeContext.Provider>
  );
}
