const getType = (top: string | number | JSX.Element, bottom?: string) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div className="option-top">{top}</div>
      {bottom && <div className="option-bottom">{bottom}</div>}
    </div>
  );
};

export const DEFINE_FORM_META_DATA = [
  {
    type: "title",
    label: "Type",
  },
  {
    type: "binary-choice",
    key: "select-attribute-type",
    className: "choose-type",
    options: [
      {
        key: "integer",
        label: getType(123, "Integer"),
      },
      {
        key: "number",
        label: getType("1.0", "Number"),
      },
      {
        key: "string",
        label: getType(<i className="ri-text"></i>, "String"),
      },
      {
        key: "boolean",
        label: getType(<i className="ri-toggle-fill"></i>, "Boolean"),
      },
      {
        key: "object",
        label: getType(
          <i style={{ fontSize: "20px" }} className="ri-braces-line"></i>
        ),
      },
      {
        key: "date",
        label: getType(
          <i style={{ fontSize: "20px" }} className="ri-time-line"></i>,
          "Date"
        ),
      },
    ],
  },
  {
    type: "toggle",
    label: "List",
    key: "isArray",
    name: "isArray",
    isBorder: true,
  },
  {
    type: "title",
    label: "Description",
  },
  {
    type: "text",
    placeholder: "resource",
    label: "Name",
    name: "name",
  },
  {
    type: "textarea",
    placeholder: "Additional Information",
    label: "Description",
    name: "xDescription",
    note: "Enter information for internal use that will not be included in your specification",
    isBorder: true,
  },
  {
    type: "title",
    label: "Legend",
  },
  {
    type: "select",
    placeholder: "Default",
    label: "Description",
    isBorder: true,
  },
  {
    type: "toggle",
    label: "Add to the documentation",
    isBorder: true,
    key: "inDocumention",
  },
];

export const REFINE_FORM_META_DATA = [
  {
    type: "title",
    label: "Validation fields",
  },

  {
    title: "Validation fields",
    type: "select",
    placeholder: "Possible values",
    key: "possible-values",
    label: "Specify the expected format of the attribute.",
    name: "enumValues",
    isCreatable: true,
    note: "Press Enter to separate values.",
  },
  {
    type: "toggle",
    label: "Required",
    key: "required",
    isBorder: true,
  },
  {
    type: "title",
    label: "Example",
  },
  {
    type: "textarea",
    placeholder: "Additional Information",
    label:
      "Add an example according to the validation fields, it will also be used by the mock policy",
    name: "example",
    isBorder: true,
    note: "This example is automatically generated from its child attributes.",
  },
  {
    type: "title",
    label: "Description",
  },
  {
    type: "text",
    placeholder: "Description",
    label: "Add a description to make your specification clearer.",
    name: "description",
  },
];

export const EXPOSE_FORM_META_DATA = [
  {
    type: "title",
    label: "Resource",
    key: "resource-title",
  },
  {
    type: "binary-choice",
    className: "select-resource-wrapper",
    key: "isSubPath",
    label:
      "Transform the attribute into a resource so that it can be exposed by the API and manipulated through operations.",
    options: [
      {
        key: "yes",
        label: "Yes",
      },
      {
        key: "no",
        label: "No",
      },
    ],
  },
  {
    type: "title",
    label: "Search",
  },
  {
    type: "toggle",
    key: "queryable",
    label: "Add to filters",
  },
  {
    type: "toggle",
    key: "abstract",
    label: "Add to result",
  },
  { type: "title", label: "Operations" },
  {
    type: "binary-choice",
    className: "select-operations",
    name: "crud",
    key: "select-crud-operation",
    label: "Define how the resource is manipulated.",
    options: [
      {
        key: "CREATE",
        label: "Create",
      },
      {
        key: "READ",
        label: "Read",
      },
      {
        key: "UPDATE",
        label: "Update",
      },
      {
        key: "DELETE",
        label: "Delete",
      },
    ],
  },
  {
    type: "toggle",
    key: "patchEnabled",
    label: "Enable partial update",
  },
  {
    type: "toggle",
    key: "searchEnabled",
    label: "Enable search",
  },
  {
    type: "toggle",
    key: "pageable",
    label: "Paginated",
  },

  {
    type: "title",
    label: "Visibility",
    key: "visibility-title",
  },
  {
    type: "binary-choice",
    key: "select-response-type",
    name: "visibility",
    label:
      "Define whether the attribute is returned by the server when manipulating the resource.",
    className: "choose-visibility",
    note: "The consumer can enter a value when creating/updating the resource.",
    options: [
      {
        key: "READ_WRITE",
        label: getType(
          <i className="ri-arrow-left-right-line" />,
          "Request & Response"
        ),
      },
      {
        key: "WRITE_ONLY",
        label: getType(
          <i className="ri-arrow-left-right-line" />,
          "Request only"
        ),
      },
      {
        key: "READ_ONLY",
        label: getType(
          <i className="ri-arrow-left-right-line" />,
          "Response only"
        ),
      },
    ],
  },
];

export const IMPORT_FORM_META_DATA = [
  {
    type: "switch",
    key: "searchEnabled",
    values: [
      {
        key: "file-import",
        label: "File Import",
      },
      {
        key: "csv-file-table",
        label: "Csv file tablet",
      },
    ],
  },
];

export const BLOCK_ACTION_RESOURCE_YES = [
  "resource-title",
  "queryable",
  "abstract",
  "isSubPath",
  "select-crud-operation",
  "pageable",
  "searchEnabled",
  "patchEnabled",
];

export const BLOCK_ACTION_RESOURCE_NO = [
  "resource-title",
  "queryable",
  "abstract",
  "isSubPath",
  "select-response-type",
  "visibility-title",
];

export const BLOCK_ACTION_CSV_FILE_IMPORT = [
  "select-csv-import-method",
  "csv-import-title",
  "csv-description",
  "csv-file-import",
  "csv-note",
];

export const BLOCK_ACTION_CSV_MANUAL_IMPORT = [
  "select-csv-import-method",
  "csv-manual-import",
  "csv-note",
];

export const DEFAULT_CSV_HEADER = [
  {
    r: 0,
    c: 0,
    v: {
      bl: 1,
      m: "Key",
      v: "Key",
    },
  },
  {
    r: 0,
    c: 1,
    v: {
      bl: 1,
      m: "Type",
      v: "Type",
    },
  },
  {
    r: 0,
    c: 2,
    v: {
      bl: 1,
      m: "Description",
      v: "Description",
    },
  },
];
