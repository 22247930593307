import { useEnvUrl } from "hooks";

interface IImage {
  fileName?: string;
  url?: string;
  className?: string;
  height?: string;
  width?: string;
  props?: any;
  onLoad?: any;
}

export const Image = ({
  fileName,
  url: imageUrl,
  className,
  height,
  width,
  props,
  onLoad,
}: IImage) => {
  const { getAppUrl } = useEnvUrl();
  const url = imageUrl ?? `${getAppUrl()}/media/${fileName}`;

  return (
    <img
      src={url}
      alt={fileName}
      className={className ?? ""}
      height={height}
      width={width}
      onLoad={onLoad}
      {...props}
    />
  );
};
