import { useEffect, useState } from "react";
import { Loader } from "@storybook";
import SwaggerUI from "swagger-ui-react";
import "@anandesh/rapi-doc";

import { useCookie, useNetwork, useParams } from "hooks";
import { useRecoilValue } from "recoil";
import { isDarkMode } from "states/theme";
import {
  documentationType,
  documentationID,
  SavedTreeData,
  RootHeight,
} from "states/states";

import "./swagger-collection.scss";
import "swagger-ui-react/swagger-ui.css";

export const SwaggerCollection = () => {
  let { getParams } = useParams();
  const [swaggerObj, setSwaggerObj] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [jsonUrl, setJsonUrl] = useState<string>("");
  const { get } = useCookie();
  const userInfo = get("user");
  const isdarkMode = useRecoilValue(isDarkMode);
  const specType = useRecoilValue(documentationType);
  const specID = useRecoilValue(documentationID);
  const savedTreeData = useRecoilValue(SavedTreeData);
  const rootHeight = useRecoilValue(RootHeight);
  const { get: fetchSwagger } = useNetwork();
  const dynamicHeight = rootHeight ? Number(rootHeight) + 104 : 104;
  const url = `/api/fetch-open-api-json?userId=${userInfo.email}&name=${
    savedTreeData.find(
      (item: any) =>
        item?.id === Number(specID || getParams(window.location.href, "id"))
    )?.api_url
  }&id=${specID || getParams(window.location.href, "id")}`;

  useEffect(() => {
    setIsLoading(true);
    fetchSwagger(url).then((res) => {
      setSwaggerObj(res);
      setIsLoading(false);
    });
  }, [fetchSwagger, url]);

  useEffect(() => {
    if (swaggerObj) {
      const jsonStr = JSON.stringify(swaggerObj);
      const dataUrl = `data:application/json;charset=utf-8,${encodeURIComponent(
        jsonStr
      )}`;
      setJsonUrl(dataUrl);
    }
  }, [setJsonUrl, swaggerObj]);

  return (
    <div className="swagger-collection">
      {/* <div className="swagger-collection__header">
        <div className="swagger-collection__header__title">
          Preview your documentation
        </div>
        <Button
          label="Export"
          handleClick={function (): void {}}
          type={"swagger-collection__header__btn"}
        />
      </div> */}
      {isLoading ? (
        <Loader type="circle" className="loader-blue" dimension={20} />
      ) : (
        <div
          className="swagger-collection__inner"
          style={{
            height: `calc(100vh - ${dynamicHeight}px)`,
          }}
        >
          {specType === "swagger" ? (
            <SwaggerUI spec={swaggerObj} />
          ) : (
            <rapi-doc
              spec-url={jsonUrl}
              regular-font="Open Sans"
              mono-font="Roboto Mono"
              show-header="false"
              bg-color=""
              text-color=""
              nav-bg-color={!isdarkMode ? "#676a89" : "#676a89"}  //need this dynamic, #fff for light theme
              nav-text-color=""
              nav-hover-bg-color=""
              nav-hover-text-color=""
              nav-accent-color="#fd6964"
              primary-color="#ea526f"
              render-style="focused"
              theme="light"
              show-method-in-nav-bar="as-colored-block"
              allow-spec-url-load="true"
              allow-spec-file-load="true"
              schema-style="table"
            ></rapi-doc>
          )}
        </div>
      )}
    </div>
  );
};
