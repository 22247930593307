import { useCallback, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { Switch, Image } from "@storybook";

import { useCookie } from "hooks";
import { isDarkMode } from "states/theme";
import { SIDEBAR_TABS } from "./constants";

import "./sidebar.scss";

export const Sidebar = ({ logo, isdarkMode, logout, url_prefix }: any) => {
  const [isDark, setIsDark] = useRecoilState(isDarkMode);
  const [isCollapsed, setIsCollapsed] = useState(false); // State to track sidebar collapse
  const { deleteCookie } = useCookie();
  const navigate = useNavigate();

  const toggleTheme = useCallback(() => {
    setIsDark((prev: boolean) => !prev);
    localStorage.setItem("dark", !isDark ? "true" : "false");
    const body = document.getElementById("api-designer-root");
    body?.classList.toggle("dark-mode");
  }, [isDark, setIsDark]);

  const handleLogout = () => {
    deleteCookie("user");
    window.location.href = "../signin";
  };

  const handleNavigate = useCallback(
    (item: string) => {
      navigate(url_prefix ? `../${url_prefix}/${item}` : `../${item}`);
    },
    [navigate, url_prefix]
  );

  const handleToggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  return (
    <div className={`sidebar${isCollapsed ? " sidebar--collapsed" : ""}`}>
      {logo && (
        <div className="sidebar__header">
          {isCollapsed ? (
            <Image className="sidebar__header__logo" fileName="logo.svg" />
          ) : (
            <img
              src={isCollapsed ? "logo.svg" : logo}
              alt="logo"
              style={{
                width: "200px",
                height: "",
                objectFit: "contain",
              }}
            />
          )}
        </div>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100% - 100px)",
        }}
      >
        <div className="sidebar__list">
          {SIDEBAR_TABS.map((el, index: number) => {
            return (
              <div
                key={index + el.label}
                className={`sidebar__list__item${
                  isCollapsed ? " sidebar__list__item--collapsed" : ""
                }`}
                onClick={() => handleNavigate(el.link)}
                style={{
                  ...(el.isDisabled && {
                    opacity: el.isDisabled ? 0.4 : 1,
                    cursor: "not-allowed",
                  }),
                }}
              >
                <i className={el.icon}></i>
                {!isCollapsed && el.label}
              </div>
            );
          })}
        </div>
        <div
          className="sidebar__list"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            gap: 16,
          }}
        >
          {isdarkMode && (
            <div
              className={`sidebar__list__item${
                isCollapsed ? " sidebar__list__item--collapsed" : ""
              }`}
            >
              <i className="ri-moon-line" onClick={toggleTheme}></i>
              {!isCollapsed && "Dark mode"}
              {!isCollapsed && (
                <Switch
                  checked={isDark}
                  handleChange={toggleTheme}
                  onColor="#33b87a"
                />
              )}
            </div>
          )}
          {logout && (
            <div
              onClick={handleLogout}
              className={`sidebar__list__item${
                isCollapsed ? " sidebar__list__item--collapsed" : ""
              }`}
            >
              <i className="ri-logout-circle-line"></i>{" "}
              {!isCollapsed && "Logout"}
            </div>
          )}
        </div>
        <div
          className="sidebar__collapse-button"
          onClick={handleToggleCollapse}
        >
          <i
            className={`ri-${
              isCollapsed ? "arrow-right-s-line" : "arrow-left-s-line"
            }`}
          ></i>
        </div>
      </div>
    </div>
  );
};
