import { CSSProperties, FC, useCallback, useState } from "react";

import "./tooltip.scss";

interface ITooltip {
  /**
   * this props is used to show text in tooltip
   */
  text: JSX.Element | string;
  /*
   *this props define on which direction you want to show your tooltip default value = right
   */
  direction?: string;
  /*
   *this props accept JSX element
   */
  children: JSX.Element | JSX.Element[];
  /*
   *this props Accept style for Tooltip box
   */
  customStyle?: CSSProperties;
  /*
   * this props will accept number and it will make delay to disappear your tooltip default value = 100
   */
  disappear?: number;
  /*
   *this props will accept number and it will make delay to appear your tooltip default value = 100
   */
  appear?: number;
  className?: string;
}

/**
 * Tooltips can be attached to any active element (icons, text links, buttons, etc.) on a page. They provide descriptions or explanations for their paired element. Thus, tooltips are highly contextual and specific and don't explain the bigger picture or entire task flow.
 * ITooltip Accept few Props Example  {text , direction ,children ,customStyle ,disappear ,appear}
 * @param ITooltip React Props
 */

export const Tooltip: FC<ITooltip> =
  ({
     text, direction = "right", //default value is right as it is optional props
     children,
     disappear = 100, //default value is 100 as it is optional props
     appear = 100, // default value is 100 as it is optional props
     customStyle, className
   }) => {

    const [active, setActive] = useState(false);

    const showTooltip = useCallback(() => {
      setTimeout(() => {
        setActive(true);
      }, appear);
    }, [appear]);

    const hideToolTip = useCallback(() => {
      setTimeout(() => {
        setActive(false);
      }, disappear);
    }, [disappear]);

    return (<div className={`tooltip-wrapper ${className || ''}`}
      onMouseLeave={hideToolTip} onMouseEnter={showTooltip}>
      {children}
      {active && (
        <div className={`tooltip-tip ${direction}`} style={customStyle}>
          {text}
        </div>
      )}
    </div>);
  };
