import { DescribeAPI } from "./components";

export const Describe = () => {
  return (
    <div
      className="api-designer-describe"
      style={{
        display: "grid",
        placeItems: "center",
        // position: "absolute",
        // inset: 0,
        height: "100%"
      }}
    >
      <DescribeAPI />
    </div>
  );
};
