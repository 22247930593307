import { Button, Input } from "@storybook";
import { useCookie, useNotification } from "hooks";

import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DUMMY_LOGIN_CODE } from "./constants";

import "./signin.scss";

export const SignIn = () => {
  const [step, setStep] = useState("CREDS");
  const [state, setState] = useState({
    email: "",
    code: "",
  });

  const { set } = useCookie();

  const nagivate = useNavigate();

  const { successNotification, errorNotification } = useNotification();

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setState({ ...state, [name]: value });
    },
    [state]
  );

  const renderVerify = useMemo(() => {
    if (step !== "CREDS")
      return (
        <Input
          label="Login Code"
          handleChange={handleOnChange}
          placeholder=""
          inputType="text"
          inputName="code"
        />
      );
  }, [handleOnChange, step]);

  const handleOnSuccess = useCallback(() => {
    successNotification("Login successfully");
    const payload = {
      email: state.email,
    };
    set("user", payload);

    nagivate("../");
  }, [nagivate, set, state, successNotification]);

  const handleSubmit = useCallback(async () => {
    if (step === "CREDS") setStep("VERIFY");
    if (step === "VERIFY") {
      if (DUMMY_LOGIN_CODE.includes(state.code)) {
        handleOnSuccess();
      } else errorNotification("Login code does not match");
    }
  }, [step, state, handleOnSuccess, errorNotification]);

  const renderButtonLabel = useMemo(() => {
    if (step === "CREDS") return "Next";
    if (step === "VERIFY") return "Submit";
  }, [step]);

  const isDisabled = useMemo(() => {
    if (step === "CREDS") {
      if (state.email?.length > 4) return false;
      return true;
    }
    if (step === "VERIFY")
      if (state.email?.length > 4 && state.code.length > 5) return false;
    return true;
  }, [state, step]);
  return (
    <div className="signin-wrapper">
      <div className="signin-wrapper__inner">
        <div className="signin-wrapper__inner__form">
          <div>
            <div className="signin-wrapper__inner__form__title">Login</div>
            <div className="signin-wrapper__inner__form__subtitle">
              Please log in to your account.
            </div>
          </div>
          <Input
            label="Email"
            handleChange={handleOnChange}
            placeholder=""
            inputName="email"
            inputType="text"
          />

          {renderVerify}

          <Button
            handleClick={handleSubmit}
            label={renderButtonLabel ?? ""}
            type="adf-button adf-button__filled--primary adf-button__large adf-button__block "
            disabled={isDisabled}
          />
        </div>
      </div>
    </div>
  );
};
