import { useCookie, useNetwork } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";

import { isDarkMode } from "states/theme";
import { Sidebar } from "components";
import { Bounce, ToastContainer } from "react-toastify";

import {
  APIEditor,
  EmptyScreen,
  Describe,
  APICollections,
  SignIn,
  Documentations,
} from "views";

import { UploadScreen } from "views/describe-api/components";
import { Loader } from "@storybook";
import {
  AccessToken,
  IsTopBar,
  SavedTreeData,
  SavedTreeMetadata,
  UrlPrefix,
  RootHeight,
  RootWidth,
} from "states/states";

import "../App.css";
import { TemplateScreen } from "views/template-screen/template-screen";

export const AllRoutes = ({
  isSidebar,
  token,
  logo,
  isdarkMode,
  logout,
  isAdmin,
  url_prefix,
  height,
  width,
  isTopbar,
}: any) => {
  const [isDark, setIsDark] = useRecoilState(isDarkMode);
  const [accessToken, setAccessToken] = useRecoilState(AccessToken);
  const setUrlPrefix = useSetRecoilState(UrlPrefix);
  const setRootHeight = useSetRecoilState(RootHeight);
  const setRootWidth = useSetRecoilState(RootWidth);
  const setIsTopBar = useSetRecoilState(IsTopBar);

  const { get: getAllTree, loading } = useNetwork();

  const { get } = useCookie();
  const navigate = useNavigate();
  const location = useLocation();

  const routes = useRoutes([
    {
      path: url_prefix ? `${url_prefix}/*` : "*",
      element: <EmptyScreen />,
    },
    {
      path: "/signin",
      element: <SignIn />,
    },
    {
      path: url_prefix ? `${url_prefix}/` : "/",
      element: <EmptyScreen />,
    },
    {
      path: url_prefix ? `${url_prefix}/describe` : "/describe",
      element: <Describe />,
    },
    {
      path: url_prefix ? `/${url_prefix}/import` : "/import",
      element: <UploadScreen />,
    },
    {
      path: url_prefix ? `${url_prefix}/templates` : "/templates",
      element: <TemplateScreen />,
    },
    {
      path: url_prefix ? `${url_prefix}/api-editor` : "/api-editor",
      element: <APIEditor />,
    },
    {
      path: url_prefix ? `${url_prefix}/api-collections` : "/api-collections",
      element: <APICollections />,
    },
    {
      path: url_prefix ? `${url_prefix}/documentations` : "/documentations",
      element: <Documentations />,
    },
  ]);

  useEffect(() => {
    const isDark = localStorage.getItem("dark");
    if (isDark === "false") {
      setIsDark(false);
      localStorage.setItem("dark", "false");
      const body = document.getElementById("api-designer-root");
      body?.classList.add("light-mode");
    } else {
      localStorage.setItem("dark", "true");
      const body = document.getElementById("api-designer-root");
      body?.classList.add("dark-mode");
    }
    if (height && width) {
      const customBodyStyles = document.createElement("style");
      customBodyStyles.innerHTML = `
    #api-designer-root {
      height: calc(100vh - 64px);
    }
    `;
      setRootWidth(width);
      setRootHeight(height);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token) {
      setAccessToken(token);
    }
  }, [setAccessToken, token]);

  useEffect(() => {
    if (url_prefix) {
      setUrlPrefix(url_prefix);
    }
  }, [setUrlPrefix, url_prefix]);

  useEffect(() => {
    setIsTopBar(isTopbar);
  }, [setIsTopBar, isTopbar]);

  useEffect(() => {
    const data = get("user");
    if (!data.email && isAdmin) {
      navigate("/signin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [savedTreeData, setSavedTree] = useRecoilState(SavedTreeData);
  const setSavedTreeMetadata = useSetRecoilState(SavedTreeMetadata);
  useEffect(() => {
    setIsLoading(true);
    if (accessToken && !savedTreeData.length)
      getAllTree("/api/save-changes").then((res) => {
        const { statusCode, data } = res ?? {};
        if (statusCode === 200) {
          const rsponse = data?.map((el: any) => el.saveObject);
          const metaData = data?.map((el: any) => {
            return {
              id: el?.id,
              apiAccessKey: el?.apiAccesskey ?? "",
              name: el?.saveObject?.name ?? "",
              version: el?.saveObject?.version ?? "",
              resourceCount: el?.saveObject?.children?.length ?? 0,
              description: el?.saveObject?.description ?? "",
              createdAt: el?.createdAt ?? "",
              updatedAt: el?.updatedtedAt ?? "",
              api_url: el?.api_url ?? "",
            };
          });
          setSavedTree(rsponse);
          setSavedTreeMetadata(metaData);
          setIsLoading(false);
        }
        else{
          setIsLoading(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const props = {
    isdarkMode,
    logout,
    logo,
    url_prefix,
  };
  const renderSidebar = useMemo(() => {
    if (!/api-editor|signin/.test(location.pathname)) {
      if (isSidebar) return <Sidebar {...props} />;
    }
  }, [location, isSidebar, props]);

  const isLoader = loading || isLoading || !accessToken;
  const renderMainComponent = useMemo(() => {
    return (
      <>
        <div className="api-designer-navigator">
          <span id="adf-home" onClick={() => navigate("/admin/api-designer")}>
            Home
          </span>
          <span
            id="adf-documentations"
            onClick={() => navigate("/admin/api-designer/documentations")}
          >
            Documentations
          </span>
        </div>
        {renderSidebar}
        <div className="app-container__inner">
          {isLoader ? (
            <div
              style={{
                position: "absolute",
                top:0,
                bottom:0,
                left:0,
                right:0,
                display: "grid",
                placeItems: "center",
              }}
            >
              <Loader type="circle" />
            </div>
          ) : (
            routes
          )}
        </div>
      </>
    );
  }, [isLoader, renderSidebar, routes]);

  return (
    <>
      <div className="app-container adComp" id="api-designer-root">
        {renderMainComponent}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        transition={Bounce}
        className="adf-toaster"
        theme={isDark ? "dark" : "light"}
      />
    </>
  );
};
