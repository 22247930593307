import { ReactResponsiveTable, Tooltip } from "@storybook";
import { useNetwork } from "hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  AccessToken,
  RootHeight,
  SavedTreeData,
  SavedTreeMetadata,
  UrlPrefix,
  documentationID,
  documentationType,
} from "states/states";
import { DOCUMENTS_HEADER } from "./constants";

import "./documentations.scss";
import { getDateWithTime, copyText } from "utils";

export const Documentations = () => {
  const [data, setData] = useState([]);
  const accessToken = useRecoilValue(AccessToken);
  const urlPrefix = useRecoilValue(UrlPrefix);

  const rootHeight = useRecoilValue(RootHeight);

  const navigate = useNavigate();

  const [savedTreeData, setSavedTreeData] = useRecoilState(SavedTreeData);
  const [savedTreeMetadata, setSavedTreeMetadata] =
    useRecoilState(SavedTreeMetadata);
  const { get: getAllTree } = useNetwork();
  const setDocumentationType = useSetRecoilState<string>(documentationType);
  const setDocumentationID = useSetRecoilState<number>(documentationID);

  const pathName = useMemo(() => window.location.pathname.replace("/", ""), []);

  useEffect(() => {
    if (accessToken && !savedTreeData.length)
      getAllTree("/api/save-changes").then((res) => {
        const { statusCode, data } = res ?? {};
        if (statusCode === 200) {
          const rsponse = data?.map((el: any) => el.saveObject);
          const metaData = data?.map((el: any) => {
            return {
              id: el?.id,
              apiAccessKey: el?.apiAccesskey ?? "",
              name: el?.saveObject?.name ?? "",
              version: el?.saveObject?.version ?? "",
              resourceCount: el?.saveObject?.children?.length ?? 0,
              description: el?.saveObject?.description ?? "",
              createdAt: el?.createdAt ?? "",
              updatedAt: el?.updatedtedAt ?? "",
            };
          });
          setSavedTreeData(rsponse);
          setSavedTreeMetadata(metaData);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const handleOpenSwagger = useCallback(
    (item: any, type: string) => {
      setDocumentationType(type);
      setDocumentationID(item?.id);
      navigate(
        urlPrefix
          ? `../${urlPrefix}/api-collections?entity=${item.name}&navigate=${pathName}&id=${item?.id}#overview`
          : `../api-collections?entity=${item.name}&navigate=${pathName}&id=${item?.id}#overview`
      );
    },
    [navigate, pathName, setDocumentationID, setDocumentationType, urlPrefix]
  );

  useEffect(() => {
    if (savedTreeMetadata && DOCUMENTS_HEADER.length) {
      const rows: any = [];
      savedTreeMetadata.forEach((fundItem: any, index: any) => {
        if (fundItem && fundItem?.apiAccessKey) {
          let row: any = {};
          DOCUMENTS_HEADER.forEach(({ format, key }) => {
            row.resource = fundItem?.resourceCount;
            // row.key = "bgr5fttm-gg-gggggggg-ffe mef";
            // row.key = fundItem?.apiAccessKey;

            if (format === "jsx" && key === "key") {
              const value = () => (
                <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                  <Tooltip key={index} text={fundItem["apiAccessKey"]} direction="bottom">
                    <div className="btn-group text-50px-elipsis">{fundItem["apiAccessKey"]}</div>
                  </Tooltip>
                  <i className="ri-file-copy-line copy-icon"
                    onClick={() => copyText(fundItem["apiAccessKey"])}/>
                </div>
              );
              return (row[key] = value);
            }
            if (format === "jsx" && key === "action") {
              const value = () => (
                <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                  <i className="ri-share-box-line"></i>
                  <div
                    onClick={() => handleOpenSwagger(fundItem, "rapidoc")}
                    style={{
                      cursor: "pointer",
                      fontSize: "20px",
                      display: "flex",
                      alignItems: "center",
                      gap: 16,
                    }}>
                    <div style={{ fontSize: "12px" }}>rapidoc</div>
                  </div>
                </div>
              );
              return (row[key] = value);
            } else if (fundItem[key] && key === "createdAt") {
              const value = getDateWithTime(fundItem[key]);
              row = {
                ...row,
                [key]: value,
              };
            } else {
              row = {
                ...row,
                [key]: fundItem[key],
              };
            }
            return null;
          });
          rows.push(row);
        }
      });
      return setData(rows);
    }
  }, [handleOpenSwagger, savedTreeMetadata]);

  return (
    <div
      className="documentation-wrapper"
      style={{
        height: `calc(100vh - ${rootHeight ? rootHeight : 0}px)`,
      }}
    >
      <ReactResponsiveTable column={DOCUMENTS_HEADER as any} rows={data} />
    </div>
  );
};
