import { ChangeEvent, FC, KeyboardEvent } from "react";
import { Button, CountryCode } from "@storybook";

import "./input.scss";

interface IInput {
  label: string;
  inputType: "text" | "number" | "password";
  placeholder: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  isCountryCodeVisible?: boolean;
  handleChangeCountry?: (e: any) => void;
  isShowButton?: boolean;
  handleClickBtn?: () => void;
  errorMessage?: string;
  isError?: boolean;
  handleClearField?: () => void;
  handleKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  allowClear?: boolean;
  id?: string;
  handleBlur?: () => void;
  prefixIcon?: string;
  disabled?: boolean;
  suffixIcon?: string;
  handleSuffixIcon?: () => void;
  autoComplete?: string;
  countryCode?: string;
  inputName?: string;
  height?: string;
}

export const Input: FC<IInput> = ({
  label,
  inputType,
  id,
  placeholder,
  handleChange,
  value,
  handleKeyPress,
  allowClear = false,
  handleClearField,
  isError = false,
  errorMessage,
  isShowButton,
  handleClickBtn,
  isCountryCodeVisible,
  handleChangeCountry,
  handleBlur = () => {},
  prefixIcon,
  disabled = false,
  suffixIcon,
  handleSuffixIcon,
  countryCode,
  autoComplete,
  inputName,
  height = "52px",
}) => {
  return (
    <div className="adf-input">
      {label && (
        <label htmlFor={id} className="adf-input__label">
          {label}
        </label>
      )}
      <div className="adf-input__value-container">
        <div className="adf-input__group">
          <div
            className={`adf-input__text-field adf-input__text-field${
              isError ? "--error" : ""
            }`}
          >
            {isCountryCodeVisible && handleChangeCountry && (
              <CountryCode
                handleChangeCountry={handleChangeCountry}
                countryCode={countryCode}
              />
            )}
            {prefixIcon && <i className={prefixIcon} />}
            <input
              placeholder={placeholder}
              type={inputType}
              name={inputName}
              onBlur={handleBlur}
              id={id}
              className="adf-input__field"
              onChange={handleChange}
              value={value}
              onKeyPress={handleKeyPress}
              disabled={disabled}
              autoComplete={autoComplete}
            />
            {allowClear && (
              <i
                className="ri-close-line adf-input__delete"
                onClick={handleClearField}
              />
            )}
            {suffixIcon && (
              <i
                className={`${suffixIcon} adf-input__view`}
                onClick={handleSuffixIcon}
              />
            )}
          </div>
          {isShowButton && handleClickBtn && (
            <Button
              label="Add"
              handleClick={handleClickBtn}
              type="adf-button__outline adf-button__large"
            />
          )}
        </div>

        {isError && errorMessage && (
          <span className="adf-input__error">{errorMessage}</span>
        )}
      </div>
    </div>
  );
};
