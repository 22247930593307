export const DESCRIBE_API_META = [
  {
    label: "API Import",
    path: "import",
    key: "apiImport",
    icon: "ri-braces-line",
    rightIcon: "ri-arrow-right-s-line",
  },
  {
    label: "Manual Editor",
    path: "api-editor",
    key: "manualEditor",
    icon: "ri-smartphone-line",
    rightIcon: "ri-arrow-right-s-line",
  },
  {
    label: "Templates",
    key: "templates",
    path: "templates",
    icon: "ri-smartphone-line",
    rightIcon: "ri-arrow-right-s-line",
  },
  // {
  //   label: "Unsecured Consumer Debt",
  //   key: "unsecuredConsumer",
  //   path: "api-editor",
  //   icon: "ri-smartphone-line",
  //   rightIcon: "ri-arrow-right-s-line",
  // },
];
