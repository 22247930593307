export const INPUTS_META_DATA = [
  {
    type: "text",
    name: "name",
    placeholder: "Enter API Name",
    label: "Name",
    errorMessage: {
      alreadyExist: "API name already exists",
      empty: "Empty name is not allowed",
    },
  },
  {
    type: "text",
    name: "api_url",
    placeholder: "Enter API URL",
    label: "API URL",
    errorMessage: {
      notValid: "API URL is not valid.",
      alreadyExist: "API URL already exists",
      empty: "Empty API URL is not allowed",
      bigUrl: "API URL can't be more than 20 letters.",
      specialChar: "API URL with special character is not allowed.",
    },
  },
  {
    type: "text",
    name: "version",
    placeholder: "1.0.0",
    label: "Version",
    errorMessage: {},
  },
  {
    type: "textarea",
    name: "description",
    placeholder: "Add your description here",
    label: "Description",
    errorMessage: {},
  },
  {
    type: "select",
    key: "root-label",
    name: "label",
    isCreatable: true,
    placeholder: "Label",
    label: "label",
    errorMessage: {},
  },
];
export const ACCORDION_QUERIES = [
  {
    title: "Is there Debt in this asset with principal payments?",
    summaryAttribute: "total_principal_received_on_debt",
  },
  {
    title: "Is there Interests being paid on the debt?",
    summaryAttribute: "total_interest_received_on_debt",
  },
  {
    title: "Are there royalties or dividends being paid in this asset?",
    summaryAttribute: "total_dividend_or_royalty_paid",
  },
  {
    title: "Is there Debt in this asset?",
    summaryAttribute: "outstanding_principal_on_active_debt",
  },
];
