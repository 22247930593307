export function areBothElementsPresent(
  arrOfArrays: (number | string | boolean)[][],
  element1: number | string | boolean,
  element2: number | string | boolean,
) {
  for (const nestedArray of arrOfArrays) {
    // Check if the current nested array contains both elements
    if (nestedArray.includes(element1) && nestedArray.includes(element2)) {
      return true; // Found an exact match, return true
    }
  }

  // If the loop completes without finding an exact match, return false
  return false;
}

export function findAndRemoveExactMatches(
  arrOfArrays: (number | string | boolean)[][],
  id1: number | string | boolean,
  idsToExclude: (number | string | boolean)[],
): (number | string | boolean)[][] {
  const newArray = arrOfArrays.slice(); // Create a copy of the original array

  const combinationsToExclude = generateCombinations([...idsToExclude, id1], 2).map(
    (combination: any) => combination.sort(),
  );

  const combinationsToKeep = generateCombinations([id1, ...idsToExclude], 2).map(
    (combination: any) => combination.sort(),
  );

  for (const combination of combinationsToExclude) {
    for (let i = 0; i < newArray.length; i++) {
      const nestedArray = newArray[i];
      // Check if the current nested array is an exact match with the combination
      const sortedNestedArray = [...nestedArray].sort();
      if (sortedNestedArray[0] === combination[0] && sortedNestedArray[1] === combination[1]) {
        newArray.splice(i, 1); // Remove the exact matching nested array
        i--; // Decrement the loop counter to account for the removed element
      }
    }
  }

  for (const combination of combinationsToKeep) {
    for (let i = 0; i < newArray.length; i++) {
      const nestedArray = newArray[i];
      // Check if the current nested array is an exact match with the combination to keep
      const sortedNestedArray = [...nestedArray].sort();
      if (sortedNestedArray[0] === combination[0] && sortedNestedArray[1] === combination[1]) {
        newArray.splice(i, 1); // Remove the exact matching nested array
        i--; // Decrement the loop counter to account for the removed element
      }
    }
  }
  return newArray;
}

export function isAnyElementPresent(
  arrOfArrays: (number | string | boolean)[][],
  element1: number | string | boolean,
  element2: number | string | boolean,
) {
  const seen = new Set();

  // Iterate through the array of arrays
  for (const nestedArray of arrOfArrays) {
    // Iterate through each nested array
    for (const element of nestedArray) {
      seen.add(element);

      // If either element is present in the set, return true
      if (seen.has(element1) || seen.has(element2)) {
        return true;
      }
    }
  }

  // If the loop completes without finding either element, return false
  return false;
}

export function findMatchingNestedArray(
  arrayOfArrays: any,
  targetID1: number,
  targetID2: number,
): [number, number] | [] {
  for (const nestedArray of arrayOfArrays) {
    if (nestedArray[0] === targetID1 || nestedArray[1] === targetID1) {
      return nestedArray;
    } else if (nestedArray[0] === targetID2 || nestedArray[1] === targetID2) {
      return nestedArray;
    }
  }
  // Return null or a default value if neither targetID1 nor targetID2 are found
  return [];
}

function generateCombinations(arr: any, k: any) {
  const results: any = [];

  function backtrack(start: any, currentCombo: any) {
    if (currentCombo.length === k) {
      results.push([...currentCombo]);
      return;
    }

    for (let i = start; i < arr.length; i++) {
      currentCombo.push(arr[i]);
      backtrack(i + 1, currentCombo);
      currentCombo.pop();
    }
  }

  backtrack(0, []);

  return results;
}
