import { useNodeTree, useNotification } from "hooks";
import { FC, useCallback, useMemo, useState } from "react";
import Tree from "react-d3-tree";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import {
  currentNode,
  isRootNodeSelected,
  treeDataState,
  RelationLinkedList,
} from "states/states";
import { useCenteredTree } from ".";

interface INodeTree {
  data?: any;
  handleRemoveNode: Function;
  handleAddNode: Function;
  isEditable?: boolean;
  nodeMetadata?: any;
}
export const NodeTree: FC<INodeTree> = ({
  data,
  handleRemoveNode,
  handleAddNode,
  isEditable,
  nodeMetadata,
}) => {
  const [treeData, setTreeData] = useRecoilState(treeDataState);
  const [selectedNode, setSelectedNode] = useRecoilState(currentNode);
  const [enteredMouse, setEnteredMouse] = useState<any>(null);
  const setIsRootNode = useSetRecoilState(isRootNodeSelected);
  const linkedList = useRecoilValue(RelationLinkedList);
  const { getNodeById } = useNodeTree();
  const { errorNotification } = useNotification();
  // const nodeDataRef = useRef<any>({});
  const nodeDataRef = useMemo<any>(() => {
    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData]);

  const collectNodeData = (nodeDatum: any) => {
    // Add the nodeDatum to the data collection
    nodeDataRef[nodeDatum?.id] = nodeDatum;
  };

  const calculateCurvePath = (nodeAID: any, nodeBID: any) => {
    let y2 = 0,
      x2 = 0,
      d = "";
    const element1 = document.getElementById(nodeDataRef[nodeAID]?.__rd3t?.id);
    const element2 = document.getElementById(nodeDataRef[nodeBID]?.__rd3t?.id);
    const elemRect1: any = element1?.getBoundingClientRect();
    const elemRect2: any = element2?.getBoundingClientRect();
    y2 = elemRect2?.top - elemRect1?.top ?? 0;
    x2 = elemRect2?.right - elemRect1?.right ?? 0;

    if (!isNaN(y2)) {
      if (y2 < 0) {
        if (x2 < 0) {
          d = `M${x2},${y2} C${0},${y2} ${-y2 / 2},${y2 / 2} ${0},${0}`;
        } else {
          d = `M${x2},${y2} C${x2},${y2} ${-y2},${y2 / 2} ${0},${0}`;
        }
      } else {
        if (x2 < 0) {
          d = `M${0},${0} C${0},${0} ${y2 / 2},${y2 / 2} ${x2},${y2}`;
        } else {
          d = `M${0},${0} C${0},${0} ${y2 / 2},${y2 / 2} ${x2 / 2},${y2}`;
        }
      }
      nodeMetadata[nodeAID] = {
        visited: true,
        d,
      };
    }
    document.body?.classList.remove("active-linking");
  };

  const handleNode = useCallback(
    (nodeDatum: any) => {
      const data = getNodeById(treeData, nodeDatum.parent);

      const payload = {
        parentNode: data,
        currentNode: nodeDatum,
      };
      setSelectedNode(payload);
      if (nodeDatum?.version) {
        setIsRootNode(true);
      } else setIsRootNode(false);
      // setEnteredMouse(nodeDatum);
    },
    [getNodeById, setIsRootNode, setSelectedNode, treeData]
  );

  const handleNodeClick = (
    event: any,
    nodeDatum: any,
    isRelated: boolean,
    isSummary: boolean
  ) => {
    event.stopPropagation();
    if (isSummary) {
      errorNotification(
        `Cannot modify or delete summary ${
          nodeDatum?.isSubPath ? "resource" : "attribute"
        }`
      );
      return;
    }
    if (isRelated) {
      errorNotification(
        "Cannot modify or delete linked attribute. Please delete relationship first to modify."
      );
    } else {
      setEnteredMouse(nodeDatum);
      const newData = JSON.parse(JSON.stringify(treeData));
      const modifiedJson = handleAddNode(newData, nodeDatum.id);
      if (modifiedJson) setTreeData(modifiedJson);
    }
  };

  const onMouseEntered = (nodeDatum: any) => {
    setEnteredMouse(nodeDatum);
    // handleNode(nodeDatum);
  };

  const handleRemoveNodeClick = (
    event: any,
    nodeDatum: any,
    isRelated: boolean,
    isSummary: boolean
  ) => {
    event.stopPropagation();
    if (isSummary) {
      errorNotification(
        `Cannot modify or delete summary ${
          nodeDatum?.isSubPath ? "resource" : "attribute"
        }`
      );
      return;
    }
    if (isRelated) {
      errorNotification(
        "Cannot modify or delete linked attribute. Please delete relationship first to modify."
      );
    } else {
      const newData = JSON.parse(JSON.stringify(treeData));
      handleRemoveNode(newData, nodeDatum.id);
      setTreeData(newData);
      // handleNode(getNodeById(treeData, nodeDatum.parent));
    }
  };

  const renderResources = useCallback((data: any) => {
    if (data.parent === 1) {
      return `/${data.name}`;
    }
    if (data.isSubPath) {
      return `/${data.name}`;
    }
    return data.name;
  }, []);

  function measureText(
    pText: string | null,
    pFontSize?: string,
    pStyle?: null
  ) {
    var lDiv = document.createElement("div");

    document.body.appendChild(lDiv);

    if (pStyle != null) {
      //@ts-ignore
      lDiv.style = pStyle;
    }
    lDiv.style.fontSize = "" + pFontSize + "px";
    lDiv.style.position = "absolute";
    //@ts-ignore
    lDiv.style.left = -1000;
    //@ts-ignore
    lDiv.style.top = -1000;

    lDiv.textContent = pText;

    var lResult = {
      width: lDiv.clientWidth,
      height: lDiv.clientHeight,
    };

    document.body.removeChild(lDiv);
    //@ts-ignore
    lDiv = null;

    return lResult;
  }

  const renderNodeWithCustomEvents = ({
    nodeDatum,
    toggleNode,
    handleNodeClick,
  }: any) => {
    collectNodeData(nodeDatum);
    const isSummary = nodeDatum?.isSummary;
    const isRootNode = treeData.id === nodeDatum.id && treeData?.version;
    const isRelated = linkedList.reduce(
      (result: any, str: string) => result || str.includes(nodeDatum?.id),
      false
    );
    const heightOfNode: number | undefined = document
      .querySelector(".node-wrapper")
      ?.getBoundingClientRect().height;
    const textWidth = measureText(nodeDatum.name);
    //Note: Making a fixed width
    const width = isRootNode || nodeDatum.isSubPath ? 150 : 250;
    // textWidth.width < 130
    //   ? 130
    //   : textWidth.width > 150
    //   ? 150
    //   : textWidth.width;
    const foreignObjectProps = {
      width,
      height: isRootNode ? 60 : 40,
      x: isRootNode ? -width : 0,
      y: isRootNode ? -(heightOfNode ?? 60) / 2 : -20,
    };

    let d = "";
    if (nodeDatum?.isRelation && !nodeMetadata.hasOwnProperty(nodeDatum?.id)) {
      document.body?.classList.add("active-linking");
      calculateCurvePath(nodeDatum?.id, nodeDatum?.relation?.id);
    }

    return (
      <>
        <g
          onClick={() => handleNode(nodeDatum)}
          onMouseEnter={() => onMouseEntered(nodeDatum)}
          onMouseLeave={() => setEnteredMouse(null)}
        >
          <foreignObject {...foreignObjectProps}>
            <div>
              <div
                className={`node-wrapper ${
                  nodeDatum.id === selectedNode.currentNode?.id
                    ? "node-wrapper-active"
                    : ""
                }
                ${nodeDatum?.isDisabled && "node-disabled"}
                `}
                style={{
                  maxHeight: isRootNode ? "60px" : "40px",
                  overflow: "hidden",
                  width: width,
                }}
              >
                <h3
                  style={{
                    textAlign: "center",
                    wordBreak: isRootNode ? "break-all" : "keep-all",
                    padding: "0 10px ",
                    width,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {renderResources(nodeDatum)}
                </h3>
              </div>
            </div>
          </foreignObject>

          {nodeDatum.id === enteredMouse?.id && isEditable && (
            <>
              {!isRootNode && (
                <g
                  transform={`translate(0, 0)`}
                  onClick={(event) =>
                    handleRemoveNodeClick(
                      event,
                      nodeDatum,
                      isRelated,
                      isSummary
                    )
                  }
                  className="icon-action"
                >
                  <circle
                    r="9"
                    fill="#fff"
                    strokeWidth="1"
                    stroke="#3C65D6"
                    className="icon-action__circle"
                  />
                  <text
                    x="-3"
                    y="4.6"
                    fontSize="12"
                    stroke="#3C65D6"
                    className="icon-action__text"
                  >
                    -
                  </text>
                </g>
              )}
              <g
                transform={`translate(${isRootNode ? 0 : width}, 0)`}
                onClick={(event) =>
                  handleNodeClick(event, nodeDatum, isRelated, isSummary)
                }
                className="icon-action"
              >
                <circle
                  r="9"
                  fill="#fff"
                  strokeWidth="1"
                  stroke="#3C65D6"
                  className="icon-action__circle"
                />
                <text
                  x="-4"
                  y="4.6"
                  fontSize="12"
                  stroke="#3C65D6"
                  className="icon-action__text"
                >
                  +
                </text>
              </g>
            </>
          )}
        </g>
        {nodeDatum?.isRelation && (
          <g
            transform={`translate(${isRootNode ? 0 : width}, 0)`}
            //  onClick={(event) => handleNodeClick(event, nodeDatum)}
            className="icon-action"
          >
            <path
              d={
                nodeMetadata.hasOwnProperty(nodeDatum?.id)
                  ? nodeMetadata[nodeDatum?.id]?.d
                  : d
              }
              fill="none"
              stroke="var(--adf-color-input-border-light)"
              strokeWidth="2px"
              strokeDasharray="5,5"
            />
          </g>
        )}
      </>
    );
  };

  const [translate, containerRef] = useCenteredTree();
  const nodeSize = { x: 350, y: 50 };
  return (
    <div style={{ width: "100%", height: "100%" }} ref={containerRef as any}>
      {treeData && (
        <Tree
          translate={translate as any}
          data={treeData}
          centeringTransitionDuration={500}
          nodeSize={nodeSize}
          onLinkClick={(event) =>  event}
          renderCustomNodeElement={(rd3tProps) =>
            renderNodeWithCustomEvents({ ...rd3tProps, handleNodeClick })
          }
          pathFunc="diagonal"
          collapsible={true}
          zoomable
        />
      )}
    </div>
  );
};
