export const INPUTS_META_DATA = [
  {
    type: "text",
    name: "name",
    placeholder: "Satschel",
    label: "Name",
  },
  {
    type: "text",
    name: "version",
    placeholder: "1.0.0",
    label: "Version",
  },
  {
    type: "textarea",
    name: "description",
    placeholder: "Add your description here",
    label: "Description",
  },
  {
    type: "text",
    name: "apiKey",
    placeholder: "API key",
    label: "API key",
  },
  {
    type: "text",
    name: "label",
    placeholder: "Label",
    label: "label",
  },
  {
    type: "text",
    name: "format",
    placeholder: "Open API v3",
    label: "Format",
  },
];
