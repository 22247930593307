import type { IReactResponsiveTable } from "./type";

import { FC, useEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";
import { Loader, Tooltip } from "@storybook";

import {
  formatSizeUnits,
  getDate,
  getDateWithTime,
  getTime,
  useFormatNumber,
} from "utils";

import { useOutsideClick } from "hooks";

import "./react-responsive-table.scss";
import { RootHeight, RootWidth } from "states/states";
import { useRecoilValue } from "recoil";

export const ReactResponsiveTable: FC<IReactResponsiveTable> = ({
  downloadZip,
  tableType,
  column,
  rows = [],
  isLoading = false,
  showDateFilter = false,
  showFilter = false,
  showArchiveFilter = false,
  showSearch = false,
  className = "",
  columnHandle = false,
  handelRowClick,
  filterPage = "",
  showSelectedButton = false,
  handleEditInvestorDetails,
}) => {
  const filterRef = useRef();

  const rootHeight = useRecoilValue(RootHeight);
  const dynamicHeight = rootHeight ? Number(rootHeight) + 48 : 48;

  const [tableRowsData, setTableRows] = useState<any[]>(rows);

  useOutsideClick(filterRef, () => {});

  useEffect(() => {
    setTableRows(rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const tableData = useMemo(() => tableRowsData ?? [], [tableRowsData]);

  const { numberDecimal } = useFormatNumber();

  const tableRows = useMemo(() => tableData ?? [], [tableData]);

  const filteredRows = useMemo(() => {
    //here we can manupulate tableRows as per requirements
    return tableRows;
  }, [tableRows]);

  const renderColumn = useMemo(
    () =>
      column.map(({ label, key, width }, index) => (
        <th
          className="table__head-data"
          key={`renderColumns__${label}_${index}-${key}`}
          style={{ width }}
        >
          <div className="table__head-data--label">
            <span>{label} </span>
          </div>
        </th>
      )),
    [column]
  );

  const renderRows = useMemo(() => {
    const renderingRows = filteredRows;
    return renderingRows.map((item, index) => (
      <tr
        key={`renderRows_${item.createdAt}__${index}`}
        onClick={() => (handelRowClick ? handelRowClick(item) : {})}
        className={`${columnHandle ? "row__hover" : ""}`}
      >
        {column.map(({ key, format, width, className }, idx) => {
          let value = item[key];
          if (value) {
            switch (format) {
              case "number":
                break;
              case "date":
                value = `${getDate(value)}  ${getTime(value)}`;
                break;
              case "dateTime":
                value = getDateWithTime(value);
                break;
              case "jsx":
                value = value();
                break;
              case "price":
                value = `$${numberDecimal(Number(value))}`;
                break;
              case "percentage":
                value = `${value}%`;
                break;
              case "size":
                value = formatSizeUnits(value);
                break;
              default:
                break;
            }
          }
          value = value == null || value === "" ? "--" : value;
          const elepsisClass =
            format === "string"
              ? tableType === "pipeLine"
                ? "text-elipsis-pipeline"
                : "text-elipsis"
              : "";
          return (
            <td
              key={`renderColumn_${index}__${idx}__${key}`}
              className={`${className ? className : ""}`}
              style={{ width }}
            >
              {new Set(["name", "description"]).has(key) ? (
                <Tooltip key={index} text={value} direction="right">
                  <div className={elepsisClass}>{value}</div>
                </Tooltip>
              ) : (
                <div className={elepsisClass}>{value}</div>
              )}
            </td>
          );
        })}
      </tr>
    ));
  }, [
    column,
    columnHandle,
    filteredRows,
    handelRowClick,
    numberDecimal,
    tableType,
  ]);

  const reactTableResponsiveWrapper = useMemo(() => {
    return classNames("react-table-responsive-wrapper", {
      "react-table-responsive-fund-details-wrapper":
        tableType === "fund-details",
      "react-table-responsive-sessions-wrapper": tableType === "sessions",
      "react-table-responsive-agreement-document-modal-sessions-wrapper":
        tableType === "agreementdocumentmodal",
    });
  }, [tableType]);

  return (
    <div className="react-table-responsive-container">
      <div
        className={reactTableResponsiveWrapper}
        style={{
          height: `calc(100vh - ${dynamicHeight}px)`,
        }}
      >
        <table className={`react-table-responsive fl-table ${className}`}>
          <thead className="react-table-responsive__head">
            <tr
              className="react-table-responsive__head-row"
              style={{ backgroundColor: "#d9e1f2" }}
            >
              {renderColumn}
            </tr>
          </thead>
          {!!tableData.length && !!renderRows.length && (
            <tbody className="react-table-responsive__body ">
              {renderRows}
            </tbody>
          )}
        </table>

        {!tableData.length && isLoading && (
          <div className="loader-container">
            <Loader className="loader-blue" dimension={52} />
          </div>
        )}
      </div>
    </div>
  );
};
