import { atom } from "recoil";

const getType = (top: string | number | JSX.Element, bottom?: string) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div className="option-top">{top}</div>
      {bottom && <div className="option-bottom">{bottom}</div>}
    </div>
  );
};

export const ResourceConfigState = atom<{ [key: string]: any }>({
  key: "resource-config-state",
  default: {
    Define: [
      {
        type: "title",
        label: "Type",
      },
      {
        type: "binary-choice",
        key: "select-attribute-type",
        className: "choose-type",
        options: [
          {
            key: "integer",
            label: getType(123, "Integer"),
          },
          {
            key: "number",
            label: getType("1.0", "Number"),
          },
          {
            key: "string",
            label: getType(<i className="ri-text"></i>, "String"),
          },
          {
            key: "boolean",
            label: getType(<i className="ri-toggle-fill"></i>, "Boolean"),
          },
          {
            key: "object",
            label: getType(
              <i style={{ fontSize: "20px" }} className="ri-braces-line"></i>
            ),
          },
          {
            key: "date",
            label: getType(
              <i style={{ fontSize: "20px" }} className="ri-time-line"></i>,
              "Date"
            ),
          },
        ],
      },
      {
        type: "toggle",
        label: "List",
        key: "isArray",
        name: "isArray",
        isBorder: true,
      },
      {
        type: "title",
        label: "Description",
      },
      {
        type: "text",
        placeholder: "resource",
        label: "Name",
        name: "name",
      },
      {
        type: "textarea",
        placeholder: "Additional Information",
        label: "Description",
        name: "description",
        note: "Enter information for internal use that will not be included in your specification",
        isBorder: true,
      },
      {
        type: "title",
        label: "Legend",
      },
      {
        type: "select",
        placeholder: "Default",
        label: "Description",
        isBorder: true,
      },
    ],
    Expose: [
      // {
      //   type: "title",
      //   label: "Resource",
      //   key: "resource-title",
      // },
      // {
      //   type: "binary-choice",
      //   className: "select-resource-wrapper",
      //   key: "isSubPath",
      //   label:
      //     "Transform the attribute into a resource so that it can be exposed by the API and manipulated through operations.",
      //   options: [
      //     {
      //       key: "yes",
      //       label: "Yes",
      //     },
      //     {
      //       key: "no",
      //       label: "No",
      //     },
      //   ],
      // },
      {
        type: "title",
        label: "Search",
      },
      // {
      //   type: "toggle",
      //   key: "queryable",
      //   label: "Add to filters",
      // },
      // {
      //   type: "toggle",
      //   key: "abstract",
      //   label: "Add to result",
      // },
      { type: "title", label: "Operations" },
      {
        type: "binary-choice",
        className: "select-operations",
        name: "crud",
        key: "select-crud-operation",
        label: "Define how the resource is manipulated.",
        options: [
          {
            key: "CREATE",
            label: "Create",
          },
          {
            key: "READ",
            label: "Read",
          },
          {
            key: "UPDATE",
            label: "Update",
          },
          {
            key: "DELETE",
            label: "Delete",
          },
        ],
      },
      // {
      //   type: "toggle",
      //   key: "patchEnabled",
      //   label: "Enable partial update",
      // },
      // {
      //   type: "toggle",
      //   key: "searchEnabled",
      //   label: "Enable search",
      // },
      // {
      //   type: "toggle",
      //   key: "pageable",
      //   label: "Paginated",
      // },
      // {
      //   type: "title",
      //   label: "Visibility",
      //   key: "visibility-title",
      // },
      // {
      //   type: "binary-choice",
      //   key: "select-response-type",
      //   name: "visibility",
      //   label:
      //     "Define whether the attribute is returned by the server when manipulating the resource.",
      //   className: "choose-visibility",
      //   note: "The consumer can enter a value when creating/updating the resource.",
      //   options: [
      //     {
      //       key: "READ_WRITE",
      //       label: getType(
      //         <i className="ri-arrow-left-right-line" />,
      //         "Request & Response"
      //       ),
      //     },
      //     {
      //       key: "WRITE_ONLY",
      //       label: getType(
      //         <i className="ri-arrow-left-right-line" />,
      //         "Request only"
      //       ),
      //     },
      //     {
      //       key: "READ_ONLY",
      //       label: getType(
      //         <i className="ri-arrow-left-right-line" />,
      //         "Response only"
      //       ),
      //     },
      //   ],
      // },
    ],
    Refine: [
      {
        type: "title",
        label: "Validation fields",
      },

      {
        title: "Validation fields",
        type: "select",
        placeholder: "Possible values",
        key: "possible-values",
        label: "Specify the expected format of the attribute.",
        name: "enumValues",
        isCreatable: true,
        note: "Press Enter to separate values.",
      },
      {
        type: "toggle",
        label: "Required",
        key: "required",
        isBorder: true,
      },
      {
        type: "title",
        label: "Example",
      },
      {
        type: "textarea",
        placeholder: "Additional Information",
        label:
          "Add an example according to the validation fields, it will also be used by the mock policy",
        name: "example",
        isBorder: false,
        note: "This example is automatically generated from its child attributes.",
      },

      // {
      //   type: "title",
      //   label: "Description",
      // },
      // {
      //   type: "text",
      //   placeholder: "Description",
      //   label: "Add a description to make your specification clearer.",
      //   name: "description",
      // },
    ],
    Import: [
      {
        type: "binary-choice",
        className: "select-resource-wrapper",
        key: "select-csv-import-method",
        label: "",
        options: [
          {
            key: "csv-import",
            label: "CSV Import",
          },
          {
            key: "manual-import",
            label: "Manual Import",
          },
        ],
      },
      {
        type: "title",
        label: "CSV Import",
        key: "csv-import-title",
      },
      {
        type: "description",
        key: "csv-description",
        label: "You can drag and drop or browse your files here.",
      },
      {
        type: "file-upload",
        label: "CSV Upload",
        key: "csv-file-import",
        name: "",
        isBorder: true,
      },
      {
        type: "csv-manual-import",
        label: "Manual Import",
        key: "csv-manual-import",
        name: "",
        isBorder: true,
      },
      // {
      //   type: "description",
      //   key: "csv-note",
      //   label:
      //     "* Uploading data from CSV will replace the existing data.",
      // },
    ],
    Relationship: [
      {
        type: "title",
        label: "Resource/Attribute Relationship",
        key: "resource-attribute-relationship",
      },
      {
        type: "description",
        key: "relationship-description",
        label:
          "You can choose any resources and link them to attributes of any other resource to create an API flow.",
      },
      {
        type: "select",
        placeholder: "Select a resource",
        key: "linkedResourceFrom",
        name: "linkedResourceFrom",
        isCreatable: false,
      },
      {
        type: "select",
        placeholder: "Select a attribute",
        key: "linkedAttributeFrom",
        name: "linkedAttributeFrom",
      },
      {
        type: "icon",
        key: "link-icon",
        name: "linkIcon",
      },
      {
        type: "select",
        placeholder: "Select a resource",
        key: "linkedResourceTo",
        name: "linkedResourceTo",
      },
      {
        type: "select",
        placeholder: "Select a attribute",
        key: "linkedAttributeTo",
        name: "linkedAttributeTo",
      },
      {
        type: "description",
        key: "relationship-note",
        label: "",
      },
      {
        label: "Create Relationship",
        type: "button",
        key: "isRelation",
        isBorder: true,
      },
      {
        type: "title",
        label: "Resource Linked List",
        key: "resource-linked-list",
      },
      {
        label: "Relationships",
        type: "linkedList",
        key: "relationships",
      },
    ],
  },
});
