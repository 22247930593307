import { FC } from "react";
import ReactSwitch from "react-switch";

export const Switch: FC<any> = ({
  id,
  checked,
  handleChange,
  width,
  height,
  onColor,
  offColor,
  diameter,
  isDisabled = false,
}) => {
  return (
    <ReactSwitch
      id={id}
      checked={checked}
      disabled={isDisabled}
      checkedIcon={false}
      onColor={onColor ?? "#4574f5"}
      offColor={offColor ?? "#C9CDD6"}
      uncheckedIcon={false}
      height={height ?? 24}
      width={width ?? 48}
      handleDiameter={diameter ?? 16}
      onChange={handleChange}
    />
  );
};
