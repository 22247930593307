export const SIDEBAR_TABS = [
  {
    label: "Dashboard",
    icon: "ri-dashboard-line",
    link: "",
    isDisabled: true,
  },
  {
    label: "API Designer",
    icon: "ri-door-open-line",
    link: "",
    isDisabled: false,
  },
  {
    label: "Documentations",
    icon: "ri-settings-2-line",
    link: "documentations",
    isDisabled: false,
  },
  {
    label: "Settings",
    icon: "ri-settings-2-line",
    link: "",
    isDisabled: true,
  },
];
