import { useEffect, useRef } from "react";
import "./cap-table.scss";

export const CaptableGrid = (props?: any) => {
  const luckysheet = useRef(null);

  useEffect(() => {
    luckysheet.current = (window as any)?.luckysheet;
    (luckysheet as any).current.create({
      container: "luckysheet",
      showtoolbar: false,
      showsheetbar: false,
      showinfobar: false,
      showstatisticBar: false,
      sheetFormulaBar: false,
      defaultColWidth: 144,
      defaultRowHeight: 32,
      // row: 8,
      column: 3,
      data: [
        {
          celldata: props.celldata ?? [],
        },
      ],
      hook: {
        cellUpdated: () => {
          return true;
        },
      },
    });
    setTimeout(() => {
      (luckysheet?.current as any)?.setHorizontalFrozen(false);
    }, 500);
    return () => {
      (luckysheet as any).current.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.currentNode]);

  return (
    <div
      id="luckysheet"
      style={{
        margin: "0px",
        padding: "0px",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0px",
        top: "0px",
      }}
    />
  );
};
