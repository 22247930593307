import { RecoilRoot } from "recoil";
import { FC } from "react";
import { BrowserRouter } from "react-router-dom";

import { AllRoutes } from "routes";
import { ThemeProvider } from "helpers";
import PropTypes from "prop-types";
import { useEnvUrl } from "hooks";

// import { Bounce, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import "./App.css";

interface IApp {
  token?: string;
  theme?: string;
  sidebar?: string;
  topbar?: string;
  logo?: string;
  show_dark_toggle?: string;
  logout?: string;
  admin?: string;
  user_name?: string;
  url_prefix?: string;
  height?: string;
  width?: string;
}

const App: FC<IApp> = ({
  token,
  theme,
  sidebar,
  topbar,
  logo,
  show_dark_toggle,
  logout,
  admin,
  user_name,
  url_prefix,
  height,
  width,
}) => {
  const { getToken } = useEnvUrl();
  const allProps = {
    isSidebar: sidebar === "true",
    isTopbar: topbar === "true",
    isDarkMode: theme === "dark",
    token: admin === "true" ? getToken() : token,
    logo,
    isdarkMode: show_dark_toggle === "true",
    logout: logout === "true",
    isAdmin: admin,
    user_name: user_name,
    url_prefix: url_prefix ? url_prefix : "",
    height,
    width,
  };

  return (
    <RecoilRoot>
      <ThemeProvider>
        <BrowserRouter>
          <AllRoutes {...allProps} />
          {/* <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            closeOnClick
            pauseOnHover
            transition={Bounce}
            className="adf-toaster"
          /> */}
        </BrowserRouter>
      </ThemeProvider>
    </RecoilRoot>
  );
};

App.propTypes = {
  token: PropTypes.string,
  theme: PropTypes.string,
  sidebar: PropTypes.string,
  topbar: PropTypes.string,
  logo: PropTypes.string,
  show_dark_toggle: PropTypes.string,
  logout: PropTypes.string,
  admin: PropTypes.string,
  user_name: PropTypes.string,
  url_prefix: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default App;
