import { useNetwork, useNodeTree } from "hooks";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  TemplatesData,
  treeDataState,
  currentNode,
  UrlPrefix,
  SavedTreeMetadata,
  IsTopBar,
} from "states/states";

import "./template-screen.scss";
import { Topbar } from "components/topbar";

type FetchState = "fetching" | "fetched";

export const TemplateScreen = () => {
  const navigate = useNavigate();
  const { get } = useNetwork();
  const { getNodeById } = useNodeTree();
  const [treeData, setTreeData] = useRecoilState(treeDataState);

  const [templatesData, setTemplatesData] = useRecoilState(TemplatesData);
  const setSelectedNode = useSetRecoilState(currentNode);
  const urlPrefix = useRecoilValue(UrlPrefix);
  const isTopbar = useRecoilValue(IsTopBar);
  const savedTreeMetadata = useRecoilValue(SavedTreeMetadata);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fetchState, setFetchState] = useState<FetchState>("fetched");

  const pathName = useMemo(() => window.location.pathname.replace("/", ""), []);

  useEffect(() => {
    if (templatesData.length === 0) {
      setFetchState("fetching");
      get("/api/api-template")
        .then((res: any) => {
          setFetchState("fetched");
          const originalResData = JSON.parse(JSON.stringify(res?.data));
          // eslint-disable-next-line array-callback-return
          originalResData?.map((el: any) => {
            el.id = Math.floor(Date.now() * Math.random());
          });
          setTemplatesData(originalResData as any);
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = useCallback(
    (item: any, originalName: string) => {
      const newItem = JSON.parse(JSON.stringify(item));
      const regex = /[^!#$&'()*+,/:;=?%@[\]`{}|<> ]$/;
      let newAPIURL = "";
      for (const char of newItem?.name) {
        const hasInValidChar = !regex.test(char);
        if (!hasInValidChar || char === " ")
          newAPIURL += char === " " ? "_" : char;
      }
      if (
        !savedTreeMetadata.find((item: any) => item?.id === treeData?.id)
          ?.apiAccessKey
      ) {
        newItem["api_url"] = newAPIURL.slice(0, 20);
      }
      setTreeData(newItem);
      sessionStorage.setItem("selectedTemplate", originalName);
      const data = getNodeById(treeData, newItem?.parent);

      const payload = {
        parentNode: data,
        currentNode: newItem,
      };
      setSelectedNode(payload);
      navigate(
        urlPrefix
          ? `../${urlPrefix}/api-editor?id=${newItem?.id}&navigate=${pathName}`
          : `../api-editor?id=${newItem?.id}&navigate=${pathName}`
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, pathName, setSelectedNode, setTreeData, treeData]
  );

  const renderLists = useMemo(() => {
    if (Array.isArray(templatesData) && templatesData?.length)
      return templatesData?.map((el: any, index: number) => {
        const original = JSON.parse(JSON.stringify(el));
        original.name = "";
        return (
          <div
            key={index + el.name}
            style={{
              width: "200px",
              height: "150px",
              padding: "16px",
              background: "var(--adf-color-white-border)",
              borderRadius: "8px",
              position: "relative",
              overflow: "hidden",
            }}
            className="item-wrapper"
            onClick={() => handleClick(original, el?.name)}
          >
            <div
              style={{
                fontSize: "18px",
                fontWeight: "500",
                color: "#000",
                overflow: "hidden",
              }}
            >
              {el.name}
            </div>
          </div>
        );
      });
  }, [templatesData, handleClick]);

  const renderMainComponent = useMemo(() => {
    return (
      <>
        <Topbar lable="Template Designs" />
        <div
          style={{
            display: "flex",
            gap: 24,
            flexWrap: "wrap",
            padding: 48,
            overflow: "auto",
          }}
        >
          {renderLists}
        </div>
      </>
    );
  }, [renderLists]);
  return renderMainComponent;
};
