import { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState, FC } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Button, Input, ReactDropdown, Switch, TextArea } from "@storybook";

import { currentNode, treeDataState, RelationLinkedList, RootHeight } from "states/states";
import { useNodeTree, useNotification } from "hooks";
import { useAPIEditor } from "views/api-editor/stores/hooks";
import { MESSAGES } from "views/api-editor/constants";
import {
  BLOCK_ACTION_CSV_FILE_IMPORT,
  BLOCK_ACTION_CSV_MANUAL_IMPORT,
  BLOCK_ACTION_RESOURCE_NO,
  BLOCK_ACTION_RESOURCE_YES,
  DEFAULT_CSV_HEADER,
} from "./constant";
import { ResourceConfigState } from "views/api-editor/stores";
import { CaptableGrid, FileUpload, ConfirmationModal } from "components";
import { validateFile } from "./validations";
import { hasKeys } from "utils";

import "./resource-config.scss";
import {
  areBothElementsPresent,
  findMatchingNestedArray,
  isAnyElementPresent,
} from "utils/arrayOprations";

interface IResourceConfiguration {
  nameError: any
  setNameError: any;
  nameErrorMessage: any,
  setNameErrorMessage: any;
}

export const ResourceConfiguration: FC<IResourceConfiguration> = ({nameError, setNameError, nameErrorMessage, setNameErrorMessage}) => {
  type UploadState = "initial" | "uploading" | "uploaded";
  const [selectedItem, setSelectedItem] = useState("Define");
  const [selectedCSVImportMethod, setSelectedCSVImportMethod] = useState("csv-import");
  const resourceConfigData = useRecoilValue(ResourceConfigState);
  const rootHeight = useRecoilValue(RootHeight);
  const [fileName, setFileName] = useState<string>("");

  const [uploadState, setUploadState] = useState<UploadState>("initial");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [manualCsvUpload, setManualCsvUpload] = useState<boolean>(false);
  const [fileData, setFileData] = useState<any>(null);
  const [fileMetaData, setFileMetaData] = useState<any>(null);
  const [relationshipsStates, setRelationshipsStates] = useState<any>({});
  const [alreadyLinked, setAlreadyLinked] = useState<(number | string | boolean)[][]>([]);
  const [linkedList, setLinkedList] = useRecoilState<(number | string)[][]>(RelationLinkedList);
  const [activeTabs, setActiveTabs] = useState<any>(Object.keys(resourceConfigData).slice(0, 4));
  const [modalMessage, setModalMessage] = useState("");

  const Notification = useNotification();
  const { addMultipleTreeNode, getNodeById, getResourceNode, extractRelationData } = useNodeTree();

  const [treeData, setTreeData] = useRecoilState(treeDataState);
  const [selectedNode, setSelectedNode] = useRecoilState(currentNode);
  const [selectedFormMetaData, setSelectedFormMetaData] = useState(resourceConfigData.Define);

  const { changeChildValue } = useAPIEditor();
  const { errorNotification } = useNotification();

  useEffect(() => {
    const relationData = extractRelationData(treeData);
    const oldRelationshipsStates = JSON.parse(JSON.stringify(relationshipsStates));
    for (const relation of relationData) {
      const relationState: any = {};
      relationState["linkedAttributeFrom"] = getNodeById(treeData, relation.id);
      relationState["linkedResourceFrom"] = treeData?.children?.filter(
        (child: any) => child.name === relation.linkedResourceFrom,
      )?.[0];
      relationState["linkedResourceTo"] = treeData?.children?.filter(
        (child: any) => child.name === relation.linkedResourceTo,
      )?.[0];
      relationState["linkedAttributeTo"] = relationState?.linkedResourceTo?.children?.filter(
        (child: any) => child.name === relation.linkedAttributeTo,
      )?.[0];
      oldRelationshipsStates[relation?.id] = relationState;
      setAlreadyLinked([
        ...alreadyLinked,
        [relationState?.linkedResourceFrom?.id, relationState?.linkedResourceTo?.id],
      ]);
      setRelationshipsStates({ ...oldRelationshipsStates });
      if (relationState?.linkedAttributeFrom?.relation?.populate)
        setLinkedList((oldArray: any) => [
          ...oldArray,
          [relationState?.linkedAttributeFrom?.id, relationState?.linkedAttributeTo?.id],
        ]);
    }
    return () => {
      setAlreadyLinked([]);
      setLinkedList([]);
      setRelationshipsStates({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractRelationData, getNodeById, setLinkedList, treeData]);

  useEffect(() => {
    if (!selectedNode?.currentNode?.isRelation) {
      const currentNodeID = selectedNode?.currentNode?.id;
      const newData = JSON.parse(JSON.stringify(relationshipsStates));
      newData[currentNodeID] = {};
      setRelationshipsStates(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode, treeData]);

  useEffect(() => {
    if (
      (/Expose|Import/.test(selectedItem) && !isSubPath) ||
      (selectedItem === "Relationship" && isSubPath)
    ) {
      setSelectedItem("Define");
    }

    if (selectedItem === "Define") {
      if (type === "object") {
        const options = resourceConfigData.Define[1].options?.filter(
          (item: any) => item.key === "object",
        );
        if (options) {
          setSelectedFormMetaData((prev: any) => {
            let data = JSON.parse(JSON.stringify(resourceConfigData.Define));
            data[1].options = options;
            if (isSubPath) {
              data = data.filter((el: any) => el.key !== "isArray");
            }
            return [...data];
          });
        }
      } else {
        const options = resourceConfigData.Define[1]?.options?.filter(
          (item: any) => item.key !== "object",
        );
        if (options) {
          setSelectedFormMetaData((prev: any) => {
            let data = JSON.parse(JSON.stringify(resourceConfigData.Define));
            data[1].options = options;
            if (isSubPath) {
              data = data.filter((el: any) => el.key !== "isArray");
            }
            return [...data];
          });
        }
      }
    }

    if (selectedItem === "Refine") {
      setSelectedFormMetaData((prev: any) => {
        let data = JSON.parse(JSON.stringify(resourceConfigData.Refine));
        if (isSubPath) {
          data = data.filter((el: any) => el.key !== "required");
        }
        return [...data];
      });
    }
    if (selectedItem === "Relationship") {
      const currentNodeID = selectedNode?.currentNode?.id;
      const resourceNode = getResourceNode(treeData, currentNodeID);
      const oldRelationshipsStates = JSON.parse(JSON.stringify(relationshipsStates));
      if (oldRelationshipsStates[currentNodeID]) {
        oldRelationshipsStates[currentNodeID]["linkedResourceFrom"] = resourceNode;
      } else {
        const stateObj = {
          linkedResourceFrom: resourceNode,
        };
        oldRelationshipsStates[currentNodeID] = stateObj;
      }
      setRelationshipsStates(oldRelationshipsStates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode, selectedItem]);

  useEffect(() => {
    if (selectedItem === "Expose") {
      getExposeResourceData();
    } else if (selectedItem === "Import") {
      getImportResourceData();
    }

    setUploadState("initial");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode?.currentNode, selectedCSVImportMethod]);

  const { type, visibility, crud, isSubPath, id, parent } = useMemo(
    () => selectedNode?.currentNode ?? {},
    [selectedNode?.currentNode],
  );

  const getExposeResourceData = useCallback(() => {
    if (selectedNode?.currentNode.isSubPath) {
      const data: any = resourceConfigData.Expose?.filter((item: any) =>
        BLOCK_ACTION_RESOURCE_YES.includes(item.key as string),
      );
      setSelectedFormMetaData(data);
    } else {
      const data: any = resourceConfigData.Expose?.filter((item: any) =>
        BLOCK_ACTION_RESOURCE_NO.includes(item.key as string),
      );
      setSelectedFormMetaData(data);
    }
  }, [resourceConfigData.Expose, selectedNode?.currentNode.isSubPath]);

  const getImportResourceData = useCallback(() => {
    if (selectedCSVImportMethod === "csv-import") {
      const data: any = resourceConfigData.Import?.filter((item: any) =>
        BLOCK_ACTION_CSV_FILE_IMPORT.includes(item.key as string),
      );
      setSelectedFormMetaData(data);
    } else {
      const data: any = resourceConfigData.Import?.filter((item: any) =>
        BLOCK_ACTION_CSV_MANUAL_IMPORT.includes(item.key as string),
      );
      setSelectedFormMetaData(data);
    }
  }, [resourceConfigData.Import, selectedCSVImportMethod]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const classConditionRelationshipNote = (item: any) => {
    const condition =
      item.key === "relationship-note" &&
      hasKeys(relationshipsStates?.[selectedNode?.currentNode?.id], [
        "linkedResourceTo",
        "linkedAttributeTo",
        "linkedResourceFrom",
        "linkedAttributeFrom",
      ]);
    return condition;
  };

  const validateRelationshipForm = useCallback(() => {
    const relationData = relationshipsStates?.[selectedNode?.currentNode.id];
    let status = false,
      showModal = false,
      message = "";

    //Check for necessary relation data
    const hasData = Object.keys(relationData).filter((item: any) =>
      relationData[item]?.id ? true : false,
    );

    if (hasData?.length !== 4) {
      message =
        hasData.length !== 4
          ? "Please select one option from each dropdown"
          : "Can't create a relationship";
      return { status, message, showModal };
    }

    //Check for types match of attributes
    if (relationData?.linkedAttributeFrom?.type !== relationData?.linkedAttributeTo?.type) {
      message = "Types of attributes should be same";
      return { status, message, showModal };
    }

    //Check for object type fo any of the related attributes
    if (
      relationData?.linkedAttributeFrom.type === "object" ||
      relationData?.linkedAttributeTo?.type === "object"
    ) {
      message = "Relationship is not allowed for type collection";
      return { status, message, showModal };
    }

    //Check for list toggle for both the related attributes
    if (relationData?.linkedAttributeFrom?.isArray || relationData?.linkedAttributeTo?.isArray) {
      message = "Please off the LIST toggle of both the attributes before making a relationship";
      return { status, message, showModal };
    }

    //Check for already linked resources
    if (
      areBothElementsPresent(
        alreadyLinked,
        relationData?.linkedResourceTo?.id,
        relationData?.linkedResourceFrom?.id,
      )
    ) {
      message = "Resources are already linked";
      return { status, message, showModal };
    }

    //Check linked attribute for override
    if (
      isAnyElementPresent(
        linkedList,
        relationData?.linkedAttributeTo?.id,
        relationData?.linkedAttributeFrom?.id,
      )
    ) {
      const linkedAttributes = findMatchingNestedArray(
        linkedList,
        relationData?.linkedAttributeFrom?.id,
        relationData?.linkedAttributeTo?.id,
      );
      let filteredLinkedAttribute = linkedAttributes.map((attributeID) =>
        getNodeById(treeData, attributeID),
      );
      const linkedResource1 = getResourceNode(treeData, filteredLinkedAttribute[0]?.id);
      const linkedResource2 = getResourceNode(treeData, filteredLinkedAttribute[1]?.id);

      message = `${linkedResource1?.name}.${filteredLinkedAttribute[0]?.name} & ${linkedResource2?.name}.${filteredLinkedAttribute[1]?.name} are already linked.`;
      showModal = true;
      return { status, message, showModal };
    }

    const relationDataFrom = [
      { key: "isRelation", value: true },
      {
        key: "relation",
        value: {
          key: relationData?.linkedAttributeTo?.name,
          ref: relationData?.linkedResourceTo?.name,
          populate: true,
          parentNode: relationData?.linkedResourceFrom?.name,
          id: relationData?.linkedAttributeTo?.id,
        },
      },
    ];
    let newData: any = JSON.parse(JSON.stringify(treeData));
    for (const item of relationDataFrom) {
      newData = changeChildValue(
        newData,
        relationData?.linkedAttributeFrom?.id,
        item.value,
        item.key,
      );
    }
    if (newData) {
      setTreeData(newData);
      if (
        !areBothElementsPresent(
          alreadyLinked,
          relationData?.linkedResourceTo?.id,
          relationData?.linkedResourceFrom?.id,
        )
      )
        setAlreadyLinked([
          ...alreadyLinked,
          [relationData?.linkedResourceTo?.id, relationData?.linkedResourceFrom?.id],
        ]);
      setLinkedList([
        ...linkedList,
        [relationData?.linkedAttributeFrom?.id, relationData?.linkedAttributeTo?.id],
      ]);
      status = true;
      message = "Relationship has been created";
    }
    return { status, message, showModal };
  }, [
    alreadyLinked,
    changeChildValue,
    getNodeById,
    getResourceNode,
    linkedList,
    relationshipsStates,
    selectedNode?.currentNode.id,
    setLinkedList,
    setTreeData,
    treeData,
  ]);

  const handleOnChangeTab = useCallback(
    (item: string) => {
      if (/Expose|Import/.test(item) && !isSubPath) {
        return;
      }
      if (item === "Relationship" && isSubPath) {
        return;
      }
      if (item === selectedItem) {
        return;
      }
      setSelectedItem(item);
      //@ts-ignore
      setSelectedFormMetaData(resourceConfigData[item]);
      if (item === "Expose") {
        getExposeResourceData();
      } else if (item === "Import") {
        getImportResourceData();
      } else if (item === "Refine") {
      }
    },
    [isSubPath, selectedItem, resourceConfigData, getExposeResourceData, getImportResourceData],
  );

  const getRelations = useCallback(() => {
    const relationShips = extractRelationData(treeData).filter((data: any) => data.populate);
    return relationShips;
  }, [extractRelationData, treeData]);

  const getClassName = useCallback(
    (item: string) => {
      let className = "resource-config__inner__scroller__tab-header__item ";
      if (item === selectedItem) {
        className += "active";
      }
      if ((/Expose|Import/.test(item) && !isSubPath) || (item === "Relationship" && isSubPath)) {
        className = className + "disabled";
      }
      return className;
    },
    [isSubPath, selectedItem],
  );

  const getAllName = useMemo(() => {
    return selectedNode?.parentNode?.children?.map((item: any) => item.name);
  }, [selectedNode?.parentNode?.children]);

  const handleAddChild = useCallback(
    (nodeDatum: any, id: any, originalVvalue: any, key: string) => {
      let value = originalVvalue;
      const isRelated = linkedList.reduce((result, arr) => result || arr.includes(id), false);

      if (key !== "description" && isRelated) {
        errorNotification(
          "Cannot modify or delete linked resource/attribute. Please delete relationship first to modify.",
        );
      } else {
        const newData = JSON.parse(JSON.stringify(treeData));

        if (key === "name") {
          value = originalVvalue;
          const currentSavedNode = selectedNode?.parentNode?.children?.find(
            (item: any) => item.id === selectedNode?.currentNode?.id,
          );
          let hasInValidChar: boolean = false;
          const regex = /[^!#$&'()*+,/:;=?%@[\]`{}|<> ]$/;
          if (typeof value === "string") {
            for (const char of value) {
              if (!hasInValidChar) {
                hasInValidChar = !regex.test(char);
              }
            }
          }
          if (!selectedNode?.currentNode?.isSubPath) hasInValidChar = false;
          if (
            !value ||
            hasInValidChar ||
            (getAllName?.includes(value) && value !== currentSavedNode?.name)
          ) {
            let message = "";
            if (!value) {
              message = `${
                selectedNode?.currentNode?.isSubPath ? "Resource" : "Attribute"
              } cannot be empty`;
            } else if (hasInValidChar && selectedNode?.currentNode?.isSubPath) {
              message = MESSAGES.notValidForURL;
            } else if (!hasInValidChar) {
              message = selectedNode?.currentNode?.isSubPath
                ? MESSAGES.resourceAlreadyExist
                : MESSAGES.attributeAlreadyExist;
            }
            setNameError({
              ...nameError,
              [selectedNode?.currentNode?.id]: true,
            });
            setNameErrorMessage({
              ...nameErrorMessage,
              [selectedNode?.currentNode?.id]: {
                message: message,
                value: value,
              },
            });
            return;
          }
        }
        setNameError({ ...nameError, [selectedNode?.currentNode?.id]: false });
        setNameErrorMessage({
          ...nameErrorMessage,
          [selectedNode?.currentNode?.id]: {
            message: MESSAGES?.attributeAlreadyExist,
            value: value,
          },
        });
        const modifiedJson = changeChildValue(newData, id, value, key);
        if (modifiedJson) {
          setTreeData(modifiedJson);
        }
      }
    },
    [
      changeChildValue,
      errorNotification,
      getAllName,
      linkedList,
      nameError,
      nameErrorMessage,
      selectedNode,
      setTreeData,
      treeData,
    ],
  );

  const handleOnChange = useCallback(
    (data: any, key: string, type?: string) => {
      if (type === "toggle") {
        const value = !data;
        if (key === "searchEnabled" && !value && selectedNode.currentNode.pageable) {
          handleAddChild(treeData, id, false, "pageable");
        }
        handleAddChild(treeData, id, value, key);
        return;
      }
      if (key === "enumValues") {
        if (/integer|number/.test(selectedNode.currentNode.type)) {
          const inputValue: string = data[data.length - 1]?.value ?? "0";
          let regex = /^-?[0-9]\d*(\.\d+)?$/;
          let message = "Value should be a number";
          if (selectedNode?.currentNode?.type === "integer") {
            regex = /^[0-9]*$/;
            message = "Value should be an integer";
          }
          if (
            regex.test(Number(inputValue) as unknown as string) ||
            inputValue === "" ||
            inputValue === "-"
          ) {
            const value = data.map((item: any) => Number(item?.value ?? "0"));
            /\s/.test(inputValue.toString())
              ? errorNotification("Spaces are not allowed")
              : handleAddChild(treeData, id, value, key);
          } else {
            errorNotification(message);
          }
        } else {
          const value = data.map((item: any) => item.value);
          handleAddChild(treeData, id, value, key);
        }
        return;
      }
      if (
        [
          "linkedResourceFrom",
          "linkedAttributeFrom",
          "linkedResourceTo",
          "linkedAttributeTo",
        ].includes(key)
      ) {
        const currentNodeID = selectedNode?.currentNode?.id;
        const relationshipsStatesData = JSON.parse(JSON.stringify(relationshipsStates));
        if (relationshipsStatesData[currentNodeID]) {
          relationshipsStatesData[currentNodeID][key] = data?.value;
        } else {
          relationshipsStatesData[currentNodeID] = {};
          relationshipsStatesData[currentNodeID][key] = data?.value;
        }
        if (key === "linkedResourceTo") {
          relationshipsStatesData[currentNodeID]["linkedAttributeTo"] = {
            label: "",
            value: "",
          };
        }
        setRelationshipsStates({ ...relationshipsStatesData });
        return;
      } else {
        const { value: originalValue } = data.target ?? {};
        let trimmedValue = originalValue;
        if (originalValue.length === 1) {
          trimmedValue = originalValue.trim();
        }
        handleAddChild(treeData, id, trimmedValue, key);
      }
    },
    [selectedNode, handleAddChild, treeData, id, errorNotification, relationshipsStates],
  );

  const handleOnClickBinary = useCallback(
    (key: string, selectedItem: any, name?: string) => {
      if (key === "select-attribute-type") {
        handleAddChild(treeData, id, selectedItem.key, "type");
      }
      if (key === "select-response-type") {
        handleAddChild(treeData, id, selectedItem.key, "visibility");
      }
      if (key === "select-crud-operation") {
        const isRelated = linkedList.reduce((result, arr) => result || arr.includes(id), false);

        let data: any = [];
        const index = crud?.findIndex((item: string) => item === selectedItem.key);
        if (index === -1) {
          data = [...crud, selectedItem.key];
        } else {
          data = crud?.filter((item: string) => item !== selectedItem.key);
        }
        if (!isRelated) {
          setSelectedNode((prev: any) => {
            const newObj = JSON.parse(JSON.stringify(prev));
            newObj.currentNode.crud = data;
            return newObj;
          });
        }

        handleAddChild(treeData, id, data, "crud");
      }
      if (key === "isSubPath") {
        if (!selectedNode.parentNode.isArray && parent === treeData.id) {
          errorNotification(MESSAGES.firstLabelNodeResource);
          return;
        }

        if (
          selectedItem.key === "yes" &&
          (!selectedNode.currentNode.isArray || selectedNode.currentNode.type !== "object")
        ) {
          errorNotification(MESSAGES.listOfObject);
          return;
        }
        const value = selectedItem.key === "yes" ? true : false;
        handleAddChild(treeData, id, value, "isSubPath");
        if (selectedItem.key === "yes") {
          const data: any = resourceConfigData.Expose?.filter((item: any) =>
            BLOCK_ACTION_RESOURCE_YES.includes(item.key as string),
          );
          setSelectedFormMetaData(data);
        } else {
          const data: any = resourceConfigData.Expose?.filter((item: any) =>
            BLOCK_ACTION_RESOURCE_NO.includes(item.key as string),
          );
          setSelectedFormMetaData(data);
        }
      }

      if (key === "select-csv-import-method") {
        if (selectedItem.key === "csv-import") {
          const data: any = resourceConfigData.Import?.filter((item: any) =>
            BLOCK_ACTION_CSV_FILE_IMPORT.includes(item.key as string),
          );
          setSelectedCSVImportMethod(selectedItem.key);
          setSelectedFormMetaData(data);
        } else {
          const data: any = resourceConfigData.Import?.filter((item: any) =>
            BLOCK_ACTION_CSV_MANUAL_IMPORT.includes(item.key as string),
          );
          setSelectedCSVImportMethod(selectedItem.key);
          setSelectedFormMetaData(data);
        }
      }
    },

    [
      handleAddChild,
      treeData,
      id,
      linkedList,
      crud,
      setSelectedNode,
      selectedNode,
      parent,
      errorNotification,
      resourceConfigData.Expose,
      resourceConfigData.Import,
    ],
  );

  const getSwitchValue = useCallback(
    (key: string, name: string) => {
      return selectedNode?.currentNode[key];
    },
    [selectedNode?.currentNode],
  );

  const getDropdownValue = useCallback(
    (key: string) => {
      return selectedNode?.currentNode[key]?.map((item: any) => {
        return { label: item, value: item };
      });
    },
    [selectedNode?.currentNode],
  );

  const getSelectOptions = useCallback(
    (key: string) => {
      let resourceNode: any;
      const currentNodeID = selectedNode?.currentNode?.id;
      switch (key) {
        case "linkedResourceFrom":
          resourceNode = getResourceNode(treeData, currentNodeID);
          return [{ label: resourceNode?.name, value: resourceNode }];
        case "linkedAttributeFrom":
          resourceNode = getResourceNode(
            treeData,
            relationshipsStates?.[currentNodeID]?.["linkedResourceFrom"]?.id,
          );
          const attributeFromOptions = resourceNode?.children ?? [];
          return attributeFromOptions?.map((child: any) => {
            return { label: child?.name, value: child };
          });
        case "linkedResourceTo":
          resourceNode = getResourceNode(
            treeData,
            relationshipsStates?.[currentNodeID]?.["linkedResourceFrom"]?.id ??
              selectedNode?.currentNode?.id,
          );
          if (resourceNode) {
            const filteredOptions = treeData?.children?.filter(
              (child: any) => child.id !== resourceNode?.id && !child?.isSummary,
            );
            return filteredOptions.map((child) => {
              return { label: child?.name, value: child };
            });
          }
          return;
        case "linkedAttributeTo":
          resourceNode = getResourceNode(
            treeData,
            relationshipsStates?.[currentNodeID]?.["linkedResourceTo"]?.id,
          );
          const attributeToOptions = resourceNode?.children ?? [];
          return attributeToOptions?.map((child: any) => {
            return { label: child?.name, value: child };
          });
      }
    },
    [treeData, selectedNode, relationshipsStates, getResourceNode],
  );

  const getSelectValue = useCallback(
    (key: string) => {
      const currentNodeID = selectedNode?.currentNode?.id;
      switch (key) {
        case "possible-values":
          return selectedNode?.currentNode?.enumValues?.map((el: any) => {
            return { label: el, value: el };
          });
        case "linkedResourceFrom":
        case "linkedAttributeFrom":
        case "linkedResourceTo":
        case "linkedAttributeTo":
          const label = relationshipsStates?.[currentNodeID]?.[key]?.name ?? "";
          const value = relationshipsStates?.[currentNodeID]?.[key]?.name ?? "";
          return { label: label, value: value };
        default:
          return getDropdownValue(key);
      }
    },
    [getDropdownValue, selectedNode, relationshipsStates],
  );

  const getDefaultValue = useCallback(
    (key: string) => {
      const currentNodeID = selectedNode?.currentNode?.id;
      const resourceNode: any = getResourceNode(treeData, currentNodeID);
      switch (key) {
        case "linkedResourceFrom":
          return { label: resourceNode?.name, value: resourceNode?.name };
        case "linkedAttributeFrom":
          return {
            label: selectedNode?.currentNode?.name,
            value: selectedNode?.currentNode?.name,
          };
        case "linkedResourceTo":
          return {
            label: selectedNode?.currentNode?.relation?.ref,
            value: selectedNode?.currentNode?.relation?.ref,
          };
        case "linkedAttributeTo":
          return {
            label: selectedNode?.currentNode?.relation?.key,
            value: selectedNode?.currentNode?.relation?.key,
          };
        default:
          return { label: "", value: "" };
      }
    },
    [getResourceNode, selectedNode, treeData],
  );

  const getStyles = useCallback(
    (el: any, key: string) => {
      if (key === "select-attribute-type" && type === el?.key) {
        return "active-binary-option";
      }
      if (key === "select-response-type" && visibility === el.key) {
        return "active-binary-option";
      }
      if (key === "select-crud-operation" && crud?.includes(el?.key?.toUpperCase())) {
        return "active-binary-option";
      }
      if (key === "isSubPath") {
        const value = isSubPath ? "yes" : "no";
        if (value === el?.key) {
          return "active-binary-option";
        }
      }
      if (key === "select-csv-import-method") {
        const value = selectedCSVImportMethod;
        if (value === el?.key) {
          return "active-binary-option";
        }
      }

      return "";
    },
    [type, visibility, crud, isSubPath, selectedCSVImportMethod],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function convertCSVToIntermediateFormat(csvData: any, parentID: number) {
    const result = [];
    const constValidDataTypes = ["string", "number", "boolean", "date", "object", "integer"];
    for (const col of csvData) {
      if (!col) continue;
      if (col?.length === 0) continue;
      if (col[0] === "Key") continue;
      if (col[1] && !constValidDataTypes.includes(col[1].toLowerCase())) {
        Notification.errorNotification(`${col[1]} data type is not supported`);
        return [];
      }
      if (!col[0] || !col[1]) continue;
      const defaultNodeStructure = {
        id: Math.floor(Date.now() * Math.random()),
        parent: 0,
        name: "",
        branchColor: "#FCB900",
        legendId: "default",
        direction: "right",
        description: "",
        inDocumentation: true,
        children: [],
        type: "integer",
        example: "1",
        queryable: false,
        pageable: false,
        queryParams: [],
        crud: [],
        enumValues: [],
        isRelation: false,
        relation: {},
        searchEnabled: false,
        patchEnabled: false,
        visibility: "READ_WRITE",
        required: false,
        xDescription: "",
        abstract: false,
        isArray: false,
        isSubPath: false,
      };
      defaultNodeStructure.parent = parentID;
      defaultNodeStructure.name = col[0];
      defaultNodeStructure.type = col[1].toLowerCase();
      defaultNodeStructure.description = col[2];
      result.push(defaultNodeStructure);
    }
    return result;
  }

  const CsvFileUpload = useCallback(
    (fileData: any, metadata: any, method: string) => {
      const [isValid, , parsedCSVFileData, notificationMessage] = validateFile(
        fileData,
        metadata,
        selectedNode?.currentNode?.children.map((item: any) => item?.name),
        method,
      );

      if (!isValid) {
        Notification.errorNotification(notificationMessage);
        setUploadState("initial");
      } else {
        const csvModifiedData = convertCSVToIntermediateFormat(
          parsedCSVFileData,
          selectedNode?.currentNode?.id,
        );
        if (csvModifiedData.length) {
          const newData = JSON.parse(JSON.stringify(treeData));
          const modifiedJson = addMultipleTreeNode(
            newData,
            selectedNode?.currentNode.id,
            csvModifiedData,
            method,
          );
          if (modifiedJson) {
            setTreeData(modifiedJson);
            setUploadState("uploaded");
            const payload = {
              currentNode: getNodeById(modifiedJson, selectedNode?.currentNode?.id),
            };
            setSelectedNode(payload);
            Notification.successNotification("CSV data added successfully");
          }
        }
      }
    },
    [
      Notification,
      addMultipleTreeNode,
      convertCSVToIntermediateFormat,
      getNodeById,
      selectedNode,
      setSelectedNode,
      setTreeData,
      treeData,
    ],
  );

  const CsvManualUpload = useCallback(
    (fileData: any, metadata: any, method: string) => {
      const [isValid, , , notificationMessage] = validateFile(
        fileData,
        metadata,
        selectedNode?.currentNode?.children.map((item: any) => item?.name),
        method,
      );
      if (!isValid) {
        Notification.errorNotification(notificationMessage);
      } else {
        const csvModifiedData = convertCSVToIntermediateFormat(
          fileData,
          selectedNode?.currentNode?.id,
        );
        if (csvModifiedData.length) {
          const newData = JSON.parse(JSON.stringify(treeData));
          const modifiedJson = addMultipleTreeNode(
            newData,
            selectedNode?.currentNode.id,
            csvModifiedData,
            method,
          );
          if (modifiedJson) {
            setTreeData(modifiedJson);
            setUploadState("uploaded");
            const payload = {
              currentNode: getNodeById(modifiedJson, selectedNode?.currentNode?.id),
            };
            setSelectedNode(payload);
            Notification.successNotification("CSV data added successfully");
          }
        }
      }
      setManualCsvUpload(false);
    },
    [
      Notification,
      addMultipleTreeNode,
      convertCSVToIntermediateFormat,
      getNodeById,
      selectedNode,
      setSelectedNode,
      setTreeData,
      treeData,
    ],
  );

  const handleCsvUpload = useCallback(() => {
    const isRelated = linkedList.reduce((result, arr) => result || arr.includes(id), false);

    if (isRelated) {
      errorNotification(
        "Cannot modify or delete linked resource/attribute. Please delete relationship first to modify.",
      );
    } else {
      const finalXlsValue: any = window;
      finalXlsValue?.luckysheet.exitEditMode();
      const finalData = finalXlsValue?.luckysheet?.transToCellData?.(
        finalXlsValue?.luckysheet.getSheetData(),
      );
      const expectedArray = finalData?.reduce((acc: any, curr: any) => {
        if (!acc[curr.r]) {
          acc[curr.r] = [];
        }
        if (curr.v.v) acc[curr.r][curr.c] = curr.v.v?.toString();
        return acc;
      }, []);
      const metadata = {
        name: "abc.csv",
        type: "text/csv",
      };
      const currentNode = getNodeById(treeData, selectedNode?.currentNode?.id);
      if (expectedArray?.length > 1 && currentNode?.children?.length > 0) {
        setIsModalOpen(true);
        setManualCsvUpload(true);
        setFileData(expectedArray);
        setFileMetaData(metadata);
      } else {
        CsvManualUpload(expectedArray, metadata, "replace");
      }
    }
  }, [
    CsvManualUpload,
    errorNotification,
    getNodeById,
    id,
    linkedList,
    selectedNode?.currentNode?.id,
    treeData,
  ]);

  const handleUploadFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const isRelated = linkedList.reduce((result, arr) => result || arr.includes(id), false);

      if (isRelated) {
        errorNotification(
          "Cannot modify or delete linked resource/attribute. Please delete relationship first to modify.",
        );
      } else {
        setUploadState("uploading");
        const fileReader = new FileReader();
        if (e?.target?.files) {
          const metadata = {
            name: e.target.files[0]?.name,
            type: e.target.files[0]?.type,
          };
          setFileName(metadata.name);
          fileReader.readAsText(e?.target?.files[0], "UTF-8");
          fileReader.onload = async (e) => {
            const currentNode = getNodeById(treeData, selectedNode?.currentNode?.id);
            if (currentNode?.children?.length > 0) {
              setIsModalOpen(true);
              setManualCsvUpload(false);
              setFileData(e?.target?.result);
              setFileMetaData(metadata);
            } else {
              CsvFileUpload(e?.target?.result, metadata, "replace");
            }
          };
        }
      }
    },
    [
      linkedList,
      id,
      errorNotification,
      getNodeById,
      treeData,
      selectedNode?.currentNode?.id,
      CsvFileUpload,
    ],
  );

  const getDisabled = useCallback(
    (key: string, type: string) => {
      if (key === "pageable") {
        if (!selectedNode.currentNode.searchEnabled) {
          return true;
        }
      }
      return false;
    },
    [selectedNode],
  );

  const handleOnConfirm = useCallback(
    (method: string) => {
      if (fileData) {
        if (manualCsvUpload) {
          CsvManualUpload(fileData, fileMetaData, method);
        } else if (!manualCsvUpload) {
          CsvFileUpload(fileData, fileMetaData, method);
        }
        setFileData(null);
        setManualCsvUpload(false);
        setIsModalOpen(false);
      }
    },
    [CsvFileUpload, CsvManualUpload, fileData, fileMetaData, manualCsvUpload],
  );

  const handleOnCancel = useCallback(() => {
    setIsModalOpen(false);
    setUploadState("initial");
  }, []);

  const handleOnRelationOverride = useCallback(() => {
    const relationData = relationshipsStates?.[selectedNode?.currentNode.id];
    const linkedAttributes = findMatchingNestedArray(
      linkedList,
      relationData?.linkedAttributeFrom?.id,
      relationData?.linkedAttributeTo?.id,
    );
    let linkedAttribute = linkedAttributes
      .filter((attributeID) => {
        const node = getNodeById(treeData, attributeID);
        return node && node.isRelation;
      })
      .map((attributeID) => getNodeById(treeData, attributeID));

    const linkedResource1 = getResourceNode(treeData, linkedAttribute[0]?.relation?.id);
    const linkedResource2 = getResourceNode(treeData, linkedAttribute[0]?.id);

    const emptyAlreadyRelatedData = [
      { key: "isRelation", value: false },
      {
        key: "relation",
        value: {},
      },
    ];
    const relationDataFrom = [
      { key: "isRelation", value: true },
      {
        key: "relation",
        value: {
          key: relationData?.linkedAttributeTo?.name,
          ref: relationData?.linkedResourceTo?.name,
          populate: true,
          parentNode: relationData?.linkedResourceFrom?.name,
          id: relationData?.linkedAttributeTo?.id,
        },
      },
    ];
    let newData: any = JSON.parse(JSON.stringify(treeData));
    const alreadyRelatedAttributeId = relationData?.linkedAttributeFrom?.isRelation
      ? relationData.linkedAttributeFrom?.id
      : linkedAttribute[0]?.id;

    for (const item of emptyAlreadyRelatedData) {
      newData = changeChildValue(newData, alreadyRelatedAttributeId, item.value, item.key);
    }
    for (const item of relationDataFrom) {
      newData = changeChildValue(
        newData,
        relationData?.linkedAttributeFrom?.id,
        item.value,
        item.key,
      );
    }
    const itemToFilter = [linkedResource1?.id, linkedResource2?.id];
    const filteredLinkedList = alreadyLinked.filter((nestedArray) => {
      const [element1, element2] = nestedArray;
      return (
        !(element1 === itemToFilter[0] && element2 === itemToFilter[1]) &&
        !(element1 === itemToFilter[1] && element2 === itemToFilter[0])
      );
    });
    setAlreadyLinked(filteredLinkedList);
    setTreeData(newData);
    setIsModalOpen(false);
    Notification.successNotification("Relationship has been overridden successfully");
  }, [
    Notification,
    alreadyLinked,
    changeChildValue,
    getNodeById,
    getResourceNode,
    linkedList,
    relationshipsStates,
    selectedNode?.currentNode.id,
    setTreeData,
    treeData,
  ]);

  const handleOnClick = useCallback(() => {
    const isValidRelationship = validateRelationshipForm();
    if (isValidRelationship?.status) {
      Notification.successNotification(isValidRelationship?.message);
    } else {
      if (isValidRelationship?.showModal) {
        setIsModalOpen(true);
        setModalMessage(isValidRelationship?.message);
      } else Notification.errorNotification(isValidRelationship?.message);
    }
  }, [Notification, validateRelationshipForm]);

  const handleOnDelete = useCallback(
    (id: any) => {
      const relationData = [
        { key: "isRelation", value: false },
        {
          key: "relation",
          value: {
            key: "",
            ref: "",
          },
        },
      ];
      let newData: any = JSON.parse(JSON.stringify(treeData));
      for (const item of relationData) {
        newData = changeChildValue(newData, id, item.value, item.key);
      }
      if (newData) {
        const linkedIDs = [...linkedList];
        let deleteID: any;
        let IDFrom, IDTo;

        for (const linkedID of linkedIDs) {
          if (linkedID.includes(id)) {
            deleteID = linkedID;
            [IDFrom, IDTo] = linkedID;
            break;
          }
        }
        if (IDFrom && IDTo) {
          const nodeFrom = getResourceNode(treeData, IDFrom);
          const nodeTo = getResourceNode(treeData, IDTo);
          const filteredArray = [...alreadyLinked].filter(
            (arr) => !arr.includes(nodeFrom?.id) && !arr.includes(nodeTo?.id),
          );
          const filteredLinkedList = [...linkedList].filter((str) => str !== deleteID);
          const newRelationStatesData = JSON.parse(JSON.stringify(relationshipsStates));
          newRelationStatesData[IDFrom] = {};
          setAlreadyLinked(filteredArray);
          setLinkedList(filteredLinkedList);
          setRelationshipsStates(newRelationStatesData);
          setTreeData(newData);
          Notification.successNotification("Relationship has been deleted!");
        } else {
          Notification.errorNotification("Unable to delete");
        }
      }
    },
    [
      Notification,
      alreadyLinked,
      changeChildValue,
      getResourceNode,
      linkedList,
      relationshipsStates,
      setLinkedList,
      setTreeData,
      treeData,
    ],
  );

  const getDropdownDisabled = useCallback(
    (key: string) => {
      if (key === "possible-values" && !/integer|number|string/.test(type)) {
        return true;
      }
      return false;
    },
    [type],
  );

  const handleRenderSettings = useCallback(
    (item: any) => {
      const {
        type,
        label,
        placeholder,
        options,
        className,
        name,
        key,
        isCreatable = false,
      } = item ?? {};

      const validatedPlaceholder =
        name === "name"
          ? selectedNode?.currentNode?.isSubPath
            ? "resource"
            : "attribute"
          : placeholder;

      let jsx: any;
      switch (type) {
        case "linkedList":
          const relationShipsData: any = getRelations();
          return (
            <div
              style={{
                height: "auto",
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}>
              {relationShipsData?.map((item: any) => (
                <div
                  key={item?.id}
                  className="relationship-item-wrapper"
                  style={{
                    backgroundColor: "var(--adf-color-input-background)",
                    color: "var(--text-primary-tab)",
                    height: "min-content",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    padding: "0 10px",
                  }}>
                  <div
                    style={{
                      width: "90%",
                      padding: "5px",
                      alignItems: "center",
                      wordBreak: "break-all",
                    }}>
                    <span style={{ marginRight: "10px" }}>
                      {item.linkedResourceFrom}.{item.linkedAttributeFrom}
                    </span>
                    <i className="ri-links-line" style={{ color: "var(--icon-color)" }}></i>
                    <span style={{ marginLeft: "10px" }}>
                      {item.linkedResourceTo}.{item.linkedAttributeTo}
                    </span>
                  </div>
                  <div
                    style={{
                      color: "#fff",
                      backgroundColor: "#a9017c",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      display: "grid",
                      placeItems: "center",
                      position: "relative",
                      fontSize: "16px",
                    }}
                    onClick={() => {
                      handleOnDelete(item.id);
                    }}>
                    <i className="ri-delete-bin-5-line"></i>
                  </div>
                </div>
              ))}
            </div>
          );

        case "icon":
          if (key === "link-icon") {
            return (
              <i
                className="ri-links-line"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#8C97B8",
                }}></i>
            );
          } else {
            return null;
          }
        case "button":
          jsx = (
            <div>
              <h3>Confirm override</h3>
              <p>{modalMessage}</p>
              <p>Do you want to override it?</p>
            </div>
          );
          return (
            <div className="btn-container">
              <Button
                label={label}
                handleClick={handleOnClick}
                type="adf-button__filled adf-button__filled--secondary adf-button__large inner"
              />
              <ConfirmationModal
                isOpen={isModalOpen}
                onConfirm={handleOnRelationOverride}
                onCancel={handleOnCancel}
                jsx={jsx}
                buttonTexts={["Override"]}
              />
            </div>
          );
        case "title":
          return <div className="resource-config__inner__tab-body__title">{label}</div>;
        case "toggle":
          return (
            <div className="resource-config__inner__toggle-wrapper">
              <div className="resource-config__inner__toggle-wrapper__label">{label}</div>
              <Switch
                checked={getSwitchValue(key, name)}
                handleChange={() => handleOnChange(selectedNode?.currentNode[key], key, type)}
                id=""
                onColor="#33b87a"
                isDisabled={getDisabled(key, type)}
              />
            </div>
          );
        case "text":
          return (
            <Input
              label={label}
              value={
                nameError[selectedNode?.currentNode?.id]
                  ? nameErrorMessage[selectedNode?.currentNode?.id].value
                  : selectedNode?.currentNode[name]
              }
              inputType={type}
              placeholder={validatedPlaceholder}
              handleChange={(e) => handleOnChange(e, name)}
              isError={nameError[selectedNode?.currentNode?.id] ?? false}
              errorMessage={nameErrorMessage[selectedNode?.currentNode?.id]?.message ?? ""}
            />
          );
        case "textarea":
          return (
            <TextArea
              label={label}
              handleChange={(e) => handleOnChange(e, name)}
              value={selectedNode?.currentNode[name] ?? ""}
              placeholder={validatedPlaceholder}
            />
          );
        case "select":
          return (
            <div
              style={{
                cursor: getDropdownDisabled(key) ? "not-allowed" : "default",
              }}>
              <ReactDropdown
                options={getSelectOptions(key) ?? []}
                value={getSelectValue(key)}
                defaultValue={getDefaultValue(key)}
                handleChangeSelect={(event) => handleOnChange(event, name, type)}
                isCreatable={isCreatable}
                placeholder={validatedPlaceholder}
                isDisabled={getDropdownDisabled(key)}
              />
            </div>
          );
        case "binary-choice":
          return (
            <div
              className={`binary-choice__wrapper ${className ?? ""}`}
              style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <div className="binary-choice__label">{label}</div>
              <div className="options-wrapper">
                {options.map((el: any) => (
                  <div
                    key={el.key}
                    className={`option ${getStyles(el, key)}`}
                    onClick={() => handleOnClickBinary(key, el)}>
                    {el.label}
                  </div>
                ))}
              </div>
            </div>
          );
        case "file-upload":
          jsx = (
            <div>
              <h3>Upload from CSV</h3>
              <p>
                All the attribute information will be uploaded from CSV. Choose your option for
                upload.
              </p>
            </div>
          );
          return (
            <div>
              <FileUpload
                handleChange={handleUploadFile}
                isUploaded={uploadState}
                fileName={fileName}
              />
              <ConfirmationModal
                isOpen={isModalOpen}
                onCancel={handleOnCancel}
                onConfirm={handleOnConfirm}
                jsx={jsx}
                buttonTexts={["Append", "Replace"]}
              />
            </div>
          );
        case "description":
          if (key === "relationship-note") {
            const currentNodeID = selectedNode?.currentNode?.id;
            if (
              hasKeys(relationshipsStates?.[currentNodeID], [
                "linkedResourceTo",
                "linkedAttributeTo",
                "linkedResourceFrom",
                "linkedAttributeFrom",
              ])
            ) {
              const linkedResourceFrom =
                relationshipsStates?.[currentNodeID]?.["linkedResourceFrom"]
                  ?.name;
              const linkedAttributeFrom =
                relationshipsStates?.[currentNodeID]?.["linkedAttributeFrom"]
                  ?.name;
              const linkedResourceTo =
                relationshipsStates?.[currentNodeID]?.["linkedResourceTo"]
                  ?.name;
              const linkedAttributeTo =
                relationshipsStates?.[currentNodeID]?.["linkedAttributeTo"]
                  ?.name;

              if (
                linkedResourceFrom &&
                linkedAttributeFrom &&
                linkedResourceTo &&
                linkedAttributeTo
              ) {
                const linkedFromString = `${linkedResourceFrom}.${linkedAttributeFrom}`;
                const linkedToString = `${linkedResourceTo}.${linkedAttributeTo}`;

                return (
                  <div className="relationship-note">
                    <span>{linkedFromString}</span>
                    has many
                    <span>{linkedToString}</span>
                  </div>
                );
              }
            }
          }
          if (key === "csv-note") return <div className="note__label">{label}</div>;
          return <div className="description__label">{label}</div>;
        case "csv-manual-import":
          jsx = (
            <div>
              <h3>Upload from CSV</h3>
              <p>
                All the attribute information will be uploaded from CSV. Choose your option for
                upload.
              </p>
            </div>
          );
          return (
            <Fragment>
              <div style={{ position: "relative", height: "400px", width: "100%" }}>
                <CaptableGrid celldata={DEFAULT_CSV_HEADER} currentNode={selectedNode} />
              </div>
              <div className="btn-container">
                <Button
                  label="Upload"
                  handleClick={handleCsvUpload}
                  type="adf-button__filled adf-button__filled--secondary adf-button__large inner"
                />
              </div>
              <ConfirmationModal
                isOpen={isModalOpen}
                onCancel={handleOnCancel}
                onConfirm={handleOnConfirm}
                jsx={jsx}
                buttonTexts={["Append", "Replace"]}
              />
            </Fragment>
          );
      }
    },
    [
      selectedNode,
      getRelations,
      modalMessage,
      handleOnClick,
      isModalOpen,
      handleOnRelationOverride,
      handleOnCancel,
      getSwitchValue,
      getDisabled,
      nameError,
      nameErrorMessage,
      getDropdownDisabled,
      getSelectOptions,
      getSelectValue,
      getDefaultValue,
      handleUploadFile,
      uploadState,
      fileName,
      handleOnConfirm,
      handleCsvUpload,
      handleOnDelete,
      handleOnChange,
      getStyles,
      handleOnClickBinary,
      relationshipsStates,
    ],
  );

  const handleTabScroll = useCallback(
    (direction: string) => {
      const allTabs = Object.keys(resourceConfigData);
      const indexOfFirstActiveTab = allTabs.indexOf(activeTabs[0]);
      const indexOfLastActiveTab = allTabs.indexOf(activeTabs[3]);
      if (direction === "left") {
        if (indexOfFirstActiveTab === 0) return;
        const newActiveTabs = allTabs.slice(indexOfFirstActiveTab - 1, indexOfFirstActiveTab + 3);
        setActiveTabs(newActiveTabs);
      } else {
        if (indexOfLastActiveTab === allTabs?.length - 1) return;
        const newActiveTabs = allTabs.slice(indexOfFirstActiveTab + 1, indexOfFirstActiveTab + 5);
        setActiveTabs(newActiveTabs);
      }
    },
    [activeTabs, resourceConfigData],
  );

  const renderTab = useMemo(
    () =>
      activeTabs.map((item: any) => {
        return (
          <div key={item} onClick={() => handleOnChangeTab(item)} className={getClassName(item)}>
            {item}
          </div>
        );
      }),
    [activeTabs, getClassName, handleOnChangeTab],
  );

  const renderSetting = useMemo(
    () =>
      selectedFormMetaData.map((item: any, index: number) => (
        <div
          key={index}
          style={{
            display:
              item.type === "description"
                ? classConditionRelationshipNote(item)
                  ? "flex"
                  : "none"
                : "flex",
            flexDirection: "column",
            gap: "8px",
          }}
          className={selectedNode?.currentNode?.isDisabled ? "form-wrapper" : ""}>
          {handleRenderSettings(item)}
          {item.note && <div className="resource-config__inner__note">{item?.note}</div>}
          {item.isBorder && <div className="border-line" />}
        </div>
      )),
    [
      classConditionRelationshipNote,
      handleRenderSettings,
      selectedFormMetaData,
      selectedNode?.currentNode?.isDisabled,
    ],
  );

  return (
    <div className="resource-config">
      <div className="resource-config__inner">
        <div className="resource-config__inner__scroller">
          <div
            className="resource-config__inner__scroller__left"
            onClick={() => handleTabScroll("left")}>
            &lt;
          </div>
          <div className="resource-config__inner__scroller__tab-header">{renderTab}</div>
          <div
            className="resource-config__inner__scroller__right"
            onClick={() => handleTabScroll("right")}>
            &gt;
          </div>
        </div>
        <div
          className="resource-config__inner__tab-body"
          style={{
            height: rootHeight ? "calc(100vh - 370px)" : "calc(100vh - 250px)",
          }}>
          {renderSetting}
        </div>
      </div>
    </div>
  );
};
