import { useCallback, useEffect, useMemo, useState } from "react";

import "./summary.scss";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  SummaryMapping,
  currentNode,
  toggleAccordionState,
  treeDataState,
} from "states/states";
import { SummaryCard } from "./summaryCard";
import { ACCORDION_QUERIES } from "../settings/constants";
import { useNotification } from "hooks";

export const Summary = ({ setNextBtnClicked, setSelectedItem }: any) => {
  const [expressionsList, setExpressionsList] = useRecoilState<any>(SummaryMapping);
  const treeData = useRecoilValue(treeDataState);
  const { successNotification} = useNotification();
  const selectedNode= useRecoilValue(currentNode)

  const [accordionVisibility, setAccordionVisibility] = useState<any>({});
  const [excludedAttributes, setExcludedAttributes] = useState<any>([]);
  const [deletedIndices, setDeletedIndices] = useState<number[]>([]);
  const setToggleAccordion = useSetRecoilState(toggleAccordionState);

  const expressionsListIndex = expressionsList[treeData?.id].map(
    (value: any, index: any) => {
      return { index, value };
    }
  );
  useEffect(() => {
    if (treeData) {
      const summaryData = treeData.children.find(
        (child: any) => child.name === "summary"
      );

      if (summaryData && summaryData.children) {
        const childrenWithCalculations = summaryData.children.filter(
          (child: any) => child.calculations && child.calculations.length > 0
        );

        const calculationName = childrenWithCalculations.map(
          (child: any) => child.name
        );
        const isAttributeIncludedd: any = {};
        calculationName.map((child: any) => {
          for (let i = 0; i < expressionsListIndex.length; i++) {
            if (expressionsListIndex[i]?.value.linkedTo === child) {
              isAttributeIncludedd[i] = true;
            }
          }
        });

        if (isAttributeIncludedd) {
          setToggleAccordion((prev: any) => {
            return { ...prev, ...isAttributeIncludedd };
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData]);

  useEffect(() => {
    const newExcludedAttributes: any = [];
    const expressionsMapping = ACCORDION_QUERIES.map((query: any, index: number) => {
      newExcludedAttributes.push(query?.summaryAttribute);
      setAccordionVisibility((prevVisibility: any) => {
        const newAccordionVisibility = { ...prevVisibility };
        newAccordionVisibility[index] = false;
        return newAccordionVisibility;
      });
      return {
        linkedTo: query?.summaryAttribute,
        expression: [{ type: "attribute", value: "" }],
        title: { value: query?.title, selectable: false, toggle: false },
      };
    });
    const summaryData: any = treeData?.children?.find((item: any) => item?.isSummary);
    for (const attribute of summaryData?.children ?? []) {
      if (!attribute?.calculations) continue;
      newExcludedAttributes.push(attribute?.name);
    }
    setExcludedAttributes([...newExcludedAttributes]);

    if (!expressionsList[treeData?.id] || expressionsList[treeData?.id].length === 0) {
      const newExpressionList = JSON.parse(JSON.stringify(expressionsList));
      newExpressionList[treeData?.id] = [...expressionsMapping];
      setExpressionsList(newExpressionList);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData]);

  const handleAddNew = useCallback(() => {
    const newExpressionList = JSON.parse(JSON.stringify(expressionsList));
    if (!Array.isArray(newExpressionList[treeData?.id])) newExpressionList[treeData?.id] = [];
    setAccordionVisibility((prevVisibility: any) => {
      const newAccordionVisibility = { ...prevVisibility };
      newAccordionVisibility[Object.keys(newAccordionVisibility).length] = true;
      return newAccordionVisibility;
    });
    newExpressionList[treeData?.id].push({
      linkedTo: "",
      expression: [{ type: "attribute", value: "" }],
      title: { value: "", selectable: true },
    });
    setExpressionsList(newExpressionList);
  }, [expressionsList, setExpressionsList, treeData?.id]);

  const handleDeleteMapping = useCallback(
    (index: number, summaryAttribute: string) => {
      const newExpressionList = JSON.parse(JSON.stringify(expressionsList));
      newExpressionList[treeData?.id].splice(index, 1);
      setExpressionsList(newExpressionList);


      let newExcludedAttributes = JSON.parse(JSON.stringify(excludedAttributes));
      newExcludedAttributes = newExcludedAttributes.filter((item: string) => item !== summaryAttribute);
      setExcludedAttributes(newExcludedAttributes);
      successNotification("Deleted Successfully");
    },
    [excludedAttributes, expressionsList, setExpressionsList, successNotification, treeData?.id],
  );

  const handleMappingEdit = useCallback(
    (linkedTo: "string", expression: string[], index: number) => {
      const newExpressionList = JSON.parse(JSON.stringify(expressionsList));
      if (expression?.length > 0) newExpressionList[treeData?.id][index].expression = expression;
      if (linkedTo) newExpressionList[treeData?.id][index].linkedTo = linkedTo;
      setExpressionsList(newExpressionList);
      setExcludedAttributes((prev: any) => [...prev, linkedTo]);
    },
    [expressionsList, treeData?.id, setExpressionsList, setExcludedAttributes],
  );

  const handleOnClickAccordion = useCallback(
    (index: any) => {
      setAccordionVisibility((prev: { [x: string]: any; }) => ({
        ...prev,
        [index]: !prev[index],
      }));
    },
    [],
  );

  const renderSummaryList = useMemo(() => {
    return (
      <>
        {expressionsList[treeData?.id]?.map((item: any, index: number) => {
          if (!deletedIndices.includes(index)) { 
            return (
              <div className="summary-item" key={index}>
                <SummaryCard
                  handleSave={handleMappingEdit}
                  expressionsList={expressionsList[treeData?.id][index]}
                  indexOfExpressionList={index}
                  accordionVisibility={accordionVisibility[index]}
                  handleToggleAccordion={handleOnClickAccordion}
                  excludedAttributes={excludedAttributes}
                />
                {!expressionsList[treeData?.id][index]?.title?.value && (
                  <div className="delete-wrapper">
                    <div
                      className="delete-summary-mapping"
                      onClick={() => handleDeleteMapping(index, expressionsList[treeData?.id][index]?.linkedTo)}
                    >
                      <i className="ri-delete-bin-5-line"></i>
                    </div>
                  </div>
                )}
              </div>
            );
          }
          return null;
        })}
      </>
    );
  }, [
    accordionVisibility,
    deletedIndices,
    excludedAttributes,
    expressionsList,
    handleDeleteMapping,
    handleMappingEdit,
    handleOnClickAccordion,
    treeData?.id,
  ]);

  return (
    <>
      <div className="api-settings__sub-info">
        <div className="api-settings__sub-info__title">Please complete your endpoints summary</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}>
        <div
          onClick={() => {
            setNextBtnClicked(false);
            
            if(selectedNode?.version){
              setSelectedItem("General");
            }
            else{
              setSelectedItem("");
            }
          }}
          className="go-back">
          <i className="ri-arrow-left-s-line"></i>
          Back
        </div>
        <div onClick={handleAddNew} className="add-new-summary">
          <i className="ri-add-box-fill"></i>
          <div>Add New</div>
        </div>
      </div>
      <div className="next-wrapper">
        <div className="summary-list">{renderSummaryList}</div>
      </div>
    </>
  );
};
