import { useCallback } from "react";

export const useEnvUrl = () => {
  // TODO: @Pankaj refactor code it should work on every platform
  const url =
    (window as any)?.__ENV?.REACT_APP_API_HOST || window.location.origin;
  const getUrl = useCallback(() => {
    if (url.includes("localhost")) {
      return "https://api.stage.satschel.com/v2/apidesigner";
      // return "http://localhost:3001";
    }
    if (url.includes("pp.")) {
      return "https://api.pp.satschel.com/v2/apidesigner";
    }
    if (url.includes("beta.")) {
      return "https://api.beta.satschel.com/v2/apidesigner";
    }
    if (!/pp|stage|beta/gi.test(url)) {
      return "https://api.satschel.com/v2/apidesigner";
    } else return "https://api.stage.satschel.com/v2/apidesigner";
  }, [url]);

  const getAppUrl = useCallback(() => {
    if (url.includes("localhost")) {
      return "http://localhost:3000";
    }
    if (url.includes("pp.")) {
      return "https://api-designer.pp.satschel.com";
    }
    if (url.includes("beta.")) {
      return "https://api-designer.beta.satschel.com";
    }
    if (!/pp|stage|beta/gi.test(url)) {
      return "https://api-designer.satschel.com";
    } else return "https://api-designer.stage.satschel.com";
  }, [url]);

  const getToken = useCallback(() => {
    if (getUrl() === "https://api.pp.satschel.com/v2/apidesigner") {
      return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MzljOTkxYWYxMTMyNGNhMmMzMjU1ODIiLCJpYXQiOjE2ODM2Mjc2NTB9.K-WBO5-K47Rr3ecHlgfvCWnq4Q4fxpqikr-Qf7AsBUE";
    } else {
      return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MzZiNmVlMWE4ODE4ODU2M2YzMjczMWMiLCJpYXQiOjE2ODM1NDMyNDZ9.i31cjniaewfOMHlOVFCxyHhNU1KHpKzwGNtQVwYVCvc";
    }
  }, [getUrl]);

  return { getUrl, getAppUrl, getToken };
};
