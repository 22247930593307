function csvToArray(csv: string) {
  if (Array.isArray(csv)) return csv;
  const rows = csv.split("\n");
  const result = [];
  const headers = rows[0].split(",");
  for (let i = 1; i < rows.length; i++) {
    const row = rows[i].replace(/\r$/, "").split(",");
    result.push(row);
  }
  result.unshift(headers);
  return result;
}

function hasAllElements(array: string[], elements: string[]) {
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i].replace(/\r$/, "");
    if (!array.includes(element)) {
      return false;
    }
  }
  return true;
}

export const validateFile = (
  fileData: any,
  fileMetadata: any,
  prevAttributes: any,
  method: string
) => {
  //TODO: write file format and schema validations
  let isValid: boolean = false;
  let parsedCSVFileData: any = "";
  const fileNameArray = fileMetadata?.name?.split(".");
  const ext = fileNameArray[fileNameArray.length - 1];
  if (ext === "csv") {
    const attributes: any[] = [];
    parsedCSVFileData = csvToArray(fileData);
    parsedCSVFileData = parsedCSVFileData.filter((arr: []) => !!arr.length);
    const headers = parsedCSVFileData[0];
    if (fileData.length < 2) {
      return [isValid, "csv", "", "File should not be empty"];
    }
    if (headers.length !== 3) {
      return [isValid, "csv", "", "Invalid file format"];
    }
    if (!hasAllElements(["Key", "Type", "Description"], headers)) {
      return [isValid, "csv", "", "Invalid file format"];
    }
    for (const i in parsedCSVFileData) {
      const row = parsedCSVFileData[i];
      if (row[0] === undefined || row[1] === undefined) {
        return [
          isValid,
          "csv",
          "",
          `Please check the row number ${
            parseInt(i) + 1
          }, Column Key and Type should not be empty`,
        ];
      }
      if (!attributes.includes(row[0])) {
        attributes.push(row[0]);
      } else {
        isValid = false;
        return [
          isValid,
          "csv",
          parsedCSVFileData,
          `"${row[0]}" has multiple occurence. Each attribute should be unique`,
        ];
      }
    }

    if (
      prevAttributes.filter((elem: any) => attributes.includes(elem)).length >
        0 &&
      method === "append"
    ) {
      return [
        isValid,
        "csv",
        parsedCSVFileData,
        "One or more attributes are already present.",
      ];
    }
    isValid = true;
    return [isValid, "csv", parsedCSVFileData, "File uploaded successfully"];
  }
  return [isValid, "csv", "", "Invalid File"];
};
