import { defaultTreeData } from "constants/constants";
import { atom } from "recoil";

export const isRootNodeSelected = atom({
  key: "root-node",
  default: true,
});

export const currentNode = atom<any>({
  key: "current-node",
  default: {
    currentNode: {},
    parentNode: {},
  },
});

export const treeDataState = atom({
  key: "tree-data",
  default: defaultTreeData,
});

export const documentationType = atom({
  key: "open-spec-type",
  default: "rapidoc",
});

export const documentationID = atom({
  key: "open-spec-id",
  default: 0,
});

export const entityName = atom<any>({
  key: "entity-name",
  default: "",
});

export const SavedTreeData = atom<any>({
  key: "saved-tree-data",
  default: [],
});

export const selectedItemState = atom({
  key: 'selected-Item-State',
  default: 'General',
});

export const nextBtnClickedState = atom({
  key: 'next-Btn-Clicked-State',
  default: false,
});

export const SavedTreeMetadata = atom<any>({
  key: "saved-tree-metadata",
  default: [],
});

export const TemplatesData = atom<any>({
  key: "templates-data",
  default: [],
});

export const RelationLinkedList = atom<any>({
  key: "relation-linked-list",
  default: [],
});

export const SummaryMapping = atom<any>({
  key: "summary-mapping",
  default: {},
});

export const AccessToken = atom({
  key: "access-token",
  default: "",
});

export const UrlPrefix = atom({
  key: "url-prefix",
  default: "",
});

export const RootHeight = atom({
  key: "root-height",
  default: 0,
});

export const RootWidth = atom({
  key: "root-width",
  default: 0,
});
export const IsTopBar = atom({
  key: "topbar- visibility",
  default: "true",
});

export const toggleAccordionState = atom<any>({
  key: 'toggle-accordion-state',
  default: {},
});
