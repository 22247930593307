import { ChangeEvent, FC } from "react";

import "./text-area.scss";

interface ITextArea {
  label: string;
  handleChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  placeholder: string;
  isError?: boolean;
  row?: number;
  disabled?: boolean;
}

export const TextArea: FC<ITextArea> = ({
  label,
  handleChange,
  value,
  placeholder,
  isError = false,
  disabled = false,
  row = 6,
}) => {
  return (
    <div className="adf-input">
      <div className="adf-input__label-text">{label}</div>
      <div
        className={`adf-input__text-field adf-input__text-field${
          isError ? "--error" : ""
        }`}
      >
        <textarea
          name="some area"
          typeof="text"
          className="adf-input__field adf-text-area-field"
          rows={row}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
