import numeral from "numeral";

export const NumberWithCommas = (number: number) => {
  return Number(number).toLocaleString();
};

export const useFormatNumber = () => {
  const numberDecimal = (value: number | undefined) => {
    return numeral(value ?? 0).format("0,0.00");
  };

  return { numberDecimal };
};

export const AddZeroInSingleNumbers = (integer: string) => {
  return integer.length === 1 ? `0${integer}` : integer;
};
