import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { currentNode } from "states/states";

export const useAPIEditor = () => {
  const setSelectedNode = useSetRecoilState(currentNode);

  const changeChildValue = useCallback(
    (json: any, id: any, value: any, key: string, index?: number) => {
      if (json.id === id) {
        setSelectedNode((prev: any) => {
          const newObj = JSON.parse(JSON.stringify(prev));
          if (index && newObj?.parentNode?.children) {
            newObj.parentNode.children[index][key] = value;
          }
          if (key === "isArray" && !value) {
            newObj.currentNode["isSubPath"] = false;
          }
          if (key === "type") {
            newObj.currentNode["enumValues"] = [];
          }

          newObj.currentNode[key] = value;
          return newObj;
        });
        if (key === "isArray" && !value) {
          json["isSubPath"] = false;
        }
        json[key] = value;
        if (key === "type") {
          json["enumValues"] = [];
        }
        return { ...json };
      } else if (json.children) {
        for (let i = 0; i < json.children.length; i++) {
          const modifiedChild = changeChildValue(
            json.children[i],
            id,
            value,
            key,
            i
          );
          if (modifiedChild) {
            json.children[i] = modifiedChild;
            return { ...json };
          }
        }
      }
      return null;
    },
    [setSelectedNode]
  );

  return {
    changeChildValue,
  };
};
