import React from "react";
import ReactDOM from "react-dom/client";

import reportWebVitals from "./reportWebVitals";

import App from "./App";

import "./index.scss";

import "remixicon/fonts/remixicon.css";

//@ts-ignore
import reactToWebComponent from "react-to-webcomponent";

const AppContainer = reactToWebComponent(
  App as any,
  React as any,
  ReactDOM as any,
  {
    props: {
      token: "string",
      theme: "string",
      sidebar: "string",
      topbar: "string",
      logo: "string",
      show_dark_toggle: "string",
      logout: "string",
      admin: "string",
      url_prefix: "string",
      height: "string",
      width: "string",
    },
  }
);

customElements.define("api-designer", AppContainer as any);

// createWebComponent(App, "api-designer", ReactDOM, {
//   styles: [
//     "https://cdn.jsdelivr.net/npm/remixicon@3.2.0/fonts/remixicon.css",
//     "https://cdn.jsdelivr.net/npm/luckysheet/dist/assets/iconfont/iconfont.css",
//     "https://cdn.jsdelivr.net/npm/luckysheet/dist/css/luckysheet.css",
//     "https://cdn.jsdelivr.net/npm/luckysheet/dist/plugins/plugins.css",
//     "https://cdn.jsdelivr.net/npm/luckysheet/dist/plugins/css/pluginsCss.css",
//   ],
//   scrips: [
//     "https://cdn.jsdelivr.net/npm/luckysheet/dist/plugins/js/plugin.js",
//     "https://cdn.jsdelivr.net/npm/luckysheet/dist/luckysheet.umd.js",
//   ],
// });

reportWebVitals();
