import { Image, Loader } from "@storybook";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { TemplatesData, UrlPrefix, treeDataState } from "states/states";

import { useNetwork } from "hooks";

import { DESCRIBE_API_META } from "./constant";
import { defaultTreeData } from "constants/constants";

import "./describe-api.scss";
import { unSecureddefaultTreeData } from "constants/constants";

type FetchState = "fetching" | "fetched";
export const DescribeAPI = () => {
  const setTemplatesData = useSetRecoilState(TemplatesData);
  const setTreeData = useSetRecoilState(treeDataState);
  const urlPrefix = useRecoilValue(UrlPrefix);
  const navigate = useNavigate();

  const [fetchState, setFetchState] = useState<FetchState>("fetched");

  const { get } = useNetwork();

  const fetchTemplate = useCallback((path: string) => {
    setFetchState("fetching");
    get("/api/api-template")
      .then((res) => {
        if (res.statusCode === 200) {
          setFetchState("fetched");
          const originalResData = JSON.parse(JSON.stringify(res?.data));
          // eslint-disable-next-line array-callback-return
          originalResData?.map((el: any) => {
            el.id = Math.floor(Date.now() * Math.random());
          });
          setTemplatesData(originalResData as any);
          navigate(urlPrefix ? `../${urlPrefix}/${path}` : `../${path}`);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleImportoptions = useCallback(
    (path: string, key: string) => {
      if (key === "templates") {
        fetchTemplate(path);
      } else {
        sessionStorage.removeItem("selectedTemplate");
        const newDefaultTreeData = JSON.parse(JSON.stringify(defaultTreeData));
        const rootNodeID = Math.floor(Date.now() * Math.random());
        newDefaultTreeData?.children.map(
          (child: any) => (child.parent = rootNodeID)
        );
        newDefaultTreeData["id"] = rootNodeID;
        setTreeData(newDefaultTreeData);
        navigate(urlPrefix ? `../${urlPrefix}/${path}` : `../${path}`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, get]
  );

  const renderAction = useMemo(
    () =>
      DESCRIBE_API_META.map((el: any, index: number) => {
        return (
          <div
            key={el.path + index}
            className="describe-api__action-wrapper__item"
            onClick={() => {
              handleImportoptions(el.path, el.key);
            }}
          >
            <div className="describe-api__action-wrapper__item__leftIcon">
              <i className={el.icon} />
            </div>
            {el.key === "templates" && fetchState === "fetching" ? (
              <div className="describe-api__action-wrapper__item__loader">
                <Loader type="circle" className="loader-blue" dimension={20} />
              </div>
            ) : (
              <div className="describe-api__action-wrapper__item__label">
                {el.label}
              </div>
            )}
            <div className="describe-api__action-wrapper__item__rightIcon">
              <i className={el.rightIcon} />
            </div>
          </div>
        );
      }),
    [handleImportoptions, fetchState]
  );

  return (
    <div className="describe-api">
      <div className="describe-api__inner">
        <div className="describe-api__inner__img-wrapper">
          <Image fileName="describe-api.svg" />
        </div>

        <div className="describe-api__inner__content">
          <div className="describe-api__inner__content__label">
            Describe Your API
          </div>
          <div className="describe-api__inner__content__sub-title">
            First, tell us about your API. You can use an API definition file
            (Open API, Swagger, CSV, Json and Postman) or our manual editor. For
            API definition files, you can set up a GitHub or CLI-based syncing
            workflow or just upload a file the old-fashioned way!
          </div>
        </div>
        <div className="describe-api__action-wrapper">{renderAction}</div>
      </div>
    </div>
  );
};
