import { ChangeEvent, Fragment, useCallback, useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { Button, Input, Loader } from "@storybook";
import yaml from "js-yaml";
import { FileUpload } from "components";
import { treeDataState, UrlPrefix } from "states/states";

import { useNotification, useNetwork } from "hooks";

import "./upload-screen.scss";

export const UploadScreen = () => {
  type UploadState = "initial" | "uploading" | "uploaded";

  const [url, setUrl] = useState("");

  const setTreeData = useSetRecoilState(treeDataState);
  const urlPrefix = useRecoilValue(UrlPrefix);
  const [selectedItem, setSelectedItem] = useState<renderTabType>("File");
  const [fileName, setFileName] = useState<string>("");
  const [uploadState, setUploadState] = useState<UploadState>("initial");
  const Notification = useNotification();
  const { post } = useNetwork();
  const navigate = useNavigate();

  const isDisabled = useMemo(() => {
    if (uploadState === "uploaded") {
      return false;
    }
    return true;
  }, [uploadState]);

  const handleFormatConversion = useCallback(
    (fileMetadata: any, fileData: any) => {
      post("/convert/to-tree-format", {
        fileMetadata,
        data: fileData,
      })
        .then((res) => {
          if (res.statusCode === 200) {
            setTreeData((prev) => {
              const newData = JSON.parse(JSON.stringify(prev));
              return {
                ...newData,
                children: [...res.treeStructure],
              };
            });
            setUploadState("uploaded");
            Notification.successNotification("File uploaded successfully");
          } else {
            setUploadState("initial");
            Notification.errorNotification("Fail to upload file. Try Again!");
          }
        })
        .catch((error) => {
          setUploadState("initial");
          Notification.errorNotification("Fail to upload file. Try Again!");
        });
    },
    [Notification, post, setTreeData]
  );

  const handleUploadFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      const validFileTypes: Record<string, string> = {
        "application/yaml": "x-yaml",
        "application/yml": "x-yaml",
        "application/json": "json",
      };

      const fileReader = new FileReader();
      if (e?.target?.files) {
        const selectedFile = e.target.files[0];

        if (!validFileTypes[selectedFile.type as any]) {
          Notification.errorNotification("Invalid file format, please upload valid file format");
          return;
        }
        setUploadState("uploading");
        const fileMetadata = {
          name: e.target.files[0]?.name,
          type: e.target.files[0]?.type,
        };
        setFileName(fileMetadata.name);
        fileReader.readAsText(e?.target?.files[0], "UTF-8");
        fileReader.onload = async (e) => {
          handleFormatConversion(
            fileMetadata,
            fileMetadata?.type === "application/json"
              ? JSON.parse(e?.target?.result as string)
              : yaml.load(e?.target?.result as string)
          );
        };
      }
    },
    [Notification, handleFormatConversion],
  );

  const uploadScreen = useMemo(() => {
    return (
      <div className="file-upload-container">
        <FileUpload
          handleChange={handleUploadFile}
          isUploaded={uploadState}
          fileName={fileName}
        />
      </div>
    );
  }, [fileName, handleUploadFile, uploadState]);

  const handleChangeUrl = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target ?? {};
    setUrl(value);
  };

  const handleFetch = useCallback(() => {
    setUploadState("uploading");
    fetch(url)
      .then((res) => res.json())
      .then((resp) => {
        if (!resp?.collection) {
          throw new Error("Not a postman collection");
        }
        const fileMetadata = {
          type: "application/json",
          name: "abc.json",
        };
        setFileName("Uploaded By URL");
        handleFormatConversion(fileMetadata, resp.collection);
      })
      .catch((er) => {
        console.error(er);
        setUploadState("initial");
        Notification.errorNotification("Not a postman collection");
      });
  }, [Notification, handleFormatConversion, url]);

  const fetchUrl = useMemo(() => {
    return (
      <div className="fetch-url-wrapper">
        <Input
          inputType="text"
          value={url}
          handleChange={handleChangeUrl}
          placeholder="API Specification URL"
          label="Upload from a URL"
        />
        <button
          className="fetch-button"
          onClick={handleFetch}
          style={{
            cursor: url.length === 0 ? "not-allowed" : "pointer",
          }}
          disabled={url.length === 0}
        >
          {uploadState === "uploading" ? (
            <Loader type="circle" className="loader-blue" dimension={16} />
          ) : (
            "Fetch"
          )}
        </button>
      </div>
    );
  }, [handleFetch, url]);

  // const renderCapTable = useMemo(() => {
  //   return (
  //     <div style={{ position: "relative", height: "400px", width: "100%" }}>
  //       <CaptableGrid />
  //     </div>
  //   );
  // }, []);

  type renderTabType = "File" | "Url";

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderTabs = {
    File: uploadScreen,
    Url: fetchUrl,
    // Csv: renderCapTable,
  };
  const renderTabLabels ={
    File: "File",
    Url: "URL",
  }

  const renderAction = useMemo(
    () =>
      Object.keys(renderTabs).map((item: string) => {
        return (
          <Fragment>
            <div
              className={`tab ${selectedItem === item && "active"}`}
              style={{
                width: `calc(100% / ${Object.keys(renderTabs).length})`,
              }}
              onClick={() => setSelectedItem(item as renderTabType)}
            >
             {renderTabLabels[item as renderTabType]}
            </div>
          </Fragment>
        );
      }),

    [renderTabs, selectedItem]
  );

  const renderActionComponent = useMemo(() => {
    return renderTabs[selectedItem];
  }, [renderTabs, selectedItem]);

  return (
    <div className="upload-screen">
      <div className="upload-screen__inner">
        <div className="upload-screen__inner__content">
          <div className="upload-screen__inner__content__label">
            Describe Your API
          </div>
          <div className="upload-screen__inner__content__sub-title">
            First, tell us about your API. You can use an API definition file
            (Open API, Swagger, Csv, Json and Postman) or our manual editor. For
            API definition files, you can set up a GitHub or CLI-based syncing
            workflow or just upload a file the old-fashioned way!
          </div>
        </div>
        <div className="upload-screen__action-wrapper">
          <div className="upload-screen__action-wrapper__inner">
            <div className="action-header-tab">{renderAction}</div>

            {renderActionComponent}
          </div>
        </div>
        <div className="upload-file-wrapper__notice">
          We support <span>Open API 3.0, Open API 3.1, Swagger 2, </span>
          and <span>Postman</span> API formats.
        </div>
      </div>
      <div className="upload-button-wrapper">
        <Button
          label="Back"
          handleClick={() =>
            navigate(urlPrefix ? `../${urlPrefix}/describe` : "../describe")
          }
          type="adf-button__filled adf-button__filled--secondary adf-button__large Upload-btn"
        />
       <Button
          label="Next"
          handleClick={() =>
            navigate(urlPrefix ? `../${urlPrefix}/api-editor` : "../api-editor")
          }
          type="adf-button__filled adf-button__filled--primary adf-button__large Next-button"
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};
