import { IButton } from ".";

import { FC } from "react";

import "./button.scss";

export const Button: FC<IButton> = ({
  label,
  handleClick,
  loader,
  type,
  icon,
  height,
  disabled,
}) => {
  return (
    <button
      className={`adf-button ${type} ${disabled ? "adf-button--disabled" : ""}`}
      onClick={handleClick}
      style={{ height }}
      disabled={disabled}
    >
      <>{label}</>
      {loader}
      {icon && <i className={icon} />}
    </button>
  );
};
