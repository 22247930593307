import { Button, Input, Loader, TextArea } from "@storybook";
import { ChangeEvent, Fragment, useCallback, useMemo, useState } from "react";

import { INPUTS_META_DATA } from "./constant";
import { useRecoilValue } from "recoil";
import { entityName, treeDataState } from "states/states";

import "./api-settings.scss";

export const APISettings = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const treeData = useRecoilValue(treeDataState);
  const entity = useRecoilValue(entityName);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDisabled = useCallback(() => setIsDisabled((prev) => !prev), []);

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;
    },
    []
  );

  type ITreeDataRootType =
    | "name"
    | "version"
    | "description"
    | "label"
    | "format";

  const getValue = useCallback(
    (name: ITreeDataRootType) => {
      if (name === "label") {
        return treeData[name].join(",");
      }
      if (name === "format") {
        return "Open API v3";
      }
      return treeData[name];
    },
    [treeData]
  );
  const renderSettings = useCallback(
    (input: any) => {
      let { type, label, placeholder, name } = input ?? {};
      placeholder = placeholder === "Satschel" ? entity : placeholder;
      switch (type) {
        case "text":
          return (
            <Input
              inputType={type}
              placeholder={placeholder}
              inputName={name}
              label={label}
              value={getValue(name)}
              handleChange={(event) => handleOnChange(event)}
              disabled={isDisabled}
            />
          );
        case "textarea":
          return (
            <TextArea
              placeholder={placeholder}
              label={label}
              row={10}
              value={getValue(name)}
              handleChange={(event) => handleOnChange(event)}
              disabled={isDisabled}
            />
          );
      }
    },
    [entity, getValue, handleOnChange, isDisabled]
  );

  const renderInput = useMemo(
    () =>
      INPUTS_META_DATA.map((item, index) => (
        <Fragment key={index + item.label}>{renderSettings(item)}</Fragment>
      )),

    [renderSettings]
  );

  return (
    <div className="api-setting">
      {/* <div className="api-setting__inner"> */}
      {/* <div className="api-setting__inner__header"> */}
      {/* <div className="api-setting__inner__header__title">
            Confirm your API Settings
          </div> */}
      {/* <div
            className="api-setting__inner__header__edit-icon"
            onClick={handleDisabled}
          >
            <i className="ri-pencil-fill"></i>
          </div> */}
      {/* </div> */}
      {/* <div className="api-setting__inner__inputs">{renderInput}</div> */}
      {!isDisabled && (
        <div className="api-setting__inner__btn-wrapper">
          <Button
            label=""
            handleClick={() => {}}
            type="adf-button__filled adf-button__filled--secondary adf-button__large  settting-btn"
            loader={
              false ? (
                <Loader type="circle" className="loader-blue" dimension={20} />
              ) : (
                <>Cancel</>
              )
            }
          />
          <Button
            label=""
            handleClick={() => {}}
            type="adf-button__filled adf-button__filled--primary  adf-button__large  action-btn"
            loader={
              false ? (
                <Loader type="circle" className="loader-blue" dimension={20} />
              ) : (
                <>Save</>
              )
            }
          />
        </div>
      )}
      {/* </div> */}
    </div>
  );
};
