import { Button } from "@storybook";
import "./modal.scss";

export const ConfirmationModal = ({ isOpen, onConfirm, onCancel, jsx, buttonTexts }: any) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="confirmation-modal">
      <div className="confirmation-modal-content">
        <div className="close-modal" onClick={onCancel}>
          &#x2716;
        </div>
        {jsx}
        <div className="confirmation-modal-buttons">
          <div className="modal-btn-container">
            {buttonTexts.map((buttonField: string) => {
              return (
                <Button
                  key={buttonField}
                  label={buttonField}
                  handleClick={() => {
                    onConfirm(buttonField.toLocaleLowerCase());
                  }}
                  type="adf-button__filled button__filled--secondary adf-button__large action-btn"
                />
              );
            })}
            {/* <Button
              label={buttonTexts[0]}
              handleClick={() => {
                onConfirm("append");
              }}
              type="adf-button__filled button__filled--secondary adf-button__large action-btn"
            />
            <Button
              label={buttonTexts[1]}
              handleClick={() => {
                onConfirm("replace");
              }}
              type="adf-button__filled adf-button__filled--secondary adf-button__large action-btn"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export const DeleteModal = ({ isOpen, onConfirm, onCancel }: any) => {
  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };
  if (!isOpen) {
    return null;
  }

  return (
    <div className="confirmation-modal" onClick={handleModalClick}>
      <div className="confirmation-modal-content">
        <div
          className="close-modal"
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}>
          &#x2716;
        </div>
        <div>
          <h3>Confirm Deletion</h3>
          <p>Are you sure you want to delete this item?</p>
          <p>This action is irreversible and the data will be permanently removed.</p>
        </div>
        <div className="confirmation-modal-buttons">
          <div className="modal-btn-container">
            <Button
              label=" Cancel"
              handleClick={(e: any) => {
                e.stopPropagation();
                onCancel();
              }}
              type="adf-button__filled button__filled--secondary adf-button__large action-btn"
            />
            <Button
              label="Delete"
              handleClick={(e: any) => {
                e.stopPropagation();
                onConfirm();
              }}
              type="adf-button__filled adf-button__filled--danger adf-button__large action-btn"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const SummaryModal = ({ isOpen, onConfirm }: any) => {
  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };
  if (!isOpen) {
    return null;
  }

  return (
    <div className="confirmation-modal" onClick={handleModalClick}>
      <div className="confirmation-modal-content">
        <div
          className="close-modal"
          onClick={(e) => {
            e.stopPropagation();
            onConfirm();
          }}>
          &#x2716;
        </div>
        <div>
          <h3>Summary</h3>
          <p>Please complete your endpoints summary to proceed.</p>
        </div>
        <div className="confirmation-modal-buttons">
          <div className="modal-btn-container">
            <Button
              label="OK"
              handleClick={(e: any) => {
                e.stopPropagation();
                onConfirm();
              }}
              type="adf-button__filled adf-button__filled--danger adf-button__large action-btn"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
