import { css } from 'lit';

/*
This file is reserved for any custom css that developers want to add to
customize their theme. Simply add your css to this file and yarn build.
*/

export default css`
    .nav-method{
        text-align: center;
    }
    .hidden{
        display: none;
    }
    .tab-title.arrow::after{
        transform: translate(-50%, -50%) rotate(-45deg);
        content: "";
        position: absolute;
        top: 60%;
        border-bottom: 2px solid black;
        border-right: 2px solid black;
        background: white;
    }
    .tab-title{
        cursor: pointer;
        position: relative;
        display: inline-block;
    }
    .tab-title::after{
        content: "";
        position: absolute;
        top: 40%;
        left: 100%;
        margin-left: 10px;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 8px;
        height: 8px;
        border-bottom: 2px solid black;
        border-right: 2px solid black;
        background: white;
    }
    .tab-buttons{
        height:100%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .tab-content pre code{
      white-space: pre-wrap;
    }
`;
