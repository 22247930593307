import { useCallback } from "react";
import { RecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { currentNode, isRootNodeSelected, nextBtnClickedState, selectedItemState, treeDataState } from "states/states";

export const useNodeTree = () => {
  const setSelectedNode = useSetRecoilState(currentNode);
  const setIsRootNode = useSetRecoilState(isRootNodeSelected);
  const selectedNode = useRecoilValue(currentNode);
  const setSelectedItem = useSetRecoilState(selectedItemState);
  const setNextBtnClicked = useSetRecoilState(nextBtnClickedState);


  const treeData = useRecoilValue(treeDataState);

  const getNodeById = useCallback((json: any, id: any) => {
    if (json.id === id) {
      return json;
    } else if (json.children) {
      for (let i = 0; i < json.children.length; i++) {
        const modifiedChild: any = getNodeById(json.children[i], id);
        if (modifiedChild) {
          return modifiedChild;
        }
      }
    }
    return null;
  }, []);

  const removeTreeNode = useCallback((node: any, id: any) => {


    setNextBtnClicked(false);

    if (selectedNode?.version) {
      setSelectedItem("General");
    }
    else {
      setSelectedItem("");
    }


    if (node.id === id) {
      node.type = "string";
      node.children = [];
      return;
    }
    if (node.children) {
      for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i];
        if (child.id === id) {
          node.children.splice(i, 1);
          if (!node.children.length && !node?.isSubPath) {
            node.type = "string";
          }
          const payload = {
            parentNode: getNodeById(treeData, node?.parent),
            currentNode: node,
          };
          setSelectedNode(payload);
          return;
        } else {
          removeTreeNode(child, id);
        }
      }
    }
  }, []);

  const getName = useCallback(
    (data: any) => {
      const structuredValues = data.children
        ?.filter((item: any) => {
          const newValue = item?.name?.split("_");
          return newValue?.length === 2 && !isNaN(Number(newValue[1]));
        })
        .map((item: any) => item?.name);
      if (data.id === treeData.id)
        return `resource_${
          Number(
            structuredValues.length > 0
              ? structuredValues[structuredValues.length - 1]?.split("_")?.[1]
              : 0
          ) + 1
        }`;
      return `Attribute_${
        Number(
          structuredValues.length > 0
            ? structuredValues[structuredValues.length - 1]?.split("_")?.[1]
            : 0
        ) + 1
      }`;
    },
    [treeData]
  );

  const addTreeNode = useCallback(
    (json: any, id: any) => {
      if (json.id === id) {
        if (!json.children) {
          json.children = [];
        }

        setNextBtnClicked(false);

        if (selectedNode?.version) {
          setSelectedItem("General");
        }
        else {
          setSelectedItem("");
        }

        const childrenPayload = {
          id: Math.floor(Date.now() * Math.random()),
          name: getName(json),
          parent: id,
          branchColor: "#FCB900",
          legendId: "default",
          description: "",
          type: json.id === treeData.id ? "object" : "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: json.id === treeData.id ? true : false,
          inDocumention: true,
          queryable: false,
        };
        json.type = "object";
        json.children.push(childrenPayload);

        const data = getNodeById(json, id);
        const payload = {
          parentNode: data,
          currentNode: childrenPayload,
        };
        setSelectedNode(payload);
        setIsRootNode(false);
        return { ...json };
      } else if (json.children) {
        for (let i = 0; i < json.children.length; i++) {
          const modifiedChild = addTreeNode(json.children[i], id);
          if (modifiedChild) {
            json.children[i] = modifiedChild;
            return { ...json };
          }
        }
      }
      return null;
    },
    [getName, getNodeById, setIsRootNode, setSelectedNode, treeData]
  );

  const addMultipleTreeNode = useCallback(
    (json: any, id: any, nodes: any[], method: string) => {
      if (json.id === id) {
        if (!json.children) {
          json.children = [];
        }
        json.type = "object";
        const newChildrenArra =
          method === "append" ? [...json.children, ...nodes] : nodes;
        json.children = newChildrenArra.reduce((acc, cur) => {
          if (!acc.find((elem: any) => elem.name === cur.name)) {
            acc.push(cur);
          }
          return acc;
        }, []);
        return { ...json };
      } else if (json.children) {
        for (let i = 0; i < json.children.length; i++) {
          const modifiedChild = addMultipleTreeNode(
            { ...json.children[i] },
            id,
            nodes,
            method
          );
          if (modifiedChild) {
            json.children[i] = modifiedChild;
            return { ...json };
          }
        }
      }
    },
    []
  );

  const getResourceNode = useCallback(
    (json: any, id: any): any => {
      const currentNode = getNodeById(json, id);
      if (currentNode?.isSubPath) return currentNode;
      if (currentNode) {
        return getResourceNode(json, currentNode?.parent);
      }
    },
    [getNodeById]
  );

  const getNodeIDS = useCallback((node: any, ids: any[]) => {
    ids.push(node.id); // add the current node's ID to the array

    if (node.children) {
      // if the node has children
      for (let i = 0; i < node.children.length; i++) {
        getNodeIDS(node.children[i], ids); // recursively traverse each child node
      }
    }

    return ids;
  }, []);

  const extractRelationData = useCallback((treeData: any) => {
    const relationData: any = [];

    function traverseTree(node: any, parentResource: any) {
      if (node.isRelation) {
        const relationObj = {
          id: node.id,
          populate: node.relation.populate,
          linkedAttributeFrom: node.name,
          linkedResourceFrom: parentResource.name,
          linkedAttributeTo: node.relation.key,
          linkedResourceTo: node.relation.ref,
        };
        relationData.push(relationObj);
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach((child: any) => traverseTree(child, node));
      }
    }

    traverseTree(treeData, treeData);

    return relationData;
  }, []);

  return {
    removeTreeNode,
    addTreeNode,
    getNodeById,
    getNodeIDS,
    extractRelationData,
    addMultipleTreeNode,
    getResourceNode,
  };
};
