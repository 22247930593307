const id = Math.floor(Date.now() * Math.random());

export const defaultTreeData = {
  name: "",
  api_url: "",
  id: id,
  description: "",
  type: "list",
  version: "1.0",
  label: [],
  children: [
    {
      id: 2,
      name: "resource_1",
      parent: id,
      branchColor: "#FCB900",
      legendId: "default",
      description: "",
      type: "object",
      pageable: false,
      queryParams: [],
      crud: ["CREATE", "READ", "UPDATE", "DELETE"],
      enumValues: [],
      isRelation: false,
      relation: {},
      children: [],
      searchEnabled: true,
      patchEnabled: false,
      visibility: "READ_WRITE",
      required: false,
      xDescription: "",
      abstract: true,
      isArray: true,
      isSubPath: true,
      inDocumention: true,
      queryable: false,
    },
    {
      id: 3,
      name: "resource_2",
      parent: id,
      branchColor: "#FCB900",
      legendId: "default",
      description: "",
      type: "object",
      pageable: false,
      children: [],
      queryParams: [],
      crud: ["CREATE", "READ", "UPDATE", "DELETE"],
      enumValues: [],
      isRelation: false,
      relation: {},
      searchEnabled: true,
      patchEnabled: false,
      visibility: "READ_WRITE",
      required: false,
      xDescription: "",
      abstract: true,
      isArray: true,
      isSubPath: true,
      inDocumention: false,
    },
  ],
};

export const unSecureddefaultTreeData = {
  name: "",
  api_url: "",
  id: id,
  description: "",
  type: "list",
  version: "1.0",
  label: [],
  children: [
    {
      id: 2,
      name: "loan-payments",
      parent: id,
      branchColor: "#FCB900",
      legendId: "default",
      description: "",
      type: "object",
      pageable: false,
      children: [
        {
          id: 798567374491,
          name: "loan_type",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "answer could be ['ALL','unsecured_consumer', 'unsecured_auto', ..... there will be many types of these]",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 605355693486,
          name: "loan_number",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "The loan_number if the Loan has already been completed funded/originated",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 523171008577,
          name: "loan_pool_id",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 881101394438,
          name: "transaction_id",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Unique id associated with this loan payment transaction.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 917383341369,
          name: "funds_available_date",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Date and time this loan payment’s funds are available in your account.",
          type: "date",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 656635592417,
          name: "investor_disbursement_date",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Date and time this loan payment’s funds are disbursed to the investor.",
          type: "date",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1568821521055,
          name: "transaction_effective_date",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Date and time this loan payment transaction is determined.",
          type: "date",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 87308557447,
          name: "account_effective_date",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Date and time this loan payment appears within your Prosper account.",
          type: "date",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1571398891294,
          name: "payment_transaction_code",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "ACH – Automated clearing house\nBD – Bank draft\nCAP – Collection agency\nDSP – Dispute\nNSF – Insufficient funds\nBDF – Bank draft return\nACHF – ACH Failure\nCAPF – Collection Agency Payment Failure\nSEQR – Payment reversal due to the need to reverse a prior payment\nINTR – Interest reversal\nCRINT – Interest reversal\nCK – Check payment\nCKF – Check payment reversal\nLFA – Late Fee Adjustment\nNSFA – NSF Adjustment\nLFA2 – Late Fee Adjustment without Cash Disbursement\nNSFA2 – NSF Adjustment without Cash Disbursement\nPROMO – A loan payment made on behalf of the borrower as part of a promotional program\nPROMF – A PROMO reversal\nCRCRE – A loan payment made on behalf of the borrower as a customer relations credit\nCRCRF – A CRCRE reversal\nSIFA – Settled in full credit, or loan payment made to zero out the balance of a loan. Does not result in a cash disbursement to investors.\nSIFAR – A SIFA reversal.\nUNWND – Flat cancel a loan\nACHF2 – ACH Failure with no NSF\nCKF2 – Check Failure with no NSF\nCRCF2 – CRCRE Failure with no NSF\nCAPF2 – Collection agency payment Failure with no NSF\nPRMF2 – PROMO reversal with no NSF\nSIFR2 – A SIFA reversal with no NSF\nDBSA – Debt Sale transaction made to zero out the balance of a loan. Does not result in a cash disbursement to investors\nDBSAF – Debt Sale Failure\nPMT – Catch all generic payment\nPMTF – Catch all generic payment Failure\nPMTF2 – Catch all generic payment with no NSF\nBDF2 – Bank draft return with no NSF",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [
            "ACH",
            "BD",
            "CAP",
            "DSP",
            "NSF",
            "BDF",
            "ACHF",
            "CAPF",
            "SEQR",
          ],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1390208229898,
          name: "payment_status",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Process – Payment is within ACH window and is in-flight\nSuccess – Payment is successful\nFail – Payment failed\nDispute – Payment disputed",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 178539693045,
          name: "match_back_id",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description: "Unique identifier for the payment transaction.",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 946369015639,
          name: "prior_match_back_id",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "For a reversal/failure of a payment, this identifier will\nmap back to the match_back_id.",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1177684718005,
          name: "loan_payment_cashflow_type",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Payment – Standard loan payment by the borrower\nAdjustment – Payment adjustment, likely due to payment re-application\nChargeback – Payment chargeback\nDebtSale – Proceeds from loan debt sale\nOther",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1320840721006,
          name: "payment_amount",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "The total amount of this loan payment applied to the investor’s account.\nThis amount is the sum of:\nprincipal_amount\ninterest_amount\nlate_fee_amount\nnsf_fee_amount\nThis amount does not include:\nservice_fee_amount\ncollection fee_amount",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 168197965924,
          name: "principal_amount",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "The amount of this loan payment applied to the loan’s principal.\nThis is part of the payment applied to the investor’s account.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 585036618333,
          name: "interest_amount",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "The amount of interest applied to this loan payment amount.\nThis is part of the payment applied to the investor’s account.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 783579344115,
          name: "origination_interest_amount",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description: "This will always return 0.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 998965777452,
          name: "late_fee_amount",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "The late fees (if any) applied to this loan payment.\nThis is part of the payment applied to the investor’s account.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 389164347873,
          name: "service_fee_amount",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "The service fees (if any) applied to this loan payment amount.\nThis part of the payment is collected by Prosper for servicing the loan.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 836573908807,
          name: "collection_fee_amount",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "The collection fees (if any) charged to collect a loan that has gone into collections.\nThis part of the payment is paid to the third-party collection agency that collects on a defaulted loan.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 97320316292,
          name: "gl_reward_amount",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description: "This element has been deprecated.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 238206898399,
          name: "nsf_fee_amount",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "The fee charged for insufficient funds in the loan payment funding account at time of the loan payment.\nThis is part of the payment applied to the investor’s account.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1187859591103,
          name: "pre_days_past_due",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "The days past due on this loan payment prior to applying the payment.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 364555440693,
          name: "post_days_past_due",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "The days past due on this loan payment after applying the late payment.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 687444443018,
          name: "resulting_principal_balance",
          parent: 2,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "The amount of principal remaining on the loan once this loan payment has been applied to the loan.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
      ],
      queryParams: [],
      crud: ["CREATE", "READ", "UPDATE", "DELETE"],
      enumValues: [],
      isRelation: false,
      relation: {},
      searchEnabled: true,
      patchEnabled: false,
      visibility: "READ_WRITE",
      required: false,
      xDescription: "",
      abstract: true,
      isArray: false,
      isSubPath: true,
      inDocumention: true,
      queryable: false,
    },
    {
      id: 3,
      name: "open-orders",
      parent: id,
      branchColor: "#FCB900",
      legendId: "default",
      description: "",
      type: "object",
      pageable: false,
      children: [
        {
          id: 173160317372,
          name: "order_id",
          parent: 3,
          branchColor: "#FCB900",
          legendId: "default",
          description: "A unique generated id for the order.",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1440593470757,
          name: "order_date",
          parent: 3,
          branchColor: "#FCB900",
          legendId: "default",
          description: "The date the order was created.",
          type: "date",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1329570554967,
          name: "order_status",
          parent: 3,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Status of order. Can be one of the following:\nIN_PROGRESS\nCOMPLETED",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1064460955890,
          name: "loan_type",
          parent: 3,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "answer could be ['ALL','unsecured_consumer', 'unsecured_auto', ..... there will be many types of these]",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 111392808394,
          name: "loan_number",
          parent: 3,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "The loan_number if the Loan has already been completed funded/originated",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1198589383131,
          name: "loan_pool_number",
          parent: 3,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1316132246009,
          name: "listing_type",
          parent: 3,
          branchColor: "#FCB900",
          legendId: "default",
          description: "['new_listing','already_originated']",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 143295843579,
          name: "bid_amount",
          parent: 3,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Amount to bid on this listing. For a partial loan listing that is less than 24 hours old, you cannot bid more than 10% of the loan total. After 24 hours, you can bid any amount of the loan listing total.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 528281677476,
          name: "source",
          parent: 3,
          branchColor: "#FCB900",
          legendId: "default",
          description: "The originating system that created the order.",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
      ],
      queryParams: [],
      crud: ["CREATE", "READ", "UPDATE", "DELETE"],
      enumValues: [],
      isRelation: false,
      relation: {},
      searchEnabled: true,
      patchEnabled: false,
      visibility: "READ_WRITE",
      required: false,
      xDescription: "",
      abstract: true,
      isArray: true,
      isSubPath: true,
      inDocumention: false,
    },
    {
      id: 4,
      name: "loans",
      parent: id,
      branchColor: "#FCB900",
      legendId: "default",
      description: "",
      type: "object",
      pageable: false,
      children: [
        {
          id: 98375784090,
          name: "loan_type",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 711208746202,
          name: "loan_number",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        // {
        //   id: 525115473855,
        //   name: "loan_pool_id",
        //   parent: 4,
        //   branchColor: "#FCB900",
        //   legendId: "default",
        //   description:
        //     "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
        //   type: "string",
        //   pageable: false,
        //   children: [],
        //   isRelation: false,
        //   relation: {},
        //   queryParams: [],
        //   crud: ["CREATE", "READ", "UPDATE", "DELETE"],
        //   enumValues: [],
        //   searchEnabled: true,
        //   patchEnabled: false,
        //   visibility: "READ_WRITE",
        //   required: false,
        //   xDescription: "",
        //   abstract: true,
        //   isArray: false,
        //   isSubPath: false,
        //   inDocumention: true,
        //   queryable: false,
        // },
        {
          id: 1595756066223,
          name: "age_in_months",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1328977316319,
          name: "amount_borrowed",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1513440683433,
          name: "borrower_rate",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1056792709934,
          name: "days_past_due",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1456013445917,
          name: "collection_fees_paid",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1082377160757,
          name: "debt_sale_proceeds_received",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 718677836607,
          name: "interest_paid",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 606863296680,
          name: "late_fees_paid",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        // {
        //   id: 472681969830,
        //   name: "loan_ownership",
        //   parent: 4,
        //   branchColor: "#FCB900",
        //   legendId: "default",
        //   description:
        //     "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
        //   type: "boolean",
        //   pageable: false,
        //   children: [],
        //   isRelation: false,
        //   relation: {},
        //   queryParams: [],
        //   crud: ["CREATE", "READ", "UPDATE", "DELETE"],
        //   enumValues: [],
        //   searchEnabled: true,
        //   patchEnabled: false,
        //   visibility: "READ_WRITE",
        //   required: false,
        //   xDescription: "",
        //   abstract: true,
        //   isArray: false,
        //   isSubPath: false,
        //   inDocumention: true,
        //   queryable: false,
        // },
        // {
        //   id: 290008304179,
        //   name: "loan_ownership_amount",
        //   parent: 4,
        //   branchColor: "#FCB900",
        //   legendId: "default",
        //   description:
        //     "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
        //   type: "number",
        //   pageable: false,
        //   children: [],
        //   isRelation: false,
        //   relation: {},
        //   queryParams: [],
        //   crud: ["CREATE", "READ", "UPDATE", "DELETE"],
        //   enumValues: [],
        //   searchEnabled: true,
        //   patchEnabled: false,
        //   visibility: "READ_WRITE",
        //   required: false,
        //   xDescription: "",
        //   abstract: true,
        //   isArray: false,
        //   isSubPath: false,
        //   inDocumention: true,
        //   queryable: false,
        // },
        {
          id: 287109430886,
          name: "loan_status",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 460049860131,
          name: "loan_status_description",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1085812071844,
          name: "next_payment_due_date",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "date",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1592867875674,
          name: "next_payment_due_amount",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1023597951561,
          name: "origination_date",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "date",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 171828297520,
          name: "principal_balance",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 349867007841,
          name: "principal_paid",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        // {
        //   id: 516445972325,
        //   name: "origination_fees_paid",
        //   parent: 4,
        //   branchColor: "#FCB900",
        //   legendId: "default",
        //   description:
        //     "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
        //   type: "number",
        //   pageable: false,
        //   children: [],
        //   isRelation: false,
        //   relation: {},
        //   queryParams: [],
        //   crud: ["CREATE", "READ", "UPDATE", "DELETE"],
        //   enumValues: [],
        //   searchEnabled: true,
        //   patchEnabled: false,
        //   visibility: "READ_WRITE",
        //   required: false,
        //   xDescription: "",
        //   abstract: true,
        //   isArray: false,
        //   isSubPath: false,
        //   inDocumention: true,
        //   queryable: false,
        // },
        {
          id: 1550999006251,
          name: "service_fees_paid",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 908334117789,
          name: "term",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        // {
        //   id: 22361921678,
        //   name: "loan_default_reason",
        //   parent: 4,
        //   branchColor: "#FCB900",
        //   legendId: "default",
        //   description:
        //     "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
        //   type: "number",
        //   pageable: false,
        //   children: [],
        //   isRelation: false,
        //   relation: {},
        //   queryParams: [],
        //   crud: ["CREATE", "READ", "UPDATE", "DELETE"],
        //   enumValues: [],
        //   searchEnabled: true,
        //   patchEnabled: false,
        //   visibility: "READ_WRITE",
        //   required: false,
        //   xDescription: "",
        //   abstract: true,
        //   isArray: false,
        //   isSubPath: false,
        //   inDocumention: true,
        //   queryable: false,
        // },
        // {
        //   id: 875809304199,
        //   name: "loan_default_reason_description",
        //   parent: 4,
        //   branchColor: "#FCB900",
        //   legendId: "default",
        //   description:
        //     "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
        //   type: "string",
        //   pageable: false,
        //   children: [],
        //   isRelation: false,
        //   relation: {},
        //   queryParams: [],
        //   crud: ["CREATE", "READ", "UPDATE", "DELETE"],
        //   enumValues: [],
        //   searchEnabled: true,
        //   patchEnabled: false,
        //   visibility: "READ_WRITE",
        //   required: false,
        //   xDescription: "",
        //   abstract: true,
        //   isArray: false,
        //   isSubPath: false,
        //   inDocumention: true,
        //   queryable: false,
        // },
        {
          id: 1090880263794,
          name: "debt_sale_proceeds_received",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1174094593395,
          name: "interest_paid",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 537473371337,
          name: "principal_paid",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1591458580665,
          name: "late_fees_paid",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        // {
        //   id: 1290981535941,
        //   name: "non_service_fees_paid_pro_rata_share",
        //   parent: 4,
        //   branchColor: "#FCB900",
        //   legendId: "default",
        //   description:
        //     "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
        //   type: "number",
        //   pageable: false,
        //   children: [],
        //   isRelation: false,
        //   relation: {},
        //   queryParams: [],
        //   crud: ["CREATE", "READ", "UPDATE", "DELETE"],
        //   enumValues: [],
        //   searchEnabled: true,
        //   patchEnabled: false,
        //   visibility: "READ_WRITE",
        //   required: false,
        //   xDescription: "",
        //   abstract: true,
        //   isArray: false,
        //   isSubPath: false,
        //   inDocumention: true,
        //   queryable: false,
        // },
        {
          id: 1298209376683,
          name: "service_fees_paid",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1658111532691,
          name: "next_payment_due_amount",
          parent: 4,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        // {
        //   id: 1213293695596,
        //   name: "is_sold",
        //   parent: 4,
        //   branchColor: "#FCB900",
        //   legendId: "default",
        //   description:
        //     "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
        //   type: "boolean",
        //   pageable: false,
        //   children: [],
        //   isRelation: false,
        //   relation: {},
        //   queryParams: [],
        //   crud: ["CREATE", "READ", "UPDATE", "DELETE"],
        //   enumValues: [],
        //   searchEnabled: true,
        //   patchEnabled: false,
        //   visibility: "READ_WRITE",
        //   required: false,
        //   xDescription: "",
        //   abstract: true,
        //   isArray: false,
        //   isSubPath: false,
        //   inDocumention: true,
        //   queryable: false,
        // },
        // {
        //   id: 98524050168,
        //   name: "loan_sale_fees_paid",
        //   parent: 4,
        //   branchColor: "#FCB900",
        //   legendId: "default",
        //   description:
        //     "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
        //   type: "number",
        //   pageable: false,
        //   children: [],
        //   isRelation: false,
        //   relation: {},
        //   queryParams: [],
        //   crud: ["CREATE", "READ", "UPDATE", "DELETE"],
        //   enumValues: [],
        //   searchEnabled: true,
        //   patchEnabled: false,
        //   visibility: "READ_WRITE",
        //   required: false,
        //   xDescription: "",
        //   abstract: true,
        //   isArray: false,
        //   isSubPath: false,
        //   inDocumention: true,
        //   queryable: false,
        // },
        // {
        //   id: 952499781962,
        //   name: "loan_sale_gross_amount_received",
        //   parent: 4,
        //   branchColor: "#FCB900",
        //   legendId: "default",
        //   description:
        //     "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
        //   type: "number",
        //   pageable: false,
        //   children: [],
        //   isRelation: false,
        //   relation: {},
        //   queryParams: [],
        //   crud: ["CREATE", "READ", "UPDATE", "DELETE"],
        //   enumValues: [],
        //   searchEnabled: true,
        //   patchEnabled: false,
        //   visibility: "READ_WRITE",
        //   required: false,
        //   xDescription: "",
        //   abstract: true,
        //   isArray: false,
        //   isSubPath: false,
        //   inDocumention: true,
        //   queryable: false,
        // },
        // {
        //   id: 270241340864,
        //   name: "loan_sale_net_amount_received",
        //   parent: 4,
        //   branchColor: "#FCB900",
        //   legendId: "default",
        //   description:
        //     "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
        //   type: "number",
        //   pageable: false,
        //   children: [],
        //   isRelation: false,
        //   relation: {},
        //   queryParams: [],
        //   crud: ["CREATE", "READ", "UPDATE", "DELETE"],
        //   enumValues: [],
        //   searchEnabled: true,
        //   patchEnabled: false,
        //   visibility: "READ_WRITE",
        //   required: false,
        //   xDescription: "",
        //   abstract: true,
        //   isArray: false,
        //   isSubPath: false,
        //   inDocumention: true,
        //   queryable: false,
        // },
      ],
      queryParams: [],
      crud: ["CREATE", "READ", "UPDATE", "DELETE"],
      enumValues: [],
      isRelation: false,
      relation: {},
      searchEnabled: true,
      patchEnabled: false,
      visibility: "READ_WRITE",
      required: false,
      xDescription: "",
      abstract: true,
      isArray: true,
      isSubPath: true,
      inDocumention: false,
    },
    {
      id: 5,
      name: "account",
      parent: id,
      branchColor: "#FCB900",
      legendId: "default",
      description: "",
      type: "object",
      pageable: false,
      children: [
        {
          id: 1122471731753,
          name: "account_number",
          parent: 5,
          branchColor: "#FCB900",
          legendId: "default",
          description: "A unique id associated with the account",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1423625371901,
          name: "available_cash_balance",
          parent: 5,
          branchColor: "#FCB900",
          legendId: "default",
          description: "Current cash balance",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 668293267656,
          name: "pending_investments_primary_market_sum",
          parent: 5,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Total Amount of Dollars pending in the Primary Issuance of a New Loan",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 447335051647,
          name: "pending_investments_primary_market_count",
          parent: 5,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Total Amount of Loans pending in the Primary Issuance of a New Loan",
          type: "",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1034489700365,
          name: "pending_investments_secondary_market_sum",
          parent: 5,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Total Sum of Dollars pending in an already existing Loan",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 616787984950,
          name: "pending_investments_secondary_market_count",
          parent: 5,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Total count of Orders pending in an already existing Loan",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1434527426644,
          name: "total_principal_received_on_loans",
          parent: 5,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Total principal payments received on currently active Loans",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1215286770259,
          name: "total_amount_invested_in_loans",
          parent: 5,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Total amount invested in Notes on the Liquidity.io platform through both the primary and secondary markets.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1233834652470,
          name: "outstanding_principal_on_all_loans",
          parent: 5,
          branchColor: "#FCB900",
          legendId: "default",
          description: "outstanding_principal_on_active_notes",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1086096210996,
          name: "total_account_value",
          parent: 5,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Total dollars held in the following combined:\nAvailable cash balance\n+ pending investments in the Primary market\n+ pending investments in the Secondary market\n+ (total amount invested on active Notes\n– total principal received on active Notes)\n– pending quick invest orders",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 504095577854,
          name: "inflight_gross",
          parent: 5,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Total loan payment amount that borrowers have paid that are in-flight in the following combined:\nPrincipal\n+ interest\n+ insufficient funds fees\n+ late Fees\nThe total above also includes any Liquidity.io processing fees that will be charged to an investor.\nThere may be times when a payment that is already deposited to the investor account can be reversed. In cases when this happens, the inflight_gross value may be negative if the amount to be reversed is greater than the total inflight_gross to be paid.\nNote: By default, the inflight_gross element and its value are not returned by a GET <liquidity_base_address>/accounts/liquidity/ API call, as excluding it will provide significant performance improvement for your API client.\nIf you need to view the value of this element, add the suppress_in_flight_gross query parameter to the GET call with a value of false.\nExample: GET <liquidity_base_address> /accounts/liquidity/?suppress_in_flight_gross=false\nIf there is no in-flight payment, this element will not be returned.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 300897634763,
          name: "last_deposit_amount",
          parent: 5,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "The total amount of your last deposit to your Liquidity.io account.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1606740563519,
          name: "last_deposit_date",
          parent: 5,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "The date of your last deposit to your Liquidity.io account.",
          type: "date",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 622591626447,
          name: "last_withdraw_amount",
          parent: 5,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "The total amount of your last withdrawal from your Liquidity.io account.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 287540480474,
          name: "last_withdraw_date",
          parent: 5,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "The date of your last withdrawal from your Liquidity.io account.",
          type: "date",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1462285054063,
          name: "number_pending_bids",
          parent: 5,
          branchColor: "#FCB900",
          legendId: "default",
          description: "An object with the number of loans with bids pending",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
      ],
      queryParams: [],
      crud: ["CREATE", "READ", "UPDATE", "DELETE"],
      enumValues: [],
      isRelation: false,
      relation: {},
      searchEnabled: true,
      patchEnabled: false,
      visibility: "READ_WRITE",
      required: false,
      xDescription: "",
      abstract: true,
      isArray: true,
      isSubPath: true,
      inDocumention: false,
    },
    {
      id: 6,
      name: "loans_for_sales",
      parent: id,
      branchColor: "#FCB900",
      legendId: "default",
      description: "",
      type: "object",
      pageable: false,
      children: [
        {
          id: 505440471130,
          name: "loan_type",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "answer could be ['ALL','unsecured_consumer', 'unsecured_auto', ..... there will be many types of these]",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 591690922230,
          name: "loan_number",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "The loan_number if the Loan has already been completed funded/originated",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 332966028974,
          name: "loan_pool_id",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "If the loans are only being sold as a pool of many loans, liquidity.io makes pool number to associate it together",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 66300675208,
          name: "listing_type",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Is the loan for a sale new and not originated yet (new_listing), or is the loan already originated\n['new_listing','already_originated'] ",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1398996935415,
          name: "include_credit_bureau_values",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Possible values:\nexperian\ntransunion\nexperian,transunion\nThe default value is null.\nAdd this parameter to your request if you want to see TransUnion or Experian credit bureau underwriting values in the search response.\nBy default, the listings API returns a subset of indexed TransUnion credit bureau underwriting values in a response.\nNote: Use this parameter judiciously. Including credit bureau underwriting values can increase response time dramatically, especially when returning a large set of listings. If you do not need the credit bureau underwriting values, it is best not to request them.\nDue to the large set of credit bureau underwriting values that are returned within a GET listings response, those values are documented on separate pages:\nTransUnion credit bureau values\nExperian credit bureau values",
          type: "text",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1035849465300,
          name: "invested",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Use the invested parameter to retrieve listings that you have invested in, or to exclude listings you have invested in.\nPossible values:\ntrue\nfalse",
          type: "boolean",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1234884873415,
          name: "fractional_or_whole",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Is the investor required to bid on the whole individual loan or pool of loans, or can they invest in fractional ownership\nPossible values:\ntrue\nfalse\n",
          type: "boolean",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 147719604834,
          name: "loan_or_pool",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "Is the loan being sold individually or is it being sold only in a pool of loans\npossible values:\n'pool'\n'individual_loan'",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 178037535536,
          name: "biddable",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "By default biddable is set to true.\nPossible values:\ntrue\nfalse",
          type: "boolean",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 468768639761,
          name: "amount_funded",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "total dollar amount that has been invested into buying the loan so far",
          type: "filter-type: range\nAmount of the listing funded",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 420880946494,
          name: "amount_participation",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "amount that individual user signed into our platform has invested in that loan ",
          type: "filter-type: N/A\nAmount already invested by the authenticated user",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 835629734184,
          name: "amount_remaining",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description: "amount of the loan left that hasn't been funded yet ",
          type: "filter-type: range\nAmount remaining for funding.",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 787267570656,
          name: "borrower_apr",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description: "apr that the loan has",
          type: "filter-type: N/A\nBorrower APR",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 575908701246,
          name: "borrower_rate",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description: "interest rate that borrower is paying on the loan ",
          type: "filter-type: range\nThe borrower’s interest rate on the Loan",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 392980863405,
          name: "borrower_state",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description: "the state that the borrower took the loan out in ",
          type: "filter-type: multivalue\nState, abbreviated.(CA, TX, MA, etc.)",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 716424774731,
          name: "borrower_country",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description: "the country that the borrower took the loan out in ",
          type: "filter-type: multivalue\ncountry, abbreviated.(US, BE, etc)",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1268174799122,
          name: "credit_bureau_values_experian",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "return json file from experian credit bureau while removing all the PII",
          type: "object",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 756502886880,
          name: "credit_bureau_values_transunion",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "return json file from experian credit transunion while removing all the PII",
          type: "object",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 424346985738,
          name: "credit_bureau_values_lexisnexis",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "return json file from lexisnexis credit bureau while removing all the PII",
          type: "object",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 661470596918,
          name: "decision_bureau",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: N/A\nPossible values\nlexisnexis \nexperian\ntransunion\nThe value of this element shows which credit bureau’s underwriting data was used to generate the terms within the listing.",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1423042064957,
          name: "dti_wtih_loan",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: range\nDebt to income ratio including the pro-forma monthly payment of the entire loan amount posted by the borrower.",
          type: "percent",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 7712176808,
          name: "employment_status_description",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: multivalue\nGeneral employment status description (employed, self-employed, etc…)",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 244660936924,
          name: "estimated_monthly_housing_expense",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: range\nThe estimated monthly housing expense for the borrower.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 352537016231,
          name: "fico_score",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "credit score for example can be 600 or 732 etc so big int\n",
          type: "bigint",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 301196457126,
          name: "funding_threshold",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description: "filter-type: N/A\nFunding threshold to originate",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 847977763425,
          name: "has_mortgage",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-\nAn indication whether the borrower has an outstanding mortgage.",
          type: "boolean",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 115022024491,
          name: "income_range",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "income amount is bigint so that like $55,000\n\nold notes below on how liquidity does it\nfilter-type: multivalue\nYou may specify values from 0 to 7, as listed below:\n0 = Not displayed\n1 = $0\n2 = $1-24,999\n3 = $25,000-49,999\n4 = $50,000-74,999\n5 = $75,000-99,999\n6 = $100,000+\n7 = Not employed\nExample:\nincome_range=4\nincome_range=4,5,6",
          type: "bigint",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1078498332913,
          name: "income_range_description",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: N/A\nA textual description corresponding to the income_range number described above.",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 689487341381,
          name: "income_verifiable",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: N/A\nThe borrower stated that they could verify their stated income.",
          type: "boolean",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 248669583274,
          name: "investment_type_description",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: N/A\nA string that corresponds to the listing’s investment_typeid.\nWill be one of the following strings:\n“Fractional”\n“Whole”",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 15167204337,
          name: "investment_product_id",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description: "filter-type: N/A\n1 – Standard\n2 – Series 1",
          type: "int",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 396016660123,
          name: "investment_typeid",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: N/A\nThe type of loan offering:\n1 – Fractional\n2 – Whole\nNote: Not all investors can bid on whole loans.",
          type: "int",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1531265533757,
          name: "last_updated_date",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: N/A\nLast updated date for the listing.\nNote: Some fields, such as internal relationship fields, are not accessible to the search/listings API. However, updating those fields will update the last_updated_date field.",
          type: "date",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 16632119843,
          name: "lender_yield",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: range\nThe borrower’s interest rate less the investor servicing fee.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 342001302190,
          name: "loan_amount",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description: "filter-type: range\nLoan amount requested by borrower.",
          type: "",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 699603620710,
          name: "loan_category_id",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: multivalue\nBroad borrower-reported loan purpose expressed as an integer. This value will map to the listing_title value in your response.\nThe mapping for the values is:\n1 – Debt Consolidation\n2 – Home Improvement\n3 – Business\n4 – Personal loan\n5 – Student use\n6 – Auto / Motorcycle / RV / Boat\n7 – Other\n8 – Baby & Adoption\n9 – Boat\n10 – Cosmetic Procedures\n11 – Engagement Ring Financing\n12 – Green Loans\n13 – Household Expenses\n14 – Large Purchase\n15 – Medical / Dental\n16 – Motorcycle\n17 – RV\n18 – Taxes\n19 – Vacation\n20 – Wedding Loans\n21 – Special Occasion",
          type: "integer",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 270874857940,
          name: "loan_creation_date",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: range\nListing Creation Datedate format ‘yyyy-MM-dd’\nExample: 2015-12-24",
          type: "date",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 229210667057,
          name: "loan_end_date",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: range\nListing End Date\ndate format ‘yyyy-MM-dd’\nExample: 2015-12-24",
          type: "date",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 1642655916637,
          name: "loan_monthly_payment",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: range\nMonthly payment associated with the listing",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 124752240739,
          name: "loan_purpose",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: N/A\nThe title is determined by the type of loan selected by the borrower (Debt Consolidation, Home Improvement, Large Purchase, Household Expenses, Special Occassion, Vacation, Taxes, etc.)",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 665598922296,
          name: "loan_start_date",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: range\nListing Start Date\ndate format ‘yyyy-MM-dd’\nExample: 2015-12-24\nWhen filtering, you can also use the date format\ndate format ‘yyyy-MM-dd HH:mm:ss’\nExample: 2015-12-24 08:00:00",
          type: "date",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 832893202999,
          name: "loan_status_reason",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: N/A\nA textual description corresponding to the listing_status number described above.\nActive\nWithdrawn\nExpired\nCompleted\nCancelled\nPending Review / Acceptance",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 784868680784,
          name: "loan_term",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: multivalue\nMonths over which the loan amortizes. For example (can be any number)\n24\n36\n48\n.....",
          type: "integer",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 626089764293,
          name: "loan_origination_date",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: range\nThe origination date of the listing.\nExample: 2016-12-10",
          type: "date",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 146598628533,
          name: "member_key",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description: "filter-type: N/A\nBorrower unique ID/Member key",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 624143191938,
          name: "months_employed",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description: "filter-type: range\nLength of employment in months.",
          type: "integer",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 337152383711,
          name: "occupation",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: multivalue\nOccupation Name\nNote: Occupation reporting is optional when a person applies for a loan through Liquidity.io.\nThe value can be one of the following:\nAccountant/CPA\nAnalyst\nArchitect\nAttorney\nBiologist\nBus Driver\nCar Dealer\nChemist\nCivil Service\nClergy\nClerical\nComputer Programmer\nConstruction\nDentist\nDoctor\nEngineer – Chemical\nEngineer – Electrical\nEngineer – Mechanical\nExecutive\nFireman\nFlight Attendant\nFood Service\nFood Service Management\nHomemaker\nJudge\nLaborer\nLandscaping\nMedical Technician\nMilitary Enlisted\nMilitary Officer\nNurse (LPN)\nNurse (RN)\nNurse’s Aide\nPharmacist\nPilot Private/Commercial\nPolice Officer/Correction Officer\nPostal Service\nPrincipal\nProfessional\nProfessor\nPsychologist\nRealtor\nReligious\nRetail Management\nSales Commission\nSales Retail\nScientist\nAdministrative Assistant\nSkilled Labor\nSocial Worker\nStudent – College Freshman\nStudent – College Sophomore\nStudent – College Junior\nStudent – College Senior\nStudent – College Graduate Student\nStudent – Community College\nStudent – Technical School\nTeacher\nTeacher’s Aide\nTradesman – Carpenter\nTradesman – Electrician\nTradesman – Mechanic\nTradesman – Plumber\nTruck Driver\nWaiter/Waitress\nOther\nInvestor",
          type: "string",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 167182477938,
          name: "partial_funding_indicator",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "type:boolean\nfilter-\nIf true, the borrower is approved for partial funding. Borrowers can choose to partially fund their listing if it is at least 70% funded at the end of the listing period. This field is not applicable when investment_typeid=2.",
          type: "boolean",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 435877284891,
          name: "percent_funded",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description: "filter-type: range\nPercent of the loan funded",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 38099768229,
          name: "stated_monthly_income",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: range\nThe borrower’s monthly income, as provided by the borrower.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 893770702726,
          name: "co_borrower_application",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-\nAn indication whether the listing is a co-borrower application.",
          type: "boolean",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 366564031673,
          name: "combined_dti_with_loan",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: range\nCombined debt to income ratio including the pro-forma monthly payment of the entire listing amount posted by the borrower and the co-borrower. This field is present only for co-borrower listings.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
        {
          id: 797180139024,
          name: "combined_stated_monthly_income",
          parent: 6,
          branchColor: "#FCB900",
          legendId: "default",
          description:
            "filter-type: range\nThe combined borrowers’ stated monthly income, as provided by the borrower and the co-borrower. This field is present only for co-borrower listings.",
          type: "number",
          pageable: false,
          children: [],
          isRelation: false,
          relation: {},
          queryParams: [],
          crud: ["CREATE", "READ", "UPDATE", "DELETE"],
          enumValues: [],
          searchEnabled: true,
          patchEnabled: false,
          visibility: "READ_WRITE",
          required: false,
          xDescription: "",
          abstract: true,
          isArray: false,
          isSubPath: false,
          inDocumention: true,
          queryable: false,
        },
      ],
      queryParams: [],
      crud: ["CREATE", "READ", "UPDATE", "DELETE"],
      enumValues: [],
      isRelation: false,
      relation: {},
      searchEnabled: true,
      patchEnabled: false,
      visibility: "READ_WRITE",
      required: false,
      xDescription: "",
      abstract: true,
      isArray: true,
      isSubPath: true,
      inDocumention: false,
    },
  ],
};
