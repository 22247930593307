import type { ICountries, ICountryCode } from ".";

import { FC, useEffect, useState } from "react";
import Select from "react-select";

import countries from "./json/country-codes.json";

import "./country-code.scss";

export const CountryCode: FC<ICountryCode> = ({
  handleChangeCountry,
  countryCode = "+1",
}) => {
  const [country, setCountry] = useState<ICountries>();

  useEffect(() => {
    const countryObj = countries.find((item) => item.label === countryCode);
    setCountry(countryObj);
  }, [countryCode]);

  return country ? (
    <Select
      defaultValue={country}
      formatOptionLabel={(country: ICountries) => (
        <div className="option">
          <img
            className="option__flag"
            src={country.image}
            alt="country-code"
          />
          <span className="option__label">{country.label}</span>
        </div>
      )}
      className="country-label"
      options={countries}
      onChange={handleChangeCountry as any}
      isSearchable
    />
  ) : (
    <></>
  );
};
