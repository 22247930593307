export const DOCUMENTS_HEADER = [
  {
    label: "Name",
    key: "name",
    width: "3%",
    format: "string",
  },
  {
    label: "Description",
    key: "description",
    width: "3%",
    format: "string",
  },
  {
    label: "Created On",
    key: "createdAt",
    width: "5%",
    format: "",
  },
  {
    label: "Access Key",
    key: "key",
    width: "8%",
    format: "jsx",
  },
  {
    label: "Endpoints",
    key: "resource",
    width: "5%",
    format: "number",
  },
  {
    label: "Version",
    key: "version",
    width: "5%",
    format: "number",
  },
  {
    label: "Action",
    key: "action",
    width: "5%",
    format: "jsx",
  },
];

export const DOCUMENTATION_TABLE_BODY = [
  {
    name: "Avinash",
    email: "avinash@gmail.com",
    age: 344,
  },
  {
    name: "Avinash",
    email: "avinash@gmail.com",
    age: 344,
  },
  {
    name: "Avinash",
    email: "avinash@gmail.com",
    age: 344,
  },
  {
    name: "Avinash",
    email: "avinash@gmail.com",
    age: 344,
  },
];
