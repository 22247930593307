import { useCallback } from "react";

export const useParams = () => {
  const getParams = useCallback((url_strings: string, key: string) => {
    var url_string = url_strings ?? window.location.href;
    var url = new URL(url_string);
    var params = url.searchParams.get(key);
    return params;
  }, []);

  const removeDuplicateParams = useCallback((url: string) => {
    let result = new URLSearchParams(
      Object.fromEntries(new URLSearchParams(url))
    ).toString();
    return result;
  }, []);

  return { getParams, removeDuplicateParams };
};
