import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useParams } from "hooks";
import { APISettings, SwaggerCollection } from "./components";

import "./api-collections.scss";
import { RootHeight, RootWidth } from "states/states";
import { useRecoilValue } from "recoil";

export const APICollections = () => {
  const navigate = useNavigate();
  const rootHeight = useRecoilValue(RootHeight);
  const rootWidth = useRecoilValue(RootWidth);

  const { getParams, removeDuplicateParams } = useParams();

  const handleNavigate = useCallback(() => {
    //navigate(-1) is not working if this webcomponent is being used in other side
    const param = getParams(window.location.href, "navigate");
    if (!param) {
      navigate("../");
    } else {
      const url = removeDuplicateParams(window.location.search);
      navigate(`../${param}?${url}`);
    }
  }, [getParams, navigate, removeDuplicateParams]);

  const renderback = useMemo(() => {
    return (
      <div onClick={handleNavigate} className="go-back">
        <i className="ri-arrow-left-s-line"></i>
        <div>Back</div>
      </div>
    );
  }, [handleNavigate]);

  return (
    <div
      className="api-collections"
      style={{
        height: `calc(100vh - ${rootHeight ? rootHeight : 0}px)`,
      }}
    >
      <div className="api-collections__header">
        {renderback}
        <div className="api-collections__title">
          {getParams(window.location.href, "entity")} {" > "}
          Documentation
        </div>
      </div>

      <div className="api-collections__inner">
        <div className="api-collections__inner__left">
          <APISettings />
          <SwaggerCollection />
        </div>
        {/* <div className="api-collections__inner__right">
          <EnvironmentSettings />
        </div> */}
      </div>
    </div>
  );
};
