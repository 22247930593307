import { useRecoilValue } from "recoil";
import "./topbar.scss";
import { IsTopBar } from "states/states";

export const Topbar = ({ lable }: any) => {
  const isTopbar = useRecoilValue(IsTopBar);

  return isTopbar ? (
    <div className="container">
      <span className="lable">{lable}</span>
    </div>
  ) : (
    <></>
  );
};
