import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader, NodeTree, Tooltip } from "@storybook";

import { Settings } from "./components";
import { ACTION_METAINFO } from "./constants";
import { useNetwork, useNodeTree, useParams } from "hooks";

import "./api-editor.scss";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import {
  SavedTreeData,
  SavedTreeMetadata,
  TemplatesData,
  treeDataState,
  UrlPrefix,
  currentNode,
} from "states/states";

interface ISelectedAction {
  icon: string;
  action: string;
  index: number;
}

type FetchState = "fetching" | "fetched";

export const APIEditor = () => {
  const { addTreeNode, removeTreeNode } = useNodeTree();
  const urlPrefix = useRecoilValue(UrlPrefix);
  const treeData = useRecoilValue(treeDataState);
  const savedData = useRecoilValue(SavedTreeData);
  const setTreeData = useSetRecoilState(treeDataState);
  const [isEditable, setIsEditable] = useState(false);
  const navigate = useNavigate();
  const { get } = useNetwork();
  const { getParams } = useParams();
  const [selectedAction, setSelectedAction] = useState<ISelectedAction>({
    ...ACTION_METAINFO[1],
    index: 1,
  });
  const savedTreeMetadata = useRecoilValue(SavedTreeMetadata);
  const [fetchState, setFetchState] = useState<FetchState>("fetching");
  const templatesData = useRecoilValue(TemplatesData);
  const nodeMetadata: any = useMemo(() => {
    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData]);
  const [selectedNode, setSelectedNode] = useRecoilState(currentNode);

  // useEffect(() => {
  //   if (!selectedNode.currentNode?.id)
  //     setSelectedNode((prev: any) => {
  //       const newObj = JSON.parse(JSON.stringify(prev));
  //       newObj.currentNode = treeData;
  //       return { ...newObj };
  //     });
  //   return () => {
  //     setSelectedNode({ currentNode: {}, parentNode: {} });
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [treeData]);

  useEffect(() => {
    const data = sessionStorage.getItem("selectedTemplate");
    if (templatesData.length < 1 && data) {
      setFetchState("fetching");
      get("/api/api-template")
        .then((res: any) => {
          if (res.statusCode === 200) {
            const newItem = JSON.parse(
              JSON.stringify(
                res?.data?.find((item: any) => item?.name === data)
              )
            );
            newItem.id = Math.floor(Date.now() * Math.random());
            newItem.name = "";
            const regex = /[^!#$&'()*+,/:;=?%@[\]`{}|<> ]$/;
            let newAPIURL = "";
            for (const char of newItem?.name) {
              const hasInValidChar = !regex.test(char);
              if (!hasInValidChar || char === " ")
                newAPIURL += char === " " ? "_" : char;
            }
            if (
              !savedTreeMetadata.find((item: any) => item?.id === treeData?.id)
                ?.apiAccessKey
            ) {
              newItem["api_url"] = newAPIURL.slice(0, 20);
            }
            setTreeData(newItem);
            setFetchState("fetched");
          }
        })
        .catch((error: any) => {
          console.error(error);
        });
    } else {
      setFetchState("fetched");
    }
  }, [templatesData]);

  useEffect(() => {
    const getId = getParams(window.location.href, "id");
    if (getId) {
      const data = savedData.find((el: any) => el.id === Number(getId));
      if (data?.id) {
        setTreeData(data);
        setSelectedNode({ currentNode: data, parentNode: null });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getParams]);

  const handleAction = useCallback((item: any, index: number) => {
    if (index === 0) {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }
    setSelectedAction({ ...item, index });
  }, []);

  const renderAction = useMemo(
    () =>
      ACTION_METAINFO.map((el, index) => {
        return (
          <Tooltip key={index} text={el.label} direction="bottom">
            <div
              className={`api-editor__inner__editor__action-wrapper__icon-wrapper ${
                index === selectedAction.index && "active"
              }`}
              key={index + el?.icon}
              onClick={() => handleAction(el, index)}
            >
              <i className={el.icon}></i>
            </div>
          </Tooltip>
        );
      }),
    [handleAction, selectedAction?.index]
  );

  const handleNavigate = useCallback(() => {
    const param = getParams(window.location.href, "navigate");

    if (!param || param === "api-editor") {
      navigate(urlPrefix ? `../${urlPrefix}` : "../");
    } else {
      navigate(`../${param ?? ""}`);
    }
  }, [getParams, navigate, urlPrefix]);

  const renderback = useMemo(() => {
    return (
      <div onClick={handleNavigate} className="goback-innner-wrapper">
        <i className="ri-arrow-left-s-line"></i>
        <div>Back</div>
      </div>
    );
  }, [handleNavigate]);

  return (
    <div className="api-editor" id="api-editor">
      <div className="api-editor__inner">
        <div className="api-editor__inner__editor">
          {renderback}
          <div className="api-editor__inner__editor__action-wrapper">
            {renderAction}
          </div>
          <div className="api-editor__inner__editor__node-tree-wrapper">
            {fetchState === "fetched" ? (
              <NodeTree
                handleAddNode={addTreeNode}
                handleRemoveNode={removeTreeNode}
                isEditable={isEditable}
                nodeMetadata={nodeMetadata}
              />
            ) : (
              <Loader type="circle" className="loader-blue" dimension={28} />
            )}
          </div>
        </div>
        <div className="api-editor__inner__setting">
          <Settings />
        </div>
      </div>
    </div>
  );
};
