import { FC, ReactNode, useMemo } from "react";
import Select, { StylesConfig } from "react-select";
import { isEmptyArrayObject } from "utils";

import CreatableSelect from "react-select/creatable";

import "./dropdown.scss";

type IOption = { label: string; value: string; tooltip?: string };

interface IReactDropdown {
  handleChangeSelect?: (event: IOption) => void;
  options: IOption[];
  value: IOption;
  defaultValue: IOption;
  isSearchable?: boolean;
  isMulti?: boolean;
  placeholder?: string | ReactNode;
  isCreatable?: boolean;
  isDisabled?: boolean;
  type?: string;
}

export const ReactDropdown: FC<IReactDropdown> = ({
  handleChangeSelect,
  options,
  value,
  defaultValue,
  isSearchable = false,
  isMulti = false,
  placeholder,
  isCreatable = false,
  isDisabled = false,
  type,
}) => {
  let controlStyle: any = {
    backgroundColor: "var(--adf-color-input-background)",
    minHeight: 52,
    border: "1px solid var(--adf-color-input-border-light)",
    borderRadius: 8,
    fontWeight: "600",
    color: "#fff",
    boxShadow: "none",
    fontSize: 14,
    "&:hover": {
      cursor: "pointer",
      borderColor: "var(--adf-color-input-border-light)",
    },
    "&:focus": {
      borderColor: "red",
    },
  };

  switch (type) {
    case "header":
      controlStyle = {
        ...controlStyle,
        backgroundColor: "var(--adf-color-bg-100-light)",
        border: "none",
        fontSize: 18,
        minHeight: 20,
      };

      break;
    case "tag":
      controlStyle = {
        ...controlStyle,
        fontSize: 12,
        borderRadius: 30,
        backgroundColor: "var(--adf-color-bg-100-light)",
        minHeight: 10,
        // paddingLeft: "10px",
      };

      break;
    default:
      controlStyle = {
        ...controlStyle,
      };
  }

  const customStyle: StylesConfig = {
    control: (styles: { [key: string]: any }) => ({
      ...styles,
      ...controlStyle,
    }),
    menu: (styles: { [key: string]: any }) => ({
      ...styles,
      background: "var(--bg-primary)",
      borderRadius: 8,
      border: "1px solid var(--adf-color-input-border-light)",
    }),
    multiValue: (styles: { [key: string]: any }) => ({
      ...styles,
      backgroundColor: "rgba(224, 233, 255, 1)",
      color: "rgba(47, 50, 61, 1)",
      borderRadius: 50,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 4,
      paddingBottom: 4,
      margin: 4,
    }),
    singleValue: (styles: { [key: string]: any }) => ({
      ...styles,
      color: "var(--btn-text-primary-light)",
    }),

    placeholder: (styles: { [key: string]: any }) => ({
      ...styles,
      color: "var(--text-primary-tab)",
    }),

    multiValueRemove: (styles: any) => ({
      ...styles,
      color: "rgba(163, 177, 214, 1)",
      borderRadius: 50,
      marginLeft: 2,
      fontSize: 12,
    }),
    option: (styles: { [key: string]: any }) => {
      return {
        ...styles,
        backgroundColor: "var(--adf-color-input-background)",
        color: "#747E99",
        height: "fit-content",
        cursor: "pointer",
        paddingTop: 12,
        paddingBottom: 12,
        borderBottom: "1px solid var(--adf-color-input-border-light)",
        fontWeight: "500",
        fontSize: 12,
        wordBreak: "break-all",
        display: "flex",
        alignItems: "center",
        // flexWrap: "wrap",
        // whiteSpace: "nowrap",
        // overflow: "hidden",
        // textOverflow: "ellipsis",
        "&:hover": {
          color: "#fff",
          backgroundColor: "#3c65d6",
        },
        "&:last-child": {
          borderBottom: "none",
        },
      };
    },
  };

  const getPlaceholder = useMemo(() => {
    return isEmptyArrayObject(value?.value) ? (
      <div className="Select-placeholder">{placeholder}</div>
    ) : (
      value
    );
  }, [placeholder, value]) as ReactNode;

  return !isCreatable ? (
    <Select
      defaultValue={defaultValue}
      isMulti={isMulti}
      value={value?.value ? value : null}
      className="adf-dropdown"
      styles={customStyle}
      options={options}
      isClearable={false}
      closeMenuOnSelect={!isMulti}
      isSearchable={isSearchable}
      placeholder={getPlaceholder}
      onChange={handleChangeSelect as any}
      components={{
        IndicatorSeparator: () => null,
      }}
      isDisabled={isDisabled}
    />
  ) : (
    <CreatableSelect
      isMulti
      styles={customStyle}
      className={`adf-dropdown ${isDisabled ? "adf-dropdown--disabled" : ""}`}
      onChange={handleChangeSelect as any}
      value={value}
      isDisabled={isDisabled}
    />
  );
};
